import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
  @Input() integrations: Integration[] = [];
  @Output() filteredIntegrations = new EventEmitter<Integration[]>();

  constructor() { }

  ngOnInit(): void {
    this.filteredIntegrations.emit([...this.integrations]);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filteredIntegrations.emit([...this.integrations]);
  }

  processFilteredText(filterText: string) {

    if(filterText == "") {
      this.filteredIntegrations.emit([...this.integrations]);
    }
    else {
      let filteredIntegrations: Integration[] = [];

      this.integrations.forEach((val, idx)=>{
        if(val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
        else if(val.tagLine.toLowerCase().includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
        if(val.keywords && val?.keywords?.includes(filterText.toLowerCase())) {
          filteredIntegrations.push(val);
        }
      })
  
      this.filteredIntegrations.emit([...filteredIntegrations]);
    }
  }

}
