<div class="card-footer d-flex justify-content-between">
    <!-- Pagination (Prev) -->
    <ul class="list-pagination-prev pagination pagination-tabs card-pagination">
        <!-- First page -->
        <!-- <li class="page-item cursor-pointer" (click)="selectPage(1, $event)" [class.disabled]="currentPage == 1">
            <a class="page-link ps-4 pe-4 border-right">
                <i class="fe fe-arrow-left me-1"></i> First </a>
        </li> -->
        <!-- Previous page -->
        <li class="page-item cursor-pointer" (click)="selectPage(currentPage - 1, $event)"
            [class.disabled]="currentPage == 1" id="prevBtn">
            <a class="page-link ps-4 pe-4 border-right">
                <i class="fe fe-arrow-left me-1"></i> Prev </a>
        </li>
    </ul>

    <!-- Pagination -->
    <ul class="list-pagination pagination pagination-tabs card-pagination">

        <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
            <a class="page-link" href="">...</a>
        </li>

        <li class="page-item" [class.active]="page == currentPage" *ngFor="let page of pages | async">
            <a class="page-link" href="javascript:void(0)" (click)="selectPage(page, $event)">
                {{page}}
            </a>
        </li>

        <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
            <a class="page-link" href="">...</a>
        </li>
    </ul>

    <!-- Pagination (Next) -->
    <ul class="list-pagination-next pagination pagination-tabs card-pagination">
        <!-- Next page -->
        <li class="page-item cursor-pointer" (click)="selectPage(currentPage + 1, $event)"
            [class.disabled]="currentPage == totalPages" id="nextBtn">
            <a class="page-link ps-4 pe-4 border-left"> Next <i class="fe fe-arrow-right ms-1"></i>
            </a>
        </li>
        <!-- Last page -->
        <!-- <li class="page-item cursor-pointer" (click)="selectPage(totalPages, $event)"
            [class.disabled]="currentPage == totalPages">
            <a class="page-link ps-4 pe-4 border-left"> Last <i class="fe fe-arrow-right ms-1"></i>
            </a>
        </li> -->
    </ul>
</div>