import { Pipe, PipeTransform } from '@angular/core';
import { Integration } from '../models/integration.model';

@Pipe({
  name: 'sortArray'
})

/*
  working
  direction: asc or dsc
  column: name of the column which needed sorting
  type: string or number

*/

export class SortArrayPipe implements PipeTransform {

  transform(items: Integration[], direction: string, column: string, type: string): any {
    let sortedItems = [];
    let sortedEnabledProducts = [];
    let sortedComingSoonProductsItems = [];
    const filteredItems = items.filter(val => {
      if (val.enabled && val.comingSoon) {
        val.comingSoon = false;
        return val;
      } else {
        return val;
      }
    });
    const enabledProducts = filteredItems.filter(val => {
      return val.enabled;
    });
    const comingSoonProducts = filteredItems.filter(val => {
      return val.comingSoon;
    });
    sortedEnabledProducts = direction === 'asc' ?
      this.sortAscending(enabledProducts, column, type) : this.sortDescending(enabledProducts, column, type);
    sortedComingSoonProductsItems = direction === 'asc' ?
      this.sortAscending(comingSoonProducts, column, type) : this.sortDescending(comingSoonProducts, column, type);
    sortedItems = [
      ...sortedEnabledProducts,
      ...sortedComingSoonProductsItems
    ];
    return sortedItems;
  }

  sortAscending(items, column, type): any {
    return [...items.sort((a: any, b: any) => {
      if (type === 'string') {
        if (a[column].toUpperCase() < b[column].toUpperCase()) { return -1; }
      }
      else {
        return a[column] - b[column];
      }
    })];
  }

  sortDescending(items, column, type): any {
    return [...items.sort((a: any, b: any) => {
      if (type === 'string') {
        if (a[column].toUpperCase() > b[column].toUpperCase()) { return -1; }
      }
      else {
        return b[column] - a[column];
      }
    })];
  }

}