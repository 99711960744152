<ng-container [formGroup]="formGroup">
  <app-empty-notifier [message]="'No ' + notifierType + ' identities'"*ngIf="cardData.length == 0">
  </app-empty-notifier>
  <div class="card-header" *ngIf="cardData.length > 0">
    <app-pick-list-filter [cardData]="cardData" (filteredDataEmitter)="updateFilteredData($event)">
    </app-pick-list-filter>
  </div>
  <div class="card-body">
    <!-- List -->
    <app-pick-list [parentForm]="formGroup" [cardData]="filteredCardData" [controlName]="'identity'">
    </app-pick-list>
  </div>
</ng-container>