import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { chartOptions, fonts, colors } from 'src/app/shared/components/charts/chart-options';



@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit {
  @Input() chartType: ChartType;
  @Input() chartDataSets: ChartDataSets[];
  @Input() chartLabels: Label[];
  @Input() chartLegend: boolean;
  @Input() chartOptions: ChartOptions;
  @Input() chartPlugins: any[];

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartStyle: string;
  @Input() chartWidth: string;

  constructor() { }

  ngOnInit(): void {


    // Global
    Chart.defaults.global.responsive = chartOptions.responsive;
    Chart.defaults.global.maintainAspectRatio = chartOptions.maintainAspectRatio;

    // Default
    Chart.defaults.global.defaultColor = colors.gray[600];
    Chart.defaults.global.defaultFontColor = colors.gray[600];
    Chart.defaults.global.defaultFontFamily = fonts.base;
    Chart.defaults.global.defaultFontSize = 13;

    // Layout
    Chart.defaults.global.layout.padding = chartOptions.layout.padding;

    // Legend
    Chart.defaults.global.legend.display = chartOptions.legend.display;
    Chart.defaults.global.legend.position = 'bottom';
    Chart.defaults.global.legend.labels.usePointStyle = chartOptions.legend.labels.usePointStyle;
    Chart.defaults.global.legend.labels.padding = chartOptions.legend.labels.padding;

    // Point
    Chart.defaults.global.elements.point.radius = chartOptions.elements.point.radius;
    Chart.defaults.global.elements.point.backgroundColor = chartOptions.elements.point.backgroundColor;

    // Line
    Chart.defaults.global.elements.line.tension = chartOptions.elements.line.tension;
    Chart.defaults.global.elements.line.borderWidth = chartOptions.elements.line.borderWidth;
    Chart.defaults.global.elements.line.borderColor = chartOptions.elements.line.borderColor;
    Chart.defaults.global.elements.line.backgroundColor = chartOptions.elements.line.backgroundColor;
    Chart.defaults.global.elements.line.borderCapStyle = chartOptions.elements.line.borderCapStyle;

    // Rectangle
    Chart.defaults.global.elements.rectangle.backgroundColor = chartOptions.elements.rectangle.backgroundColor;
    // Chart.defaults.global.elements.rectangle.maxBarThickness = 10;

    // Arc
    Chart.defaults.global.elements.arc.backgroundColor = chartOptions.elements.arc.backgroundColor;
    Chart.defaults.global.elements.arc.borderColor = chartOptions.elements.arc.borderColor;
    Chart.defaults.global.elements.arc.borderWidth = chartOptions.elements.arc.borderWidth;
    // Chart.defaults.global.elements.arc.hoverBorderColor = colors.white;

    Chart.defaults.global.tooltips.enabled = false;
    Chart.defaults.global.tooltips.mode = 'index';
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.custom = function (model) {
      let tooltip = document.getElementById('chart-tooltip');
      let chartType = this._chart.config.type;

      // Create tooltip if doesn't exist
      if (!tooltip) {
        tooltip = document.createElement('div');

        tooltip.setAttribute('id', 'chart-tooltip');
        tooltip.setAttribute('role', 'tooltip');
        tooltip.classList.add('popover');
        tooltip.classList.add('bs-popover-top');

        document.body.appendChild(tooltip);
      }

      // Hide tooltip if not visible
      if (model.opacity === 0) {
        tooltip.style.visibility = 'hidden';

        return;
      }

      if (model.body) {
        let title = model.title || [];
        let body = model.body.map(function (body) {
          return body.lines;
        });

        // Add arrow
        let content = '<div class="arrow"></div>';

        // Add title
        title.forEach(function (title) {
          content += '<h3 class="popover-header text-center">' + title + '</h3>';
        });

        // Add content
        body.forEach(function (body, i) {
          let colors = model.labelColors[i];
          let indicatorColor = (chartType === 'line' && colors['borderColor'] !== 'rgba(0,0,0,0.1)') ? colors['borderColor'] : '#2C7BE5';
          let indicator = '<span class="popover-body-indicator" style="background-color: ' + indicatorColor + '"></span>';
          let justifyContent = (body.length > 1) ? 'justify-content-left' : 'justify-content-center';

          content += '<div class="popover-body d-flex align-items-center ' + justifyContent + '">' + indicator + body + '</div>';
        });

        tooltip.innerHTML = content;
      }

      let canvas = this._chart.canvas;
      let canvasRect = canvas.getBoundingClientRect();

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;

      let canvasTop = canvasRect.top + scrollTop;
      let canvasLeft = canvasRect.left + scrollLeft;

      let tooltipWidth = tooltip.offsetWidth;
      let tooltipHeight = tooltip.offsetHeight;

      let top = canvasTop + model.caretY - tooltipHeight - 16;
      let left = canvasLeft + model.caretX - tooltipWidth / 2;

      tooltip.style.top = top + 'px';
      tooltip.style.left = left + 'px';
      tooltip.style.visibility = 'visible';
    };

    switch (<string>this.chartType) {
      case 'bar':
        Chart['elements'].Rectangle.prototype.draw = function () {

          var ctx = this._chart.ctx;
          var vm = this._view;
          var left, right, top, bottom, signX, signY, borderSkipped, radius;
          var borderWidth = vm.borderWidth;

          // Set Radius Here
          // If radius is large enough to cause drawing errors a max radius is imposed
          var cornerRadius = 6;

          if (!vm.horizontal) {
            // bar
            left = vm.x - vm.width / 2;
            right = vm.x + vm.width / 2;
            top = vm.y;
            bottom = vm.base;
            signX = 1;
            signY = bottom > top ? 1 : -1;
            borderSkipped = vm.borderSkipped || 'bottom';
          } else {
            // horizontal bar
            left = vm.base;
            right = vm.x;
            top = vm.y - vm.height / 2;
            bottom = vm.y + vm.height / 2;
            signX = right > left ? 1 : -1;
            signY = 1;
            borderSkipped = vm.borderSkipped || 'left';
          }

          // Canvas doesn't allow us to stroke inside the width so we can
          // adjust the sizes to fit if we're setting a stroke on the line
          if (borderWidth) {
            // borderWidth shold be less than bar width and bar height.
            var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
            borderWidth = borderWidth > barSize ? barSize : borderWidth;
            var halfStroke = borderWidth / 2;
            // Adjust borderWidth when bar top position is near vm.base(zero).
            var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
            var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
            var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
            var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
            // not become a vertical line?
            if (borderLeft !== borderRight) {
              top = borderTop;
              bottom = borderBottom;
            }
            // not become a horizontal line?
            if (borderTop !== borderBottom) {
              left = borderLeft;
              right = borderRight;
            }
          }

          ctx.beginPath();
          ctx.fillStyle = vm.backgroundColor;
          ctx.strokeStyle = vm.borderColor;
          ctx.lineWidth = borderWidth;

          // Corner points, from bottom-left to bottom-right clockwise
          // | 1 2 |
          // | 0 3 |
          var corners = [
            [left, bottom],
            [left, top],
            [right, top],
            [right, bottom]
          ];

          if (this._chart.config.data.datasets[this._datasetIndex].data[this._index] < 0) {
            corners = [
              [left, top],
              [left, bottom],
              [right, top],
              [right, bottom]
            ];
          }

          // Find first (starting) corner with fallback to 'bottom'
          var borders = ['bottom', 'left', 'top', 'right'];
          var startCorner = borders.indexOf(borderSkipped, 0);
          if (startCorner === -1) {
            startCorner = 0;
          }

          function cornerAt(index) {
            return corners[(startCorner + index) % 4];
          }

          // Draw rectangle from 'startCorner'
          var corner = cornerAt(0);
          ctx.moveTo(corner[0], corner[1]);

          for (var i = 1; i < 4; i++) {
            corner = cornerAt(i);
            let nextCornerId = i + 1;
            if (nextCornerId == 4) {
              nextCornerId = 0
            }

            let nextCorner = cornerAt(nextCornerId);

            let width = corners[2][0] - corners[1][0];
            let height = corners[0][1] - corners[1][1];
            let x = corners[1][0];
            let y = corners[1][1];

            let radius = cornerRadius;

            // Fix radius being too large
            if (radius > height / 2) {
              radius = height / 2;
            } if (radius > width / 2) {
              radius = width / 2;
            }

            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);

          }

          ctx.fill();
          if (borderWidth) {
            ctx.stroke();
          }
        }

        break;
      default:
        break;
    }
  }

  private generateGlobalDefaults() {

    // 
    const globalConfig = Chart.defaults.global;

    // Global
    globalConfig.responsive = chartOptions.responsive;
    globalConfig.maintainAspectRatio = chartOptions.maintainAspectRatio;

    // Default
    globalConfig.defaultColor = colors.gray[600];
    globalConfig.defaultFontColor = colors.gray[600];
    globalConfig.defaultFontFamily = fonts.base;
    globalConfig.defaultFontSize = 13;

    // Layout
    globalConfig.layout.padding = chartOptions.layout.padding;

    // Legend
    globalConfig.legend.display = chartOptions.legend.display;
    globalConfig.legend.position = 'bottom';
    globalConfig.legend.labels.usePointStyle = chartOptions.legend.labels.usePointStyle;
    globalConfig.legend.labels.padding = chartOptions.legend.labels.padding;

    // Point
    globalConfig.elements.point.radius = chartOptions.elements.point.radius;
    globalConfig.elements.point.backgroundColor = chartOptions.elements.point.backgroundColor;

    // Line
    globalConfig.elements.line.tension = chartOptions.elements.line.tension;
    globalConfig.elements.line.borderWidth = chartOptions.elements.line.borderWidth;
    globalConfig.elements.line.borderColor = chartOptions.elements.line.borderColor;
    globalConfig.elements.line.backgroundColor = chartOptions.elements.line.backgroundColor;
    globalConfig.elements.line.borderCapStyle = chartOptions.elements.line.borderCapStyle;

    // Rectangle
    globalConfig.elements.rectangle.backgroundColor = chartOptions.elements.rectangle.backgroundColor;
    // globalConfig.elements.rectangle.maxBarThickness = 10;

    // Arc
    globalConfig.elements.arc.backgroundColor = chartOptions.elements.arc.backgroundColor;
    globalConfig.elements.arc.borderColor = chartOptions.elements.arc.borderColor;
    globalConfig.elements.arc.borderWidth = chartOptions.elements.arc.borderWidth;
    // globalConfig.elements.arc.hoverBorderColor = colors.white;

    Chart.defaults.global.tooltips.enabled = false;
    Chart.defaults.global.tooltips.mode = 'index';
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.custom = function (model) {
      var tooltip = document.getElementById('chart-tooltip');
      var chartType = this._chart.config.type;

      // Create tooltip if doesn't exist
      if (!tooltip) {
        tooltip = document.createElement('div');

        tooltip.setAttribute('id', 'chart-tooltip');
        tooltip.setAttribute('role', 'tooltip');
        tooltip.classList.add('popover');
        tooltip.classList.add('bs-popover-top');

        document.body.appendChild(tooltip);
      }

      // Hide tooltip if not visible
      if (model.opacity === 0) {
        tooltip.style.visibility = 'hidden';

        return;
      }

      if (model.body) {
        var title = model.title || [];
        var body = model.body.map(function (body) {
          return body.lines;
        });

        // Add arrow
        var content = '<div class="arrow"></div>';

        // Add title
        title.forEach(function (title) {
          content += '<h3 class="popover-header text-center">' + title + '</h3>';
        });

        // Add content
        body.forEach(function (body, i) {
          var colors = model.labelColors[i];
          var indicatorColor = (chartType === 'line' && colors['borderColor'] !== 'rgba(0,0,0,0.1)') ? colors['borderColor'] : '#ff0000';
          var indicator = '<span class="popover-body-indicator" style="background-color: ' + indicatorColor + '"></span>';
          var justifyContent = (body.length > 1) ? 'justify-content-left' : 'justify-content-center';

          content += '<div class="popover-body d-flex align-items-center ' + justifyContent + '">' + indicator + body + '</div>';
        });

        tooltip.innerHTML = content;
      }

      var canvas = this._chart.canvas;
      var canvasRect = canvas.getBoundingClientRect();

      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;

      var canvasTop = canvasRect.top + scrollTop;
      var canvasLeft = canvasRect.left + scrollLeft;

      var tooltipWidth = tooltip.offsetWidth;
      var tooltipHeight = tooltip.offsetHeight;

      var top = canvasTop + model.caretY - tooltipHeight - 16;
      var left = canvasLeft + model.caretX - tooltipWidth / 2;

      tooltip.style.top = top + 'px';
      tooltip.style.left = left + 'px';
      tooltip.style.visibility = 'visible';
    };



    return globalConfig;
  }

}
