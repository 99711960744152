import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-mws-settlement-reports-checklist',
  templateUrl: './help-mws-settlement-reports-checklist.component.html'
})
export class HelpMwsSettlementReportsChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
