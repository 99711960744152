import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PipelineCountService {

  baseApiUrl: string = null;

  constructor(
    private http: HttpClient
  ) {
    this.baseApiUrl = environment.openbridgeApiUris.pipelineCount;
  }

  async getAll() {
    try {
      const getResponse = await this.http.get(this.baseApiUrl + '/count', { observe: 'response' }).toPromise();
      return getResponse;
    }
    catch (error) {
      throw error;
    }
  }

  async getByUTCDate(utcDateString: string) {
    try {
      const getResponse = await this.http.get(this.baseApiUrl + '/count?date=' + utcDateString, { observe: 'response' }).toPromise();
      return getResponse;
    }
    catch (error) {
      throw error;
    }
  }

  transformResponse(value: any) {
    const transformedData = {
      labels: value.date,
      data: value.count
    };

    return transformedData;
  }

  getDateRangeArray(s, e, format: string) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(moment(new Date(d)).format(format));
    }
    return a;
  };

}
