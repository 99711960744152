import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as fromAuth from './auth.reducer';
import * as fromAccount from './account.reducer';
import * as fromFlashNotifications from './flash-notifications.reducer';
import * as fromIdentities from './identities.reducer';
import * as fromStripe from './stripe.reducer';
import * as fromSubscriptions from './subscriptions.reducer';
import * as fromWizard from './wizard.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { from } from 'rxjs';


export interface AppState {
  router: RouterReducerState,
  auth: fromAuth.AuthState | null;
  account: fromAccount.AccountState | null;
  flashNotifications: fromFlashNotifications.FlashNotificationState | null;
  identities: fromIdentities.IdentitiesState | null;
  stripe: fromStripe.StripeState | null;
  subscriptions: fromSubscriptions.SubscriptionsState | null;
  wizard: fromWizard.WizardState | null;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: fromAuth.reducer,
  account: fromAccount.reducer,
  flashNotifications: fromFlashNotifications.reducer,
  identities: fromIdentities.reducer,
  stripe: fromStripe.reducer,
  subscriptions: fromSubscriptions.reducer,
  wizard: fromWizard.reducer
};

// Not going to use meta reducers for now, but we'll leave this.
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];