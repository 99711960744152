import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
 productIdFacebookMarketing, productIdFacebookPageInsights, productIdInstagramInsights, productIdInstagramStories
} from '../product-ids';

export const facebokInstagramProducts: Integration[] = [
  {
    productId: productIdFacebookMarketing,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Facebook',
    name: 'Marketing',
    type: 'source',
    sortOrder: 400,
    tagLine: 'Facebook Ads statistics for attribution insights, reporting and analytics ',
    routerLink: '/wizards/facebook-ads',
    logoPath: vendors.facebook,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 2,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'dev.facebook.com/marketing',
      uri: 'https://developers.facebook.com/docs/marketing-apis/'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdFacebookPageInsights,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Facebook',
    name: 'Page Insights',
    type: 'source',
    sortOrder: 500,
    tagLine: 'Engagement, reach, and audience insights for Facebook Pages and Page posts',
    routerLink: '/wizards/facebook-page-insights',
    logoPath: vendors.facebook,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 2,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'dev.facebook.com/graph-api',
      uri: 'https://developers.facebook.com/docs/graph-api/'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdInstagramInsights,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Instagram',
    name: 'Insights',
    type: 'source',
    sortOrder: 1000,
    tagLine: 'Perpformance insights data for users and media organic social interactions',
    routerLink: '/wizards/instagram-business-iq',
    logoPath: vendors.instagram,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 2,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'facebook.com/instagram-api',
      uri: 'https://developers.facebook.com/docs/instagram-api/guides/insights'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdInstagramStories,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Instagram',
    name: 'Stories',
    type: 'source',
    sortOrder: 1100,
    tagLine: 'Get the latest performance insights for a story before they expire every 24 hours',
    routerLink: '/wizards/instagram-stories',
    logoPath: vendors.instagram,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 2,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'facebook.com/instagram-api',
      uri: 'https://developers.facebook.com/docs/instagram-api/guides/insights'
    },
    history: false,
    manageMenu: {
    }
  },
];



