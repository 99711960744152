<div class="row align-items-center">
  <div class="col">
    <!-- Nav -->
    <ul class="nav nav-tabs nav-overflow header-tabs">
      <li class="nav-item">
        <a href (click)="setFilter('all')" class="nav-link text-nowrap" id="all"
          [ngClass]="{'active': activeFilterType == 'all' }"> All <span id="allPipelinesCount" class="badge rounded-pill bg-secondary-soft">{{
            allPipelinesCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('source')" class="nav-link text-nowrap" id="source"
          [ngClass]="{'active': activeFilterType == 'source' }"> Sources <span id="allSourcesCount"
            class="badge rounded-pill bg-secondary-soft">{{ allSourcesCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('destination')" class="nav-link text-nowrap" id="destination"
          [ngClass]="{'active': activeFilterType == 'destination'}"> Destinations <span id="allDestinationsCount"
            class="badge rounded-pill bg-secondary-soft">{{ allDestinationsCount }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>