import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { Router } from '@angular/router';
import { identitiesGet, identitiesLoad } from '../actions/identities.actions';
import { IdentityService } from '../../services/identity.service';
import { CacheService } from '../../services/cache.service';


@Injectable()
export class IdentitiesEffects {

  identitiesGet$ = createEffect(() => this.actions$
    .pipe(
      ofType(identitiesGet),
      tap(async action => {
        try {

          const effectSelf = this; // because, we need "this" somewhere else.
          // let identities = this.cacheService.getCache('local', 'list-identities');
          let identities = null;

          if (identities == null) {
            console.info('Requesting identities data from openbridge API');
            const identityList = [];
            const identityResponses = await this.identityService.getAll();
            for (const response of identityResponses) {
              const data = response['data'];
              for (const identity of data) {
                const formatted = effectSelf.identityService.transformResponse(identity);
                if (formatted) {
                  identityList.push(formatted);
                }
              }
            }

            identities = { identities: identityList };
          }
          this.store$.dispatch(identitiesLoad(identities));

        }
        catch (error) {
          throw error;
        }
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private identityService: IdentityService,
    private store$: Store<AppState>,
    private cacheService: CacheService
  ) { }

}