import { requestURLs } from './constant';

export function findRequestURL(name: string): string {
    const response = requestURLs.filter(value => {
        if (value.name === name) {
            return value;
        }
    });

    return response[0].value;
}