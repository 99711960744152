import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/wizard.actions';

// export interface checklistValue

// CHange "any" to whatever the user model will be in the future.
export interface WizardState {
  checklist: any | null;
  config: any | null;
  productId: number | null;
  routerLink: string | null;
  stage: string | null;
  stages: string[] | null;
  requestCaches: object;
}

//  Create the initial state for the authenticated user.
const initialWizardState: WizardState = {
  checklist: null,
  config: null,
  productId: null,
  routerLink: null,
  stage: null,
  stages: null,
  requestCaches: {},
};


export const reducer = createReducer(
  initialWizardState,

  on(actions.wizardIntegrationInit, (state, payload) => {
    return {
      ...state,
      checklist: payload.checklist,
      config: payload.config,
      productId: payload.productId,
      routerLink: payload.routerLink,
      stage: payload.stage,
      stages: payload.stages,
    };
  }),

  on(actions.wizardIntegrationChecklist, (state, payload) => {
    return {
      ...state,
      checklist: payload.checklist,
      stage: payload.stage
    };
  }),

  on(actions.wizardIntegrationConfig, (state, payload) => {
    return {
      ...state,
      stage: payload.stage,
      config: payload.config
    };
  }),

  on(actions.wizardIntegrationStagelessConfig, (state, payload) => {
    return {
      ...state,
      config: payload.config
    };
  }),

  on(actions.wizardIntegrationPreviousStage, (state, payload) => {
    return {
      ...state,
      stage: payload.stage
    };
  }),

  on(actions.wizardIntegrationStagesUpdate, (state, payload) => {
    return {
      ...state,
      stages: payload.stages
    };
  }),

  on(actions.wizardIntegrationRequestCacheUpdate, (state, payload) => {

    const cacheObject = {
      ...state.requestCaches
    };

    cacheObject[payload.key] = payload.cache;

    return {
      ...state,
      requestCaches: cacheObject
    };
  }),

);
