<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/amazon-redshift.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Activating Amazon Redshift Spectrum </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Before you get started activating Redshift Spectrum... </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Amazon Web Services or AWS account. AWS
    offers <a class="light-link" rel="noopener" target="_blank" href="https://aws.amazon.com/free"> free trials and
      service credits</a>
    for new customers </li>
  <li class="text-muted mb-2"> If you already have Redshift Spectrum running, see the <a class="light-link"
      rel="noopener" target="_blank"
      href="https://docs.openbridge.com/en/articles/1427334-amazon-redshift-setting-up-your-system">
      setup guide </a>to
    finalize settings. </li>
  <li class=" text-muted mb-2"> Make sure you have you taken note of the <code>username</code>, <code>password</code>,
    and <code>hostname</code> or <code>IP address</code> in the AWS interface. You will need this in the next step</li>
</ol>