import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { colors } from '../chart-options';


@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html'
})
export class ChartBarComponent implements OnInit {
  // Data Related
  @Input() chartDataSets: ChartDataSets[];
  @Input() chartLabels: Label[];
  @Input() chartPlugins: any[];
  @Input() chartLegend: boolean;
  @Input() chartTickLimit: number;

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartStyle: string;
  @Input() chartWidth: string;

  chartType: ChartType = 'bar';
  chartOptions: ChartOptions;
  chartDataLengthError: boolean;
  constructor() { }

  ngOnInit(): void {
    this.chartDataSets.forEach(dataSet => {
      this.chartDataLengthError = dataSet.data.length > this.chartLabels.length ? true : false;
    });
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Count of data pipelines, by day, for the prior 30 days',
        position: 'bottom',
        padding: 20
      },
      scales: {
        xAxes: [{
          gridLines: {
            zeroLineColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            callback: function (value) {
              const date = moment(value, 'YYYY-MM-DD');
              const monthDay = date.format('MMM-DD');
              return monthDay;
            },

            // property for limiting the labels
            maxTicksLimit: this.chartTickLimit

            // updated function for limiting the labels
            // callback: function (value, index) {
            //   let date = moment(value, 'YYYY-MM-DD');
            //   let monthDay = date.format('MMM-DD');
            //   return index % 5 === 0 ? monthDay : null;
            // },

          }
        }],
        yAxes: [{
          gridLines: {
            borderDash: [2],
            borderDashOffset: 2,
            color: colors.gray[900],
            drawBorder: false,
            drawTicks: false,
            zeroLineColor: colors.gray[900],
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: 2
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            callback: function (value) {
              return value;
            }
          }
        }]
      }
    };
  }
}
