import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-destinations-select',
  templateUrl: './help-destinations-select.component.html',
  styleUrls: ['./help-destinations-select.component.scss']
})
export class HelpDestinationsSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
