<!-- Image -->
<div class="avatar avatar-sm card-avatar">
    <img src="../assets/img/avatars/products/amazon.svg" class="avatar-img rounded" alt="...">
  </div>
  <!-- Heading -->
  <h2 class="text-center mb-2" id="helpDrawerHeader"> Authorization </h2>
  <!-- Text -->
  <p class="text-center mb-4" id="helpDrawerTagline"> Configuring Access Permissions </p>
  <!-- Divider -->
  <hr class="mb-4">
  <!-- Heading-->
  <h4 class="mt-2 mb-2"> Getting ready for automated Amazon DSP data pipelines </h4>
  <ol>

    <li class="text-muted mb-2"> You will be requesting and receiving consent to access a Vendor or Seller's Amazon.com profile via Login with Amazon.</li>

    <li class="text-muted mb-2">  To grant Amazon Ads access to Openbridge, you will be directed to an Amazon website where you are required to provide authorization. Once authorization is complete at Amazon, you will be redirected back to the Openbridge website to continue with setup.  </li>
  


  </ol>