import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-azure-blob-storage-details',
  templateUrl: './help-azure-blob-storage-details.component.html'
})
export class HelpAzureBlobStorageDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
