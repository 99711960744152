<ng-container *ngIf="cardData.length > 1">
  <form novalidate [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="row align-items-center" data-select2-id="13">
      <div class="col">
        <div class="input-group input-group-flush input-group-merge input-group-reverse">
          <input class="form-control list-search" type="search" placeholder="Search" name="filterInput" id="filterInput"
            formControlName="filterInput" (keyup)="onKeyUp($event)">
          <span class="input-group-text">
            <i class="fe fe-search"></i>
          </span>
        </div>
      </div>
      <div class="col-auto me-n3" data-select2-id="12">
        <!-- Select -->
        <!-- <select class="custom-select custom-select-sm form-control-flush select2-hidden-accessible"
          data-bs-toggle="select" data-options="{&quot;minimumResultsForSearch&quot;: -1}"
          data-select2-id="1" tabindex="-1" aria-hidden="true">
          <option value="5" selected="" data-select2-id="3">5 per page</option>
          <option value="10" data-select2-id="14">10 per page</option>
          <option value="*" data-select2-id="15">All</option>
        </select>
        <span
          class="select2 select2-container select2-container--default select2-container--below"
          dir="ltr" data-select2-id="2" style="width: 105px;"><span class="selection"><span
              class="select2-selection select2-selection--single custom-select custom-select-sm form-control-flush"
              role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0"
              aria-disabled="false" aria-labelledby="select2-ufwc-container"><span
                class="select2-selection__rendered" id="select2-ufwc-container" role="textbox"
                aria-readonly="true" title="5 per page">5 per page</span><span
                class="select2-selection__arrow" role="presentation"><b
                role="presentation"></b></span></span></span><span class="dropdown-wrapper"
            aria-hidden="true"></span>
        </span> -->
      </div>
    </div> <!-- / .row -->
  </form>
</ng-container>