<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-database"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Key considerations for activating data destinations </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Data Destination </h4>
<ol>
  <li class="text-muted mb-2"> An active data destination must be ready to receive data before activating a source.
    Without a destination, we have no place to deliver your data. Learn more about activating supported <a
      href="https://docs.openbridge.com/en/collections/753357-data-destinations" rel="noopener" target="_blank"
      class="light-link">data
      destinations.</a></li>
  <li class="text-muted mb-2"> For security, privacy, and maximizing productivity, data is delivered directly to a
    customer managed destination. This means you always own your data.</li>
  <li class="text-muted mb-2"> You can connect multiple data destinations to your account based on your plan. Once a
    warehouse or data lake is activated, it counts as a destination.</li>
  <li class="text-muted mb-2">A destination leverages a data catalog to keep track of source system changes. The data
    catalog is designed to adapt and adjust dynamically to changes it detects from various data sources. Read more about
    how we store and organize data with a <a
      href="https://docs.openbridge.com/en/articles/2247373-data-catalog-how-we-organize-and-manage-data-in-your-data-lake-or-cloud-warehouse"
      rel="noopener" target="_blank" class="light-link">data
      catalog.</a></li>
</ol>