import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/identities.actions';
import { setCache } from 'src/app/core/functions/cache';

const identitiesCacheKey = 'list-identities';

// Change "any" to whatever the identity model will be in the future.
export interface IdentitiesState {
  identities: any[] | null;
}

//  Create the initial state for account identities.
const initialIdentitiesState: IdentitiesState = {
  identities: []
};

export const reducer = createReducer(
  initialIdentitiesState,
  on(actions.identitiesLoad, (state, payload) => {
    const stateData = {
      ...state,
      identities: payload.identities
    };
    setCache('local', identitiesCacheKey, stateData);
    return stateData;
  }),
  on(actions.identityCreate, (state, payload) => {
    const stateData = {
      ...state,
      identities: [
        ...state.identities,
        payload
      ]
    };
    setCache('local', identitiesCacheKey, stateData);
    return stateData;
  })
);
