import { createAction, props } from "@ngrx/store";


export const identitiesLoad = createAction('[Identities] Load Identities',
  props<any>()
);
export const identitiesLoaded = createAction('[Identities] Identities Loaded Success');


export const identityGet = createAction('[Identities] Get Identity');

export const identitiesGet = createAction('[Identities] Get Identities');
export const identityCreate = createAction('[Identities] Create Identity',
  props<any>()
);
export const identityUpdate = createAction('[Identities] Update Identity',
  props<any>()
);
export const identityDelete = createAction('[Identities] Delete Identity',
  props<any>()
);