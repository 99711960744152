import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'identities',
    loadChildren: () => import('./modules/identities/identities.module').then(m => m.IdentitiesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pipelines',
    loadChildren: () => import('./modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'destinations',
    loadChildren: () => import('./modules/destinations/destinations.module').then(m => m.DestinationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./modules/original-pages/original-pages.module').then(m => m.OriginalPagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'sources',
    loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wizards',
    loadChildren: () => import('./modules/wizards/wizards.module').then(m => m.WizardsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'history', 
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule) ,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'healthchecks',
    loadChildren: () => import('./modules/healthchecks/healthchecks.module').then(m => m.HealthchecksModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
