export const responseAmazonAttributionSubscription = {
  data: {
    type: 'Subscription',
    id: '228',
    attributes: {
      price: 0,
      status: 'active',
      date_start: '2021-12-31T07:31:58',
      date_end: '2021-12-31T07:31:58',
      auto_renew: 1,
      created_at: '2021-12-31T07:32:02.273749',
      modified_at: '2021-12-31T07:32:02.273763',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'amazon-attribution-mock-1',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 342,
      product_id: 50,
      product_plan_id: null,
      remote_identity_id: 112,
      storage_group_id: 108,
      user_id: 309,
      history_requested: 0
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '342'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '50'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: {
          type: 'RemoteIdentity',
          id: '112'
        }
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '108'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '309'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '108',
      attributes: {
        product_id: 47,
        name: 'my datalake test',
        key_name: 'afad95d7a191146f-my-datalake-test'
      }
    }
  ]
};