import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-mws-finances-checklist',
  templateUrl: './help-mws-finances-checklist.component.html'
})
export class HelpMwsFinancesChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
