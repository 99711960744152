// Security page for password reset.
export const successGeneralProfileUpdate = 'Success! Your profile has been updated.';
export const errorGeneralProfileUpdate = 'Unable to update your profile. Please try again in a few minutes.';

// Security page for password reset.
export const successSecurityResetPasswordRequest = 'Password reset request successful.  Check your email for a password reset link.';
export const errorSecurityResetPasswordRequestFailure = 'Unable to submit request for password reset. Please try again in a few minutes.';

// Final response in storage testing for all storages.
export const successWizardGeneralStorageTest = 'Destination test completed successfully. Continue to the next step.';
export const errorWizardGeneralStorageTestFailed = 'We had difficulty connecting to your destination. Please check your settings.';

// General wizard form errors.
export const errorWizardGeneralChecklist = 'Please check everything so we know you are ready to go.';
export const errorWizardGeneralDestinationSelection = 'Please choose a destination so we can store your data.';
export const errorWizardGeneralDestinationName = 'Please give your destination a unique name.';
export const errorWizardGeneralSourceName = 'Please give your subscription a unique name.';
export const errorWizardGeneralRemoteIdentitySelection = 'We need an identity to authorize our access to this service.';
export const errorWizardGoogleBigqueryDatasetidSelection = 'Please choose an dataset.';
export const errorWizardGenericProfileSelection = 'Please choose a profile.';
export const errorWizardGeneralDetailsIncomplete = 'Looks like some information is missing. Please enter all the required details.';
export const errorWizardGeneralError = 'Something went wrong !!';
export const errorWizardIdentityExists = 'This identity already exists';
export const errorIntegrationDoesNotSupportHistory = 'Sorry, but this product does not support history runs';

export const errorHistoryFailure = 'Unable to process history';

// flash-notification icon list
export const FlashNotificationIcons = {
  primaryIcon: 'fa-check-square',
  secondaryIcon: 'fa-check-double',
  successIcon: 'fa-check-circle',
  dangerIcon: 'fa-octagon-exclamation',
  warningIcon: 'fa-question-circle',
  infoIcon: 'fa-info-circle',
  lightIcon: 'fa-lightbulb',
  darkIcon: 'fas fa-moon',
  defaultIcon: ''
};

// flash-notification alert list
export const FlashNotificationAlerts = {
  alertPrimary: 'alert-primary',
  alertSecondary: 'alert-secondary',
  alertSuccess: 'alert-success',
  alertDanger: 'alert-danger',
  alertWarning: 'alert-warning',
  alertInfo: 'alert-info',
  alertLight: 'alert-light',
  alertDark: 'alert-dark',
};
