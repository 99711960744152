import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-instagram-business-iq-identity',
  templateUrl: './help-instagram-business-iq-identity.component.html'
})
export class HelpInstagramBusinessIqIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
