import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-transaction-view',
  templateUrl: './modal-transaction-view.component.html',
  styleUrls: ['./modal-transaction-view.component.scss']
})
export class ModalTransactionViewComponent implements OnInit {
  data: any = {};
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  copyMessage(val: any): boolean {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    // const copy = JSON.parse(JSON.stringify(val));
    const copyData = JSON.stringify(val, null, '\t');
    selBox.value = copyData;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    return false;
  }

}
