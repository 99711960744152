import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-shopify-checklist',
  templateUrl: './help-shopify-checklist.component.html',
  styleUrls: ['./help-shopify-checklist.component.scss']
})
export class HelpShopifyChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
