import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identity-mws-create',
  templateUrl: './identity-mws-create.component.html',
  styleUrls: ['./identity-mws-create.component.scss']
})
export class IdentityMwsCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
