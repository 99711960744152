import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import { productIdYoutubeChannelInsights, productIdYoutubeCompetitorChannels, productIdYoutubeVideoInsights } from '../product-ids';

export const youtubeProducts: Integration[] = [
  {
    productId: productIdYoutubeChannelInsights,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Youtube',
    name: 'Channel Insights',
    type: 'source',
    sortOrder: 1700,
    tagLine: 'Channel level insights for user activity, traffic, playlists, devices, audience, and many more',
    routerLink: '/wizards/youtube-channel-insights',
    logoPath: vendors.youtube,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/youtube/reporting',
      uri: 'https://developers.google.com/youtube/reporting/v1/reports/channel_reports'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdYoutubeCompetitorChannels,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Youtube',
    name: 'Competitor Channels',
    type: 'source',
    sortOrder: 1800,
    tagLine: 'Understand viewing statistics and trends for competitor channel.',
    routerLink: '/wizards/youtube-competitor',
    logoPath: vendors.youtube,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/youtube/channels',
      uri: 'https://developers.google.com/youtube/reporting/v1/reports/channel_reports'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdYoutubeVideoInsights,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Youtube',
    name: 'Video Insights',
    type: 'source',
    sortOrder: 1900,
    tagLine: 'Video level insights user activity, traffic, playlists, devices, audience, and many more',
    routerLink: '/wizards/youtube-video-insights',
    logoPath: vendors.youtube,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/youtube/reporting',
      uri: 'https://developers.google.com/youtube/reporting'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: productIdYoutubeCompetitorChannels,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Youtube',
    name: 'Competitor Video',
    type: 'source',
    sortOrder: 2000,
    tagLine: 'Competitor video traffic, views, metadata, and many more',
    routerLink: '/wizards/youtube-video-iq',
    logoPath: vendors.youtube,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/youtube/video',
      uri: 'https://developers.google.com/youtube/v3/docs/videos'
    },
    history: false,
    manageMenu: {
    }
  },
];

