    <!-- Header -->
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

        <!-- Pretitle -->
        <app-step-counter [stagePosition]="stagePosition" [totalStages]="totalStages"></app-step-counter>
                
        <!-- Image -->
        <div class="avatar avatar-xl card-avatar">
          <img [src]="logoPath" class="avatar-img rounded"
               [alt]="logoText" [title]="logoText">
        </div>

        <!-- Title -->
        <h1 class="mb-2" id="headline">
          {{ headline }}
        </h1>

        <!-- Subtitle -->
        <p class="mb-6 text-muted" id="tagline">
          {{ tagline }}
        </p>

      </div>
    </div> <!-- / .row -->