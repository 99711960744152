<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/snowflake.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Connect And Authorize</h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> How to connect and authorize Snowflake destination </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Snowflake configuration </h4>
<ol>
  <li class="text-muted mb-2">Account - The account id of your cluster, not the url (e.g. url:
    <code>my-business.snowflakecomputing.com</code>). The account ID would be: <code>my-business</code>. Note: If you
    are using Snowflake on AWS, the account id
    includes the region, for example your url might look like:
    <code>my-business.us-east-1.snowflakecomputing.com/</code> and your
    account-id would be: <code>my-business.us-east-1</code>)
  </li>
  <li class="text-muted mb-2">If you are unsure of your account name, you can also query it in the Snowflake worksheet:
    <code>SELECT CURRENT_ACCOUNT(), CURRENT_REGION();</code>
  </li>
  <li class="text-muted mb-2">Database - The database name you created for this destination. </li>
  <li class="text-muted mb-2">Schema - The schema name you created for this destination. </li>
  <li class="text-muted mb-2">Warehouse - The warehouse name you created for this destination. </li>
  <li class="text-muted mb-2"> User - The user name for the destination. </li>
  <li class="text-muted mb-2">Password - The password for the user. </li>
 
  <li class="text-muted mb-2">If you get a connection error, recheck that the inputs are correct and make sure you have
    whitelisted our IP Addresses: <code>52.54.227.22</code> and <code>52.2.68.68</code>.
  </li>
    <li class="text-muted mb-2">If you feel stuck, make sure
    you are following our See our guide <a
      href="https://docs.openbridge.com/en/articles/5024964-how-to-setup-snowflake-data-destination" rel="noopener"
      target="_blank">How To Setup Snowflake Data Destination</a> which includes an automated configuration script.</li>

</ol>