<div class="card-footer d-flex justify-content-between">
  <!-- Pagination (Prev) -->
  <ul class="list-pagination-prev pagination pagination-tabs card-pagination">
    <!-- First page -->
    <li class="page-item cursor-pointer" [tooltip]="'goto first page'" (click)="onFirst()"
      [class.disabled]="currentPage <= 1">
      <a class="page-link ps-4 pe-4 border-right">
        <!-- <i class="fe fe-arrow-left me-1"></i> First  -->
        <i class="fad fa-chevron-double-left"></i>
      </a>
    </li>
    <!-- Previous page -->
    <li class="page-item cursor-pointer" [tooltip]="'goto previous page'" (click)="onPrevious()"
      [class.disabled]="currentPage <= 1">
      <a class="page-link ps-4 pe-4 border-right">
        <!-- <i class="fe fe-arrow-left me-1"></i> Prev  -->
        <i class="fad fa-chevron-left"></i>
      </a>
    </li>
  </ul>

  <!-- Pagination -->
  <!-- sample 1 -->
  <!-- <ul class="list-pagination pagination pagination-tabs card-pagination">
    <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
      <a class="page-link" href="">...</a>
    </li>

    <li class="page-item" [class.active]="page == currentPage" *ngFor="let page of pages | async">
      <a class="page-link" href="javascript:void(0)" (click)="setPage(page)">
        {{page}}
      </a>
    </li>

    <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
      <a class="page-link" href="">...</a>
    </li>
  </ul> -->

  <!-- sample 2 -->
  <ul class="list-pagination pagination pagination-tabs card-pagination">
    <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage - range) > 1">
      <a class="page-link" href="">...</a>
    </li>
    <li class="page-item" [class.active]="page == currentPage" *ngFor="let page of pages | async">
      <a class="page-link" href="javascript:void(0)" (click)="setPage(page)">
        {{page}}
      </a>
    </li>
    <li class="page-item" class="disabled" (click)="cancelEvent($event)" *ngIf="(currentPage + range) < totalPages">
      <a class="page-link" href="">...</a>
    </li>
    <li class="page-item">
      <a class="page-link" href="javascript:void(0)" *ngIf="currentPage > 0">
        of {{totalPages}}
      </a>
    </li>
  </ul>

  <!-- sample 3 -->
  <!-- <ul class="list-pagination pagination pagination-tabs card-pagination">
    <li class="page-item">
      <a class="page-link" href="javascript:void(0)" *ngIf="currentPage > 0">
        Page {{currentPage}} of {{totalPages}}
      </a>
    </li>
  </ul> -->

  <!-- Pagination (Next) -->
  <ul class="list-pagination-next pagination pagination-tabs card-pagination">
    <!-- Next page -->
    <li class="page-item cursor-pointer" [tooltip]="'goto next page'" (click)="onNext()"
      [class.disabled]="currentPage == totalPages">
      <a class="page-link ps-4 pe-4 border-left">
        <!-- Next <i class="fe fe-arrow-right ms-1"></i> -->
        <i class="fad fa-chevron-right"></i>
      </a>
    </li>
    <!-- Last page -->
    <li class="page-item cursor-pointer" [tooltip]="'goto last page'" (click)="onLast()"
      [class.disabled]="currentPage == totalPages">
      <a class="page-link ps-4 pe-4 border-left">
        <!-- Last <i class="fe fe-arrow-right ms-1"></i> -->
        <i class="fad fa-chevron-double-right"></i>
      </a>
    </li>
  </ul>
</div>