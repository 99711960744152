<div class="modal-header">
  <h1 class="modal-title pull-left">Before you downgrade your plan... </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>It looks like you have active Premium pipelines. Any active Premium services need to be turned off before downgrading your plan.</p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" routerLink="/pipelines" (click)="bsModalRef.hide()">
    Review Active Pipelines <i class="fe fe-arrow-right-circle ms-2"></i>
  </button>
</div>