import { Injectable, OnInit } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { first, flatMap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userToken: string = null;
  userTokenSubscription$: any;

  constructor(
    private store$: Store<AppState>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$.select('auth').pipe(
      first(),
      mergeMap(auth => {

        let authToken = null;

        const url = new URL(req.url);
        const headers = {
          'content-type': 'application/json'
        };

        if (!environment.httpInterceptor.tokenExcludeDomains.includes(url.origin)) {
          authToken = auth.profile['https://openbridge.com/ob_auth_token'];
          headers['token'] = authToken.token;
        }

        const authReq = !!authToken ? req.clone({
          setHeaders: headers,
        }) : req;
        return next.handle(authReq);
      }),
    );
  }
}
