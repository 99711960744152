<div class="modal-header">
  <h1 class="modal-title pull-left"><i class=" me-2 text-info fa-thin fa-circle-check"></i> Let's activate your account</h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>It appears you do not have an active plan on your account. An active plan unlocks your ability to create data pipelines. Selecting a plan is quick and easy. Our simple, no-hassle pricing ensures we have a plan that fits your needs.  </p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" routerLink="/plans" (click)="bsModalRef.hide()">
    Select A Plan <i class="fa-duotone fa-arrow-from-left ms-2 fa-lg"></i>
  </button>
</div>