import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-youtube-competitor-details',
  templateUrl: './help-youtube-competitor-details.component.html'
})
export class HelpYoutubeCompetitorDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
