import { createAction, props } from '@ngrx/store';

export const wizardIntegrationInit = createAction(
  '[Wizard] Initialize',
  props<any>()
);

export const wizardIntegrationChecklist = createAction(
  '[Wizard] Checklist Updated',
  props<any>()
);

export const wizardIntegrationConfig = createAction(
  '[Wizard] Config Details Updated Stage Transitioned',
  props<any>()
);

export const wizardIntegrationSave = createAction(
  '[Wizard] Pipeline Integration Save'
);

export const wizardIntegrationSaved = createAction(
  '[Wizard] Pipeline Integration Saved'
);

export const wizardIntegrationStagelessConfig = createAction(
  '[Wizard] Config Details Updated',
  props<any>()
);

export const wizardIntegrationName = createAction(
  '[Wizard] Config Name Updated',
  props<any>()
);

export const wizardIntegrationPreviousStage = createAction(
  '[Wizard] Previous Stage',
  props<any>()
);

export const wizardIntegrationSpecificStage = createAction(
  '[Wizard] Specific Stage',
  props<any>()
);

export const wizardIntegrationStagesUpdate = createAction(
  '[Wizard] Update Wizards Stages',
  props<any>()
);

export const wizardIntegrationRequestCacheUpdate = createAction(
  '[Wizard] Update Wizards Request Cache',
  props<any>()
);
