import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { data } from 'msw/lib/types/context';
import { environment } from 'src/environments/environment';
import { changedStateEpochUpdate } from '../store/actions/account.actions';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private httpService: HttpClient,
    private store: Store
  ) { }

  async hasDeltas(accountId: number, epochSeconds: number): Promise<boolean> {

    const requestUrl = environment.openbridgeApiUris.account + '/deltas/' + accountId + '?timestamp=' + epochSeconds;
    const response = await this.httpService.get(requestUrl).toPromise();

    if (!response['data'].has_deltas) {
      console.info('Retaining cached account subscriptions and identities, if they exist');
      return false;
    }
    console.info('Clearing cached account subscriptions and identities, if they exist');
    this.store.dispatch(changedStateEpochUpdate());
    return true;
  }

}
