<div class="row align-items-center mb-4">
  <div class="col">
    <!-- Form -->
    <form novalidate>
      <div class="input-group input-group-lg input-group-merge input-group-reverse">
        <input class="form-control list-search" type="search" placeholder="Search" id="searchInput" #filterField
          (keyup)="processFilteredText(filterField.value)">
        <span class="input-group-text">
          <i class="fe fe-search"></i>
        </span>
      </div>
    </form>
  </div>
  <!-- <div class="col-auto me-n3">
    <form>
      <select class="custom-select custom-select-sm form-control-flush" data-bs-toggle="select"
              data-options='{"minimumResultsForSearch": -1}'>
        <option value="1" selected>9 per page</option>
        <option value="5">All</option>
      </select>
    </form>
  </div> -->
  <!-- <div class="col-auto">
    <div class="dropdown">
      <!-- Toggle --
      <button class="btn btn-sm btn-white" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
        <i class="fe fe-sliders me-1"></i> Filter <span class="badge badge-primary ms-1">1</span>
      </button>
      <!-- Menu --
      <form class="dropdown-menu dropdown-menu-right dropdown-menu-card">
        <div class="card-header">
          <!-- Title --
          <h4 class="card-header-title"> Filters </h4>
          <!-- Link --
          <button class="btn btn-sm btn-link text-reset" type="reset">
            <small>Clear filters</small>
          </button>
        </div>
        <div class="card-body">
          <!-- List group --
          <div class="list-group list-group-flush mt-n4 mb-4">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <!-- Text --
                  <small>Title</small>
                </div>
                <div class="col-auto">
                  <!-- Select --
                  <select class="custom-select custom-select-sm" name="item-title" data-bs-toggle="select"
                          data-options='{"minimumResultsForSearch": -1}'>
                    <option value="*">Any</option>
                    <option value="Designer" selected>Designer</option>
                    <option value="Developer">Developer</option>
                    <option value="Owner">Owner</option>
                    <option value="Founder">Founder</option>
                  </select>
                </div>
              </div> <!-- / .row --
            </div>
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <!-- Text --
                  <small>Lead scrore</small>
                </div>
                <div class="col-auto">
                  <!-- Select --
                  <select class="custom-select custom-select-sm" name="item-score" data-bs-toggle="select"
                          data-options='{"minimumResultsForSearch": -1}'>
                    <option value="*" selected>Any</option>
                    <option value="1/10">1+</option>
                    <option value="2/10">2+</option>
                    <option value="3/10">3+</option>
                    <option value="4/10">4+</option>
                    <option value="5/10">5+</option>
                    <option value="6/10">6+</option>
                    <option value="7/10">7+</option>
                    <option value="8/10">8+</option>
                    <option value="9/10">9+</option>
                    <option value="10/10">10</option>
                  </select>
                </div>
              </div> <!-- / .row --
            </div>
          </div>
          <!-- Button --
          <button class="btn btn-block btn-primary" type="submit"> Apply filter </button>
        </div>
      </form>
    </div> 
  </div>-->
</div> <!-- / .row -->