<!-- Image -->
<div class="avatar avatar-sm card-avatar">
    <img src="../assets/img/avatars/products/google-ads.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Select Google Ads Account </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated Google Ads data pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Select your Google Ads account </h4>
<ol>
    <li class="text-muted mb-2"> If you do not have a Ads account, Google makes it easy to get started. Google offers <a href="https://ads.google.com/" rel="noopener" target="_blank"> a quick and simple online
            registration</a> for new customers </li>
    
    <li class="text-muted mb-2">
                Accounts represent a single advertiser. All data is associated with this single account.
                Please note that Advertisers cannot have more than one profile for each profile.</li>
                <li class="text-muted mb-2">
                    If you do not see any accounts, it is possible you do have have permissions to a Google customer or MCC account. Confirm the user has permissions to the desired account.</li>
    <li class="text-muted mb-2">See our Google Ads <a href="https://docs.openbridge.com/en/?q=google+ads" rel="noopener"
            target="_blank">help docs</a> for guides, tips, and troubleshooting</li>
</ol>