import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-bigquery-identity',
  templateUrl: './modal-bigquery-identity.component.html'
})
export class ModalBigqueryIdentityComponent implements OnInit {
  title: string;
  closeBtnName: string;
  completePercent: number;
  state: string;
  widthStyle = 'width: 0%';
  states: string[] = [
    'remote-identity-sa-validation',
    'remote-identity-create-initialize',
    'remote-identity-create-complete',
  ];
  statePercent: number = (100 / this.states.length);

  constructor(
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.completePercent = this.statePercent;
    this.updateWidthStyle(this.completePercent.toString());
  }

  private updateWidthStyle(percent: string): void {
    this.widthStyle = 'width: ' + percent + '%';
  }
}
