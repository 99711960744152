import { createAction, props } from '@ngrx/store';

export const accountGet = createAction('[Account] Get Account');
export const accountUpdate = createAction('[Account] Update Account',
  props<any>()
);
export const accountDelete = createAction('[Account] Delete Account',
  props<any>()
);

export const dailyPipelinesGet = createAction('[Account] Get Daily Pipelines');
export const dailyPipelinesLoad = createAction('[Account] Load Daily Pipelines',
  props<any>()
);

export const changedStateEpochUpdate = createAction('[Account] Update Epoch Cache');
