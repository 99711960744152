import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-bigquery-identity',
  templateUrl: './help-bigquery-identity.component.html',
  styleUrls: ['./help-bigquery-identity.component.scss']
})
export class HelpBigqueryIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
