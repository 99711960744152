import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-amazon-attribution-checklist',
  templateUrl: './help-amazon-attribution-checklist.component.html'
})
export class HelpAmazonAttributionChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
