import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-notifier',
  templateUrl: './empty-notifier.component.html'
})
export class EmptyNotifierComponent {
  @Input() message: string = null;

  constructor() { }
}
