import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAwsAthena, productIdAwsRedshift, productIdAwsRedshiftSpectrum, productIdAzureBlobStorage,
  productIdAzureDataLake, productIdGoogleBigquery, productIdSnowflakeWarehouse
} from '../product-ids';


export const destinations: Integration[] = [
  {
    productId: productIdAwsAthena,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'AWS Athena',
    type: 'destination',
    class: 'Data Lake',
    sortOrder: 2100,
    tagLine: 'On-demand pricing, efficient, serverless SQL query engine for your data lake',
    routerLink: '/wizards/aws-athena',
    logoPath: vendors.awsAthena,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=athena'
    },
    vendor: {
      text: 'aws.amazon.com/athena',
      uri: 'https://docs.aws.amazon.com/athena/'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAwsRedshift,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'AWS Redshift',
    type: 'destination',
    class: 'Data Warehouse',
    sortOrder: 2200,
    tagLine: 'A fast, petabyte scale cloud first data warehouse for analytics and data science',
    routerLink: '/wizards/aws-redshift',
    logoPath: vendors.awsRedshift,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=redshift'
    },
    vendor: {
      text: 'aws.amazon.com/redshift',
      uri: 'https://docs.aws.amazon.com/redshift/'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAwsRedshiftSpectrum,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'AWS Redshift Spectrum',
    type: 'destination',
    class: 'Data Lake',
    sortOrder: 2300,
    tagLine: 'Extend Redshift with an on-demand SQL query engine your Amazon data lake',
    routerLink: '/wizards/aws-spectrum',
    logoPath: vendors.awsSpectrum,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=spectrum'
    },
    vendor: {
      text: 'aws.amazon.com/spectrum',
      uri: 'https://docs.aws.amazon.com/redshift/latest/dg/c-getting-started-using-spectrum.html'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdGoogleBigquery,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Google',
    name: 'Google BigQuery',
    type: 'destination',
    class: 'Data Warehouse',
    sortOrder: 2600,
    tagLine: 'Serverless, on-demand enterprise class data warehouse with pay-as-you-go pricing',
    routerLink: '/wizards/google-bigquery',
    logoPath: vendors.googleBigquery,
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 12,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=bigquery'
    },
    vendor: {
      text: 'google.com/bigquery',
      uri: 'https://cloud.google.com/bigquery/docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAzureDataLake,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Azure',
    name: 'Azure Data Lake',
    type: 'destination',
    class: 'Data Lake',
    sortOrder: 2500,
    tagLine: 'No-limits, low-cost, Azure data lake offers cost-effective, easy access storage environment',
    routerLink: '/wizards/azure-data-lake',
    logoPath: vendors.azureDataLake,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=azure'
    },
    vendor: {
      text: 'docs.microsoft.com/adls-gen2',
      uri: 'https://docs.microsoft.com/en-us/azure/databricks/data/data-sources/azure/adls-gen2/'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAzureBlobStorage,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Azure',
    name: 'Azure Blob Storage',
    type: 'destination',
    class: 'Data Lake',
    sortOrder: 2400,
    tagLine: 'Basic, durable, and cost-effective data storage for structured and unstructured data',
    routerLink: '/wizards/azure-blob-storage',
    logoPath: vendors.azureBlobStorage,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=azure'
    },
    vendor: {
      text: 'docs.microsoft.com/storage/blobs',
      uri: 'https://docs.microsoft.com/en-us/azure/storage/blobs/'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdSnowflakeWarehouse,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Snowflake',
    name: 'Snowflake Warehouse',
    type: 'destination',
    class: 'Data Warehouse',
    sortOrder: 2700,
    tagLine: 'Fast, reliable, and secure data warehouse with cost-effective pay-as-you-go pricing',
    routerLink: '/wizards/snowflake',
    logoPath: vendors.snowflake,
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com/en/?q=snowflake'
    },
    vendor: {
      text: 'docs.snowflake.com',
      uri: 'https://docs.snowflake.com/en/'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'details',
          'integration-name',
          'save'
        ]
      }
    }
  }
];