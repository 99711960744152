import { EnvironmentConfig } from './environement-config.interface';
import { testPlanStandard, testPlanPremium, testPlanBusiness } from '../app/shared/constants/stripe';


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfig = {
  production: false,
  enableMock: false,
  accMapping: {
    bucketKey: 'ob-zeroadmin-stage'
  },
  auth0: {
    domain: 'openbridge-staging.us.auth0.com',
    clientId: 'gKIKGfgTpAuLM1c2JqQD8BjuZSXGLMhz'
  },
  openbridgeApiUris: {
    partialIdentifier: 'dev',
    account: 'https://account.api.staging.openbridge.io/account',
    auth0Proxy: 'http://localhost:3000/auth0/',
    identities: 'https://remote-identity.api.staging.openbridge.io',
    oauth: 'https://oauth.api.staging.openbridge.io/oauth',
    pipelineCount: 'https://pipeline-count.api.staging.openbridge.io',
    service: 'https://service.api.staging.openbridge.io',
    state: 'https://state.api.staging.openbridge.io/state',
    subscription: 'https://subscriptions.api.staging.openbridge.io',
    user: 'https://user.api.staging.openbridge.io/user'
  },
  stripe: {
    publishableKey: 'pk_test_cNH6JqzL4Lpxn2zN2Ujrr5nw',
    prices: {
      trialPlanId: 'trial-14-day',
      standardPriceId: testPlanStandard,
      premiumPriceId: testPlanPremium,
      businessPriceId: testPlanBusiness,
      enterprisePriceId: 'enterprise'
    }
  },
  premiumEnabledPrices: {
    trialPlanId: true,
    standardPriceId: false,
    premiumPriceId: true,
    businessPriceId: true,
    enterprisePriceId: true
  },
  httpInterceptor: {
    tokenExcludeDomains: [
      'https://openbridge-staging.us.auth0.com'
    ]
  },
  storageValidation: {
    timeBetweenPollsInMilliseconds: 2000,
    numberOfPollsBeforeFailure: 40
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
