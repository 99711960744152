import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-youtube-channel-insights-details',
  templateUrl: './help-youtube-channel-insights-details.component.html'
})
export class HelpYoutubeChannelInsightsDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
