import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-active-premium-subscription',
  templateUrl: './modal-active-premium-subscription.component.html',
  styleUrls: ['./modal-active-premium-subscription.component.scss']
})
export class ModalActivePremiumSubscriptionComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

}
