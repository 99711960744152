import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { AppState } from 'src/app/core/store/reducers';
import { colors } from '../../charts/chart-options';

@Component({
  selector: 'app-card-daily-pipelines',
  templateUrl: './card-daily-pipelines.component.html',
  styleUrls: ['./card-daily-pipelines.component.scss']
})
export class CardDailyPipelinesComponent implements OnInit {
  @Input() barChartTickLimit: number;
  barChartDataSets: ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  accountReducerSubscription$: any;
  dailyPipelines: any;

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.accountReducerSubscription$ = this.store$.select('account').subscribe(response => {
      if (response.dailyPipelines !== null) {
        this.dailyPipelines = response.dailyPipelines;
        const labels = [...response.dailyPipelines.labels];
        const data = [...response.dailyPipelines.data];
        labels.splice(30, 1);
        data.splice(30, 1);
        this.barChartLabels = labels;
        this.barChartDataSets = [{
          maxBarThickness: 10,
          hoverBackgroundColor: colors['700'],
          backgroundColor: colors['300'],
          data: data
        }];
      }
    });
  }
}
