<!-- Image -->
<div class="avatar avatar-sm card-avatar">

    <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
        <i class="fad fa-timeline-arrow"></i>
    </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Key considerations for pipelines </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> What is a data pipeline? </h4>
<ol>
    <li class="text-muted mb-2"> A data pipeline reflects the flow of data from a specific source to a target
        destination. For example, the connection between a source (i.e., a Facebook page, Seller account, YouTube channel...) and target destination
        (i.e., Azure, AWS Athena, Snowflake, BigQuery...) is a considered a data pipeline. </li>
        <li class="text-muted mb-2"> It can take 24-48 hours for an initial sync to occur between a source and destination. </li>

        
        <li class="text-muted mb-2"> All source APIs are carefully pre-modeled for limits and data availability. As a result, scheduling is not user-configurable. Read more about scheduling and timing
            in <a href="https://docs.openbridge.com/en/articles/5675188-understanding-data-pipeline-scheduling" rel="noopener" target="_blank" class="light-link">Understanding Data Pipeline Scheduling</a>.</li>
</ol>
<h4 class="mt-2 mb-2"> Pipelines, Sources and Destinations </h4>
<ol>
    <li class="text-muted mb-2">A data pipeline is <a href="https://docs.openbridge.com/en/articles/5672649-understanding-data-source-apis"
        rel="noopener" target="_blank" class="light-link">wholly dependent on APIs to collect data from source systems and APIs to write data to destinations</a>. As a result, the flow of data from a source to a destination is dependent on the availability, consistency, and durability of these APIs.</li>
        
   

</ol>

<h4 class="mt-2 mb-2"> Data pipelines and pricing </h4>
<ol>
    <li class="text-muted mb-2">Our pricing is based on usage or "pay-as-you-go" for pipelines. All plans leverage
        pay-as-you-go pricing for data pipelines. Why? Pay-as-you-go allows you to quickly adapt to changing needs and
        get charged based on actual usage levels. We detail pricing uses cases in our <a
            href="https://docs.openbridge.com/en/articles/5231237-how-pricing-and-billing-work-preview#h_07b149aa39"
            rel="noopener" target="_blank" class="light-link">pricing examples</a>.</li>

</ol>