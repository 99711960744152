import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-facebook-page-insights-details',
  templateUrl: './help-facebook-page-insights-details.component.html'
})
export class HelpFacebookPageInsightsDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
