import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { HelpDrawerService } from './help-drawer.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-help-drawer',
  templateUrl: './help-drawer.component.html',
  styleUrls: ['./help-drawer.component.scss'],
  animations: [
    trigger('helpDrawer', [
      state('true', style({ right: '-550px' })),
      state('false', style({ right: '0px' })),
      transition('0 => 1', animate('.2s')),
      transition('1 => 0', animate('.2s'))

    ]),
    trigger('helpDrawerOverlay', [
      state('true', style({ opacity: 1, display: 'block' })),
      state('false', style({ opacity: 0, display: 'none' })),
      transition('0 => 1', animate('.2s')),
      transition('1 => 0', animate('.5s'))

    ])
  ]
})
export class HelpDrawerComponent implements OnInit, OnDestroy {
  modalClasses = [];
  overlayClasses = [];
  drawerIsOpen: boolean;
  helpComponentIdentifier: string;
  helpDrawer = true;
  helpDrawerOverlay = false;
  integration: Integration | null = null;

  constructor(
    private drawService: HelpDrawerService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.drawerIsOpen = false;
    this.helpComponentIdentifier = null;
    this.drawService.drawerActionResponder$.subscribe(response => {
      this.handleDrawerActionResponder(response);
    });
  }

  ngOnDestroy(): void {
    this.removeBodyClass();
  }

  handleDrawerActionResponder(response: { drawerIsOpen: boolean, drawerComponentIdentifier: string, integration?: Integration }): void {

    this.drawerIsOpen = response.drawerIsOpen;
    this.helpComponentIdentifier = response.drawerComponentIdentifier;
    this.integration = response.integration || null;

    if (!this.drawerIsOpen) {
      this.closeDrawer();
    }
    else {
      this.openDrawer();
    }
  }

  toggleModal(): boolean {
    this.drawService.toggleDrawer('any');
    return false;
  }

  openDrawer(): void {
    // this.renderer.addClass(document.body, 'modal-open');
    this.overlayClasses.push('show');
    this.overlayClasses.push('fade');
    this.helpDrawerOverlay = !this.helpDrawerOverlay;
    this.helpDrawer = !this.helpDrawer;
  }

  closeDrawer(): void {
    this.removeBodyClass();
    let i: number = this.overlayClasses.indexOf('show');
    if (i >= 0) {
      this.overlayClasses.splice(i, 1);
    }
    i = this.overlayClasses.indexOf('fade');
    if (i >= 0) {
      this.overlayClasses.splice(i, 1);
    }
    this.helpDrawerOverlay = !this.helpDrawerOverlay;
    this.helpDrawer = !this.helpDrawer;
  }

  private removeBodyClass(): void {
    // this.renderer.removeClass(document.body, 'modal-open');
  }
}