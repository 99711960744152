import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { flashNotificationsClear } from 'src/app/core/store/actions/flash-notifications.actions';
import { AppState } from 'src/app/core/store/reducers';
import { ModalBigqueryIdentityComponent } from 'src/app/modules/wizards/components/modals/modal-bigquery-identity/modal-bigquery-identity.component';
import { GoogleBigqueryService } from 'src/app/modules/wizards/pages/google-bigquery/google-bigquery.service';
import { modalNonUserClosable } from 'src/app/shared/constants/component-configs';

@Component({
  selector: 'app-identity-bigquery-create',
  templateUrl: './identity-bigquery-create.component.html',
  styleUrls: ['./identity-bigquery-create.component.scss']
})
export class IdentityBigqueryCreateComponent implements OnInit {
  files: File[] = [];
  json: any;
  bsModalRef: BsModalRef;
  jsonExpectedKeys: string[] = [];

  constructor(
    protected store$: Store<AppState>,
    private bigQueryService: GoogleBigqueryService,
    private modalService: BsModalService

  ) { }

  ngOnInit(): void {
    this.bigQueryService.modalProgress$.subscribe(response => {
      if ((response === 'remote-identity-create-complete') || (response === 'remote-identity-create-error')) {
        this.closeModalWithComponent();
      }
    });
  }

  validate(serviceAccountString: string): void {
    this.openModalWithComponent();
    this.bigQueryService.createBigQueryIdentityFromServiceAccount(serviceAccountString.replace(/(\r\n|\n|\r)/gm, ''));
  }

  onSelect(event): void {
    this.store$.dispatch(flashNotificationsClear());
    const self = this;
    this.files.push(...event.addedFiles);
    const reader: FileReader = new FileReader();
    reader.onload = ((theFile) => {
      return (e: any) => {
        self.validate(e.target.result);
      };
    })(this.files[0]);
    reader.readAsText(this.files[0]);
  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  private openModalWithComponent(): void {
    this.bsModalRef = this.modalService.show(ModalBigqueryIdentityComponent, modalNonUserClosable);
  }

  private closeModalWithComponent(): void {
    this.bsModalRef.hide();
  }


}
