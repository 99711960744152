import { createAction, props } from '@ngrx/store';

export const subscriptionsLoad = createAction('[Subscriptions] Load Subscriptions',
  props<any>()
);
export const subscriptionsGet = createAction('[Subscriptions] Get Subscriptions');
export const subscriptionGet = createAction('[Subscriptions] Get Subscription');
export const subscriptionAdd = createAction('[Subscriptions] Add Subscription',
  props<any>()
);
export const subscriptionUpdate = createAction('[Subscriptions] Update Subscription',
  props<any>()
);
export const subscriptionDelete = createAction('[Subscriptions] Delete Subscription',
  props<any>()
);
