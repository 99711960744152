import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-google-search-ads-details',
  templateUrl: './help-google-search-ads-details.component.html'
})
export class HelpGoogleSearchAdsDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
