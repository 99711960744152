<div class="modal-header">
  <h1 class="modal-title pull-left">
    View Transaction
  </h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-sm table-hover table-nowrap card-table">
      <ng-container>
        <tbody class="list font-size-base">
          <tr>
            <td width='25%'>
              Subscription Id:
            </td>
            <td width='75%'>
              <span class="small text-muted"> {{data.id | number:'6.0' | removeComma}} </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Transaction Id:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.transaction_id">
                {{data.transaction_id | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Company:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.company">{{ data.company | truncate:[50, '...'] }}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Email:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.email_address">
                {{ data.email_address | truncate:[50, '...'] }}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Product Name:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.product_name">
                {{data.product_name | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Subscription Name:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.subscription_name">
                {{data.subscription_name | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Status:
            </td>
            <td width='75%'>
              <span class="small text-muted">{{data.status}}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Message:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.message">
                {{data.message | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Owner:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.owner">
                {{data.owner | truncate:[50, '...']}}
              </span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Sender:
            </td>
            <td width='75%'>
              <span class="small text-muted" [tooltip]="data.sender">{{data.sender | truncate:[50, '...']}}</span>
            </td>
          </tr>
          <tr>
            <td width='25%'>
              Modified At:
            </td>
            <td width='75%'>
              <span class="small text-muted">{{data.modified_at | customDate}}</span>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
  
  <!-- <ul class="list-group list-group-focus">
    <li class="list-group-item list-group-item-action">
      <h4 class="text-body text-focus mb-1">
        <span class="text-muted">Id:</span> {{data.id}}
      </h4>
    </li>
  </ul> -->
</div>
<div class="modal-footer">
  <a href (click)="copyMessage(data);">Copy Details</a>
  <!-- <button class="btn btn-premium" routerLink="/plans" (click)="bsModalRef.hide()">
    Upgrade Now <i class="fa-duotone fa-arrow-from-left ms-2 fa-lg"></i>
  </button> -->
</div>