import { IdentityType } from '../models/identity-type.type';
import { identityIdGoogleBigquery } from './identity-ids';
import { vendors } from './logos';

export const identityTypes: IdentityType[] = [
  {
    remoteIdentityTypeId: 1,
    name: 'Google',
    logoPath: vendors.google
  },
  {
    remoteIdentityTypeId: 2,
    name: 'Facebook',
    logoPath: vendors.facebook
  },
  {
    remoteIdentityTypeId: 8,
    name: 'Google Adwords',
    logoPath: vendors.googleAds
  },
  {
    remoteIdentityTypeId: 12,
    name: 'Google BigQuery Service Account',
    logoPath: vendors.googleBigquery
  },
  {
    remoteIdentityTypeId: 13,
    name: 'Amazon MWS',
    logoPath: vendors.amazon
  },
  {
    remoteIdentityTypeId: 14,
    name: 'Amazon Advertising',
    logoPath: vendors.amazon
  },
  {
    remoteIdentityTypeId: 15,
    name: 'Google',
    logoPath: vendors.google
  },
  {
    remoteIdentityTypeId: 16,
    name: 'Shopify',
    logoPath: vendors.shopify
  },
  {
    remoteIdentityTypeId: 17,
    name: 'Amazon Selling Partners',
    logoPath: vendors.amazon
  }
];