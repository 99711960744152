export const responsePipelineCount = {
  links: {
    first: 'https://pipeline-count.api.dev.openbridge.io/count?date=2022-01-19&page=1',
    last: 'https://pipeline-count.api.dev.openbridge.io/count?date=2022-01-19&page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'PipelineCount',
      id: '595',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 14,
        date: '2022-01-19',
        created_at: '2022-01-19T11:45:45.012541',
        modified_at: '2022-01-19T11:45:45.012541'
      }
    },
    {
      type: 'PipelineCount',
      id: '601',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 14,
        date: '2022-01-20',
        created_at: '2022-01-20T11:45:45.413707',
        modified_at: '2022-01-20T11:45:45.413707'
      }
    },
    {
      type: 'PipelineCount',
      id: '607',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 15,
        date: '2022-01-21',
        created_at: '2022-01-21T11:45:45.499654',
        modified_at: '2022-01-21T11:45:45.499654'
      }
    },
    {
      type: 'PipelineCount',
      id: '613',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 14,
        date: '2022-01-22',
        created_at: '2022-01-22T11:45:45.737709',
        modified_at: '2022-01-22T11:45:45.737709'
      }
    },
    {
      type: 'PipelineCount',
      id: '619',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 14,
        date: '2022-01-23',
        created_at: '2022-01-23T11:45:44.911919',
        modified_at: '2022-01-23T11:45:44.911919'
      }
    },
    {
      type: 'PipelineCount',
      id: '625',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 22,
        date: '2022-01-24',
        created_at: '2022-01-24T11:45:46.043669',
        modified_at: '2022-01-24T11:45:46.043669'
      }
    },
    {
      type: 'PipelineCount',
      id: '631',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 24,
        date: '2022-01-25',
        created_at: '2022-01-25T11:45:44.780650',
        modified_at: '2022-01-25T11:45:44.780650'
      }
    },
    {
      type: 'PipelineCount',
      id: '661',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 24,
        date: '2022-01-26',
        created_at: '2022-01-26T11:45:45.230104',
        modified_at: '2022-01-26T11:45:45.230104'
      }
    },
    {
      type: 'PipelineCount',
      id: '667',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 16,
        date: '2022-01-27',
        created_at: '2022-01-27T11:45:45.092713',
        modified_at: '2022-01-27T11:45:45.092713'
      }
    },
    {
      type: 'PipelineCount',
      id: '673',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-01-28',
        created_at: '2022-01-28T11:45:45.026547',
        modified_at: '2022-01-28T11:45:45.026547'
      }
    },
    {
      type: 'PipelineCount',
      id: '679',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-01-29',
        created_at: '2022-01-29T11:45:45.235617',
        modified_at: '2022-01-29T11:45:45.235617'
      }
    },
    {
      type: 'PipelineCount',
      id: '685',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-01-30',
        created_at: '2022-01-30T11:45:44.842009',
        modified_at: '2022-01-30T11:45:44.842009'
      }
    },
    {
      type: 'PipelineCount',
      id: '691',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-01-31',
        created_at: '2022-01-31T11:45:45.304705',
        modified_at: '2022-01-31T11:45:45.304705'
      }
    },
    {
      type: 'PipelineCount',
      id: '697',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-01',
        created_at: '2022-02-01T11:45:44.909441',
        modified_at: '2022-02-01T11:45:44.909441'
      }
    },
    {
      type: 'PipelineCount',
      id: '703',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-02',
        created_at: '2022-02-02T11:45:45.563284',
        modified_at: '2022-02-02T11:45:45.563284'
      }
    },
    {
      type: 'PipelineCount',
      id: '709',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-03',
        created_at: '2022-02-03T11:45:45.269662',
        modified_at: '2022-02-03T11:45:45.269662'
      }
    },
    {
      type: 'PipelineCount',
      id: '715',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-04',
        created_at: '2022-02-04T11:45:45.505913',
        modified_at: '2022-02-04T11:45:45.505913'
      }
    },
    {
      type: 'PipelineCount',
      id: '721',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-05',
        created_at: '2022-02-05T11:45:45.071656',
        modified_at: '2022-02-05T11:45:45.071656'
      }
    },
    {
      type: 'PipelineCount',
      id: '727',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-06',
        created_at: '2022-02-06T11:45:44.637383',
        modified_at: '2022-02-06T11:45:44.637383'
      }
    },
    {
      type: 'PipelineCount',
      id: '733',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-07',
        created_at: '2022-02-07T11:45:45.214292',
        modified_at: '2022-02-07T11:45:45.214292'
      }
    },
    {
      type: 'PipelineCount',
      id: '739',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-08',
        created_at: '2022-02-08T11:45:45.925361',
        modified_at: '2022-02-08T11:45:45.925361'
      }
    },
    {
      type: 'PipelineCount',
      id: '745',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-09',
        created_at: '2022-02-09T11:45:45.253492',
        modified_at: '2022-02-09T11:45:45.253492'
      }
    },
    {
      type: 'PipelineCount',
      id: '751',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-10',
        created_at: '2022-02-10T11:45:45.952425',
        modified_at: '2022-02-10T11:45:45.952425'
      }
    },
    {
      type: 'PipelineCount',
      id: '757',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-11',
        created_at: '2022-02-11T11:45:44.820829',
        modified_at: '2022-02-11T11:45:44.820829'
      }
    },
    {
      type: 'PipelineCount',
      id: '763',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-12',
        created_at: '2022-02-12T11:45:44.971012',
        modified_at: '2022-02-12T11:45:44.971012'
      }
    },
    {
      type: 'PipelineCount',
      id: '769',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-13',
        created_at: '2022-02-13T11:45:45.089980',
        modified_at: '2022-02-13T11:45:45.089980'
      }
    },
    {
      type: 'PipelineCount',
      id: '775',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-14',
        created_at: '2022-02-14T11:45:45.029337',
        modified_at: '2022-02-14T11:45:45.029337'
      }
    },
    {
      type: 'PipelineCount',
      id: '781',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 17,
        date: '2022-02-15',
        created_at: '2022-02-15T11:45:45.809342',
        modified_at: '2022-02-15T11:45:45.809342'
      }
    },
    {
      type: 'PipelineCount',
      id: '787',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 26,
        date: '2022-02-16',
        created_at: '2022-02-16T11:45:45.480066',
        modified_at: '2022-02-16T11:45:45.480066'
      }
    },
    {
      type: 'PipelineCount',
      id: '793',
      attributes: {
        account_id: 342,
        stripe_customer_id: 'cus_KPgiun9YhOBtyE',
        quantity: 26,
        date: '2022-02-17',
        created_at: '2022-02-17T11:45:45.600682',
        modified_at: '2022-02-17T11:45:45.600682'
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 30
    }
  }
};