import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-billing',
  templateUrl: './help-billing.component.html',
})
export class HelpBillingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
