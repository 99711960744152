import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getFormattedDate(): string {
    const d = new Date();
    return d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth() + 1))
      .slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2) + ' ' + ('0' + d.getUTCHours())
        .slice(-2) + ':' + ('0' + d.getUTCMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2);
  }

}
