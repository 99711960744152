<div class="modal-header">
    <h4 class="modal-title pull-left">Requesting Healthcheck reports</h4>
</div>
<div class="modal-body">
    <div class="mb-1">
        <div [ngSwitch]="state">
            <div *ngSwitchCase="'healthcheck-process-complete'">
                Process Complete.
            </div>
            <div *ngSwitchCase="'healthcheck-process-initialize'">
                Processing to request your data.
            </div>
            <div *ngSwitchDefault>
                Processing request. This will take couple of seconds.
            </div>
        </div>
    </div>
    <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100" [style]="widthStyle"></div>
    </div>
</div>