import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-list-filter',
  templateUrl: './pick-list-filter.component.html'
})
export class PickListFilterComponent implements OnInit, OnChanges {
  @Input() cardData: any[];
  @Output() filteredDataEmitter = new EventEmitter<any>();
  filterForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filterInput: new FormControl('')
    });
    this.filter('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter('');
  }

  filter(filterText: string): void {
    let filteredData: any[] = [];

    // Filter text search.
    if (filterText === '') {
      filteredData = this.cardData;
    }
    else {
      this.cardData.forEach((value, index) => {
        let add = false;

        if (value.title.includes(filterText)) {
          add = true;
        }

        value.meta.forEach((metaValue, metaIndex) => {
          if (metaValue.value.includes(filterText)) {
            add = true;
          }
        });

        if (add === true) {
          filteredData.push(value);
        }
      });
    }

    // let filteredData: any[] = this.cardData;
    this.filteredDataEmitter.emit(filteredData);
  }

  onKeyUp(event): void {
    this.filter(this.filterForm.value.filterInput);
  }

  onSubmit(): boolean {
    return false;
  }
}
