import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IdentityTypeService } from 'src/app/shared/services/identity-type.service';
import { environment } from 'src/environments/environment';
import { AppState } from '../store/reducers';
import { ApiStateService } from './api-state.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  identities$: Subject<any>;

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private apiStateService: ApiStateService,
    private identityTypeService: IdentityTypeService
  ) {
    this.identities$ = new Subject();
  }

  getIdentitiesByRemoteIdentityType(remoteIdentityTypeId: number): void {
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identity => {
        identities.push(this.identityObjectForIdentity(identity));
      });
      this.identities$.next(identities);
    });
  }

  getIdentityById(identityId: number): any {
    let responseIdentity: any = null;
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identity => {
        if (identity.id === identityId) {
          responseIdentity = identity;
        }
      });
    });
    return responseIdentity;
  }

  private identityObjectForIdentity(identity): any {
    const tmpIdentity = {
      id: identity.id,
      remoteIdentityTypeId: +identity.remoteIdentityTypeId,
      new: false,
      title: identity.name,
      logoUri: '/assets/img/avatars/products/google-cloud.svg',
      meta: [
        {
          name: 'Provider Id',
          value: identity.remoteUniqueId
        },
        {
          name: 'Region',
          value: identity.region
        }
      ]
    };

    return tmpIdentity;
  }

  async getAll(): Promise<any> {

    try {
      const pageSize = 100;
      const subscriptionsResponses = [];

      const firstPage = await this.requestIdentitypage(1, pageSize);
      const pages = firstPage['body'].meta.pagination.pages;
      subscriptionsResponses.push(firstPage.body);

      for (let index = 2; index < pages; index++) {
        const requestedPage = await this.requestIdentitypage(index, pageSize);
        subscriptionsResponses.push(requestedPage.body);
      }

      return subscriptionsResponses;
    }
    catch (error) {
      throw error;
    }
  }

  async requestIdentitypage(page: number, pageSize: number): Promise<any> {
    const baseUri =  environment.openbridgeApiUris.identities + '/sri';
    const identitiesGetUri = baseUri + '?page_size=' + pageSize + '&page=' + page;
    return await this.httpClient.get(identitiesGetUri, { observe: 'response' }).toPromise();
  }

  appendArrayToArray(firstArray, secondArray): any[] {
    return [
      ...firstArray,
      secondArray
    ];
  }

  transformResponse(value: any): any {

    const transformedData = {
      id: +value.id,
      identityHash: value.attributes.identity_hash,
      name: value.attributes.name,
      accountId: value.attributes.account_id,
      userId: value.attributes.user_id,
      firstName: value.relationships.user.first_name,
      lastName: value.relationships.user.last_name,
      remoteIdentityTypeId: +value.relationships.remote_identity_type.data.id,
      invalidIdentity: value.attributes.invalid_identity,
      remoteUniqueId: value.attributes.remote_unique_id,
      createdAt: value.attributes.created_at,
      modifiedAt: value.attributes.modified_at,
      region: value.attributes.region
    };

    const identityType = this.identityTypeService.findIdentityTypeFromId(transformedData.remoteIdentityTypeId);
    // check for identity type is exist for this remoteIdentity if yes then return the transformedData otherwise return null
    if (identityType) {
      return transformedData;
    }

  }

  identityExists(remoteIdentityTypeId: number, remoteUniqueId: string): boolean {
    let exists = false;
    this.store$.select('identities').pipe(take(1)).subscribe(state => {
      const identities: any[] = [];
      state.identities.forEach(identity => {
        if (identity.remoteIdentityTypeId === remoteIdentityTypeId && identity.remoteUniqueId === remoteUniqueId) {
          exists = true;
        }
      });
    });
    return exists;
  }

  async reauthOauthIdentity(identity: any): Promise<void> {

    const stateResponse = await this.apiStateService.createOauthState(
      identity.accountId,
      identity.userId,
      identity.remoteIdentityTypeId,
      window.location.href,
      identity.region);

    window.location.href = environment.openbridgeApiUris.oauth +
      '/initialize?state=' + stateResponse.body['data']['id'];

  }

  authOauthIdentity(identity): void {

  }
}
