import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-youtube-channel-insights-identity',
  templateUrl: './help-youtube-channel-insights-identity.component.html'
})
export class HelpYoutubeChannelInsightsIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
