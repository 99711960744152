import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/account.actions';

// CHange "any" to whatever the user model will be in the future.
export interface AccountState {
  account: any | null;
  dailyPipelines: any | null;
}

//  Create the initial state for the authenticated user.
const initialAccountState: AccountState = {
  account: null,
  dailyPipelines: null,
};

export const reducer = createReducer(
  initialAccountState,
  // on(actions.dailyPipelinesGet, state => { return null; }),
  on(actions.dailyPipelinesLoad, (state, payload) => {
    return {
      ...state,
      dailyPipelines: payload.dailyPipelines
    };
  }),
  on(actions.accountGet, state => null),
  on(actions.accountUpdate, state => null),
  on(actions.accountDelete, state => null),
);
