import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationService } from '../services/integration.service';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'integrationIsConfigurable' })
export class IntegrationIsConfigurablePipe implements PipeTransform {

  constructor(
    private integrationService: IntegrationService
  ) { }

  transform(value: number): boolean {
    const integration = this.integrationService.findIntegrationFromId(value);
    if (integration) {
      return integration.isConfigurable;
    }
    return false;
  }
}