<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/google-bigquery.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Authorization </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Grant Openbridge access to BigQuery </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading -->
<h4 class="mb-2"> Use Existing Google Service Account </h4>
<ol>
  <li class="text-muted mb-2"> Select an existing Google Cloud service account that has been previously registered with
    Openbridge</li>
</ol>
<!-- Heading -->
<h4 class="mt-2 mb-2"> Create New Google Service Account </h4>
<ol>
  <li class="text-muted mb-2">Google requires service account authorization to BigQuery. Refer to <a class="light-link"
      href="https://cloud.google.com/iam/docs/creating-managing-service-accounts" rel="noopener" target="_blank">Google
      Cloud’s documentation about
      service accounts</a> for more information. </li>
  <li class="text-muted mb-2"> If you have not previously authorized us access to BigQuery, you will need to create a
    new service account. You can check our <a
      href="https://docs.openbridge.com/en/articles/1856793-how-to-set-up-google-bigquery-creating-and-configuring-service-accounts-in-google-cloud-console"
      rel="noopener" target="_blank" class="light-link">step-by-step guide</a> for tips </li>
  <li class="text-muted mb-2"> If creating new service account from the Google Cloud console, go to <code>IAM & Admin >
      Service accounts</code>
  </li>
  <li class="text-muted mb-2"> Click <code>Create Service Account</code> in the Google interface </li>
  <li class="text-muted mb-2"> Grant the roles <code>Project Browser</code> and <code>BigQuery Admin</code>
  </li>
  <li class="text-muted mb-2"> Create a <code>JSON key</code>. Upload the file to create a new authorization </li>
</ol>