import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-amazon-dsp-checklist',
  templateUrl: './help-amazon-dsp-checklist.component.html',
  styleUrls: ['./help-amazon-dsp-checklist.component.scss']
})
export class HelpAmazonDspChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
