<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/amazon-athena.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-managed data pipeline into Amazon Athena </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Automated Amazon Athena Configuration </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Amazon Web Services or AWS account. AWS
    offers <a href="https://aws.amazon.com/free" rel="noopener" target="_blank"> free trials and service credits</a> for
    new customers </li>
  <li class="text-muted mb-2"> If you already have an AWS account, follow the <a
      href="https://docs.openbridge.com/en/articles/2579879-how-to-use-cloudformation-to-configure-amazon-s3-iam-and-amazon-athena"
      rel="noopener" target="_blank">AWS
      Athena Data Lake CloudFormation</a> setup guide. </li>
  <li class="text-muted mb-2"> Make sure you have you taken note of the <code>SecretKeyForIAMUser</code>,
    <code>AccessKeyForIAMUser</code>, and <code>BucketName</code> in the AWS interface. You will need this in the next
    step
  </li>
</ol>