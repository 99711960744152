import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({

  selector: '[appPickListCard]',
  templateUrl: './pick-list-card.component.html'
})
export class PickListCardComponent implements OnInit {
  @Input() cardData;
  @Input() parentForm = FormGroup;
  @Input() controlName: string;


  constructor() { }

  ngOnInit(): void {}

}
