import { Pipe, PipeTransform } from '@angular/core';
import { Integration } from '../models/integration.model';
import { IntegrationService } from '../services/integration.service';

@Pipe({name: 'integrationFullname'})
export class IntegrationFullnamePipe implements PipeTransform {

  constructor(
    private integrationService: IntegrationService
  ) {}

  transform(integration: Integration): string {
    return integration.brand + ' ' + integration.name;
  }
}