import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comingSoonIntegration'
})
export class ComingSoonIntegrationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      return 'card-coming-soon';
    } else {
      return '';
    }
  }

}
