import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/subscriptions.actions';
import { setCache } from 'src/app/core/functions/cache';

const subscriptionCacheKey = 'list-subscriptions';

// CHange "any" to whatever the user model will be in the future.
export interface SubscriptionsState {
  subscriptions: any[] | null;
}

//  Create the initial state for the authenticated user.
const initialSubscriptionsState: SubscriptionsState = {
  subscriptions: []
};

export const reducer = createReducer(
  initialSubscriptionsState,
  on(actions.subscriptionsLoad, (state, payload) => {
    const stateData = {
      ...state,
      subscriptions: payload.subscriptions
    };
    setCache('local', subscriptionCacheKey, stateData);
    return stateData;
  }),
  on(actions.subscriptionAdd, (state, payload) => {
    const pipelines = state.subscriptions;
    const stateData = {
      ...state,
      subscriptions: [
        ...pipelines,
        payload.pipelineSubscription
      ]
    };

    setCache('local', subscriptionCacheKey, stateData);
    return stateData;
  }),
  on(actions.subscriptionUpdate, (state, payload) => {
    const subscription = payload.pipelineSubscription;
    let stateData = null;
    if (subscription) {
      stateData = {
        subscriptions: state.subscriptions.map(obj => [subscription].find(o => o.id === obj.id) || obj)
      };
    } else {
      stateData = { ...state };
    }
    setCache('local', subscriptionCacheKey, stateData);
    return stateData;
  })
);
