import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-counter',
  templateUrl: './step-counter.component.html'
})
export class StepCounterComponent {
  @Input() totalStages: number = 0;
  @Input() stagePosition: number = 0;

  constructor() { }

}
