// Destinations.
export const productIdAwsAthena = 31;
export const productIdAwsRedshift = 29;
export const productIdAwsRedshiftManaged = 14;
export const productIdAwsRedshiftSpectrum = 36;
export const productIdSnowflakeWarehouse = 52;
export const productIdGoogleBigquery = 37;
export const productIdAzureDataLake = 47;
export const productIdAzureBlobStorage = 46;

// Sources
export const productIdAmazonAdvertising = 40;
export const productIdAmazonAttribution = 50;
export const productIdAmazonDsp = 54;

export const productIdAmzSellingPartnersOrders = 53;
export const productIdAmzSellingPartnersFinanceRt = 55;
export const productIdAmzSellingPartnersInbound = 56;
export const productIdAmzSellingPartnersSettlement = 57;

export const productIdAmzSellingBusRepFullfillmentRt = 58;
export const productIdAmzSellingBusRepInventoryRt = 59;
export const productIdAmzSellingBusRepInventory = 60;
export const productIdAmzSellingBusRepSales = 61;
export const productIdAmzSellingBusRepFees = 62;

export const productIdBatchSftpProcessing = 33;
export const productIdAwsKinesisWebhooks = 35;
export const productIdFacebookMarketing = 11;
export const productIdFacebookPageInsights = 2;
export const productIdGoogleAds = 63;
export const productIdGoogleAnalytics360 = 3;
export const productIdGoogleCampaignManager = 4;
export const productIdGoogleSearchAds360 = 7;
export const productIdInstagramInsights = 34;
export const productIdInstagramStories = 38;
export const productIdShopifyInsights = 49;
export const productIdYoutubeChannelInsights = 17;
export const productIdYoutubeCompetitorChannels = 18;
export const productIdYoutubeVideoInsights = 26;
export const productIdYoutubeCompetitorVideo = 15;

