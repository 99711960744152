import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HealthchecksService } from 'src/app/modules/healthchecks/healthchecks.service';

@Component({
  selector: 'app-modal-process-healthcheck-request',
  templateUrl: './modal-process-healthcheck-request.component.html',
  styleUrls: ['./modal-process-healthcheck-request.component.scss']
})
export class ModalProcessHealthcheckRequestComponent implements OnInit {
  title: string;
  closeBtnName: string;
  completePercent: number;
  state: string;
  widthStyle = 'width: 0%';
  states: string[] = [
    'healthcheck-process-initialize',
    'healthcheck-process-complete'
  ];
  statePercent: number = (100 / this.states.length);

  constructor(
    public bsModalRef: BsModalRef,
    private healthchecksService: HealthchecksService
  ) { }

  ngOnInit(): void {
    this.completePercent = this.statePercent;
    this.updateWidthStyle(this.completePercent.toString());
    this.healthchecksService.healthCheckRequestState$.subscribe(response => {
      if (this.state !== response.state) {
        this.state = response.state;
        this.completePercent += this.statePercent;
        this.updateWidthStyle(this.completePercent.toString());
      }
    });
  }

  private updateWidthStyle(percent: string): void {
    this.widthStyle = 'width: ' + percent + '%';
  }

}
