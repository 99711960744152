import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { colors } from '../chart-options';

@Component({
  selector: 'app-chart-doughnut',
  templateUrl: './chart-doughnut.component.html'
})
export class ChartDoughnutComponent implements OnInit {
 // Data Related
 @Input() chartDataSets: ChartDataSets[];
 @Input() chartLabels: Label[];
 @Input() chartPlugins: any[];
 @Input() chartLegend: boolean;

 // Style related.
 @Input() chartClass: string;
 @Input() chartHeight: string;
 @Input() chartStyle: string;
 @Input() chartWidth: string;

 chartType: ChartType = 'doughnut';
 chartOptions: ChartOptions;

 constructor() { }

 ngOnInit(): void {
   this.chartOptions = {
      elements: {
        arc: {
          borderWidth: 2,
          borderColor: colors.blue
        },
      },
      responsive: true,
      cutoutPercentage: 83,
      scales: {
       
       yAxes: [{
         id: 'yAxisOne',
         type: 'linear',
         display: 'auto',
         ticks: {
           callback: function(value) {
             return '$' + value + 'k';
           }
         }
       }, {
         id: 'yAxisTwo',
         type: 'linear',
         display: 'auto',
         ticks: {
           callback: function(value) {
             return value + 'hrs';
           }
         }
       }]
     }
   }
 }
}
