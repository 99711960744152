export const responseBigQuerySubscription = {
  data: {
    type: 'Subscription',
    id: '229',
    attributes: {
      price: 0,
      status: 'active',
      date_start: '2021-12-31T11:50:13',
      date_end: '2021-12-31T11:50:13',
      auto_renew: 1,
      created_at: '2021-12-31T11:50:17.439814',
      modified_at: '2021-12-31T11:50:31.827098',
      quantity: 1,
      stripe_subscription_id: '',
      name: 'big-query-mock-1',
      rabbit_payload_successful: 0,
      primary_job_id: null,
      pipeline: null,
      invalid_subscription: 0,
      invalidated_at: null,
      notified_at: null,
      canonical_name: null,
      account_id: 432,
      product_id: 37,
      product_plan_id: null,
      remote_identity_id: 110,
      storage_group_id: 145,
      user_id: 399,
      history_requested: 0
    },
    relationships: {
      account: {
        data: {
          type: 'Account',
          id: '432'
        }
      },
      product: {
        data: {
          type: 'Product',
          id: '37'
        }
      },
      product_plan: {
        data: null
      },
      remote_identity: {
        data: {
          type: 'RemoteIdentity',
          id: '110'
        }
      },
      storage_group: {
        data: {
          type: 'StorageGroup',
          id: '145'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '399'
        }
      }
    }
  },
  included: [
    {
      type: 'StorageGroup',
      id: '145',
      attributes: {
        product_id: 37,
        name: 'big-query-mock-1',
        key_name: '8e0fd463f6fc76ed-big-query-mock-1'
      }
    }
  ]
};