import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationService } from '../services/integration.service';

@Pipe({
  name: 'canRequestHistory'
})
export class CanRequestHistoryPipe implements PipeTransform {
  constructor(
    private integrationService: IntegrationService
  ) { }

  transform(value: number): boolean {
    const integration = this.integrationService.findIntegrationFromId(value);
    if (integration.history) {
      return true;
    } else {
      return false
    }
  }

}
