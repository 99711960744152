import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import { ModalPipelineSaveComponent } from 'src/app/shared/components/modals/modal-pipeline-save/modal-pipeline-save.component';
import { PipelineSubscription } from 'src/app/shared/models/PipelineSubscription.model';
import { flashNotificationsFormValidationErrorWithDetails } from '../actions/flash-notifications.actions';
import { subscriptionAdd, subscriptionUpdate } from '../actions/subscriptions.actions';
import { wizardIntegrationInit, wizardIntegrationSave } from '../actions/wizard.actions';
import { AppState } from '../reducers';
import { PipelineService } from './../../services/pipeline.service';
import { modalNonUserClosable } from 'src/app/shared/constants/component-configs';
import { apiResponseCodesSubscription } from 'src/app/shared/constants/api-response-code';

@Injectable()
export class WizardEffects {
  bsModalRef: BsModalRef;

  savePipeline$ = createEffect(() => this.actions$.pipe(
      ofType(wizardIntegrationSave),
      tap(() => {

        this.store$.select('auth').pipe(take(1)).subscribe(async (authResponse) => {
          const accountId: number = authResponse.profile['https://openbridge.com/openbridge_defaults'].accountId;
          const userId: number = authResponse.profile['https://openbridge.com/openbridge_defaults'].userId;

          this.store$.select('wizard').pipe(take(1)).subscribe(
            async (response) => {

              try {

                this.openModalWithComponent();
                const pipelineSubscription: PipelineSubscription = await this.pipelineService.process(
                  accountId, userId, response);
                this.closeModalWithComponent();

                if (response.config.wizardMode === 'create') {
                  this.store$.dispatch(subscriptionAdd({
                    pipelineSubscription: pipelineSubscription
                  }));
                }
                else if (response.config.wizardMode === 'edit') {
                  this.store$.dispatch(subscriptionUpdate({
                    pipelineSubscription: pipelineSubscription
                  }));
                }

                const routerLink = response.routerLink + '/complete';

                // re-initialize the wizard state
                this.store$.dispatch(wizardIntegrationInit({
                  routerLink: null,
                  stage: null,
                  config: null
                }));

                // navigate to the thankyou page.
                this.router.navigateByUrl(response.routerLink + '/complete');
              }
              catch (error) {
                let parsedError = null;

                if (typeof error === 'object') {
                  if (error.error) {
                    parsedError = apiResponseCodesSubscription[error.error.errors[0].code];
                  } else {
                    parsedError = JSON.stringify(error);
                  }
                } else {
                  parsedError = error;
                }
                // tslint:disable-next-line: max-line-length
                this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({message: 'Unable to save pipeline', details: parsedError}));
                this.closeModalWithComponent();
              }
            }
          );
        });
      })
    ),
    { dispatch: false }
  );

  private openModalWithComponent(): void {
    this.bsModalRef = this.modalService.show(ModalPipelineSaveComponent, modalNonUserClosable);
  }

  private closeModalWithComponent(): void {
    this.bsModalRef.hide();
  }

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private pipelineService: PipelineService,
    private router: Router,
    private modalService: BsModalService
  ) {}
}