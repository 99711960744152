import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { flashNotificationsClear, flashNotificationsFormValidationError } from 'src/app/core/store/actions/flash-notifications.actions';
import { AppState } from 'src/app/core/store/reducers';
import { ApiStateService } from 'src/app/core/services/api-state.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-identity-oauth-create',
  templateUrl: './identity-oauth-create.component.html',
  styleUrls: ['./identity-oauth-create.component.scss']
})
export class IdentityOauthCreateComponent implements OnInit {
  @Input() integration: any;
  @Input() regions: { value: string, name: string }[];

  // Create default global identity regions
  identityRegions: { value: string, name: string }[] = [
    { value: 'global', name: 'global' }
  ];

  formGroup: FormGroup;
  accountId: number = null;
  userId: number = null;

  constructor(
    protected store$: Store<AppState>,
    private apiStateService: ApiStateService
  ) { }

  ngOnInit(): void {

    const defaultRegion = (this.regions !== undefined) ? '' : this.identityRegions[0].value;

    // Check if regions have been passed
    if (this.regions !== undefined) {
      this.identityRegions = this.regions;
    }

    // Create a new formGroup
    this.formGroup = new FormGroup({
      region: new FormControl(defaultRegion, [Validators.required]),
    });

    this.store$.select('auth').pipe(take(1)).subscribe(response => {
      if (response) {
        const openbridgeDefaults = response.profile['https://openbridge.com/openbridge_defaults'];
        this.accountId = openbridgeDefaults.accountId;
        this.userId = openbridgeDefaults.userId;
      }
    });
  }

  async submitForm(): Promise<void> {
    this.store$.dispatch(flashNotificationsClear());
    if (!this.formGroup.valid) {
      this.doProcessError('Region is a required field.');
    }
    else {

      const stateResponse = await this.apiStateService
        .createOauthState(
          this.accountId,
          this.userId,
          this.integration.remoteIdentityTypeId,
          window.location.origin + window.location.pathname,
          this.formGroup.value.region,
          null,
          true);

      window.location.href = environment.openbridgeApiUris.oauth +
        '/initialize?state=' + stateResponse['body']['data']['id'];

    }
  }

  doProcessError(error: string): void {
    this.store$.dispatch(flashNotificationsFormValidationError({ message: error }));
  }

}
