export const responseRedshiftSubscription = {
  data: {
    attributes: {
      data: 'this will be changed'
    },
    relationships: {
      data: 'this will be changed'
    }
  },
  included: [
    {
      data: 'this will be changed'
    }
  ]
};