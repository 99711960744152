import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { Router } from '@angular/router';
import { subscriptionsGet, subscriptionsLoad } from '../actions/subscriptions.actions';
import { PipelineService } from '../../services/pipeline.service';
import { CacheService } from '../../services/cache.service';

@Injectable()
export class SubscriptionsEffects {

  subscriptionsGet$ = createEffect(() => this.actions$
    .pipe(
      ofType(subscriptionsGet),
      tap(async action => {
        try {
          const effectSelf = this; // because, we need "this" somewhere else.
          let subscriptions = this.cacheService.getCache('local', 'list-subscriptions');

          if (subscriptions == null) {
            console.info('Requesting pipeline subscription data from openbridge API');
            const subscriptionList = [];
            const subscriptionsResponses = await this.pipelineService.getAll();

            for (const response of subscriptionsResponses) {

              const included = response['included'];
              const data = response['data'];

              for (const pipeline of data) {
                const formatted = effectSelf.pipelineService.transformSubscriptionResponse(pipeline, included);
                if (formatted) {
                  subscriptionList.push(formatted);
                }
              }
            }

            subscriptions = {subscriptions: subscriptionList };
          }

          this.store$.dispatch(subscriptionsLoad(subscriptions));
        }
        catch (error) {
          throw error;
        }
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private pipelineService: PipelineService,
    private store$: Store<AppState>,
    private cacheService: CacheService
  ) { }

}