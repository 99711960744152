<div class="row">
  <div class="col-12">
    <!-- Card -->
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <!-- Heading -->
            <span class="h3 mb-0"> Activate New Google Service Account </span>
            <!-- Text -->
            <p class="card-text small text-muted mb-1">In Google Cloud console, go to <code>IAM & admin > Service
              accounts</code>. Next, <code>Create service account</code> and assign permissions. Lastly, create and export your JSON key for upload here. See the docs for <a href="https://docs.openbridge.com/en/articles/1856793-how-to-set-up-google-bigquery-creating-and-configuring-service-accounts-in-google-cloud-console" rel="noopener" target="_blank">step-by-step guide</a>.</p>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <div class="card">
                <div class="custom-dropzone" ngx-dropzone [multiple]="false" (change)="onSelect($event)">
                  <ngx-dropzone-label>
                    <div>
                      Drop JSON file here
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </div>

              </div>
            </div>
            <!-- Icon
                        <a class="btn btn-sm btn-primary" href="pricing.html">Upgrade</a>-->
          </div>
        </div> <!-- / .row -->
      </div>
    </div>
  </div>
</div>