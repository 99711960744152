import { Md5 } from 'ts-md5/dist/md5';

export const setCache = (type: string, key: string, value: any) => {
  const stringifiedValye = btoa(JSON.stringify(value));
  if (type === 'local') {
    localStorage.setItem(key, stringifiedValye);
  }
  else {
    sessionStorage.setItem(key, stringifiedValye);
  }
};

export const getCache = (type: string, key: string) => {
  let responseValue: any;

  if (type === 'local') {
    responseValue = localStorage.getItem(key);
  }
  else {
    responseValue = sessionStorage.getItem(key);
  }

  if (responseValue == null) {
    return null;
  }

  return JSON.parse(atob(responseValue));
};

export const removeCache = (type: string, key: string) => {
  if (type === 'local') {
    localStorage.removeItem(key);
  }
  else {
    sessionStorage.removeItem(key);
  }
};

export const generateKeyFromObject = (params: object) => {
  const paramString = JSON.stringify(params);
  return Md5.hashStr(paramString);
};