import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-azure-data-lake-details',
  templateUrl: './help-azure-data-lake-details.component.html'
})
export class HelpAzureDataLakeDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
