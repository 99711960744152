<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/amazon.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated Amazon Advertising data pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for automated Amazon Advertising data pipelines </h4>
<ol>
  <li class="text-muted mb-2"> If you do not have an advertising account, Amazon makes it easy to get started. Amazon
    offers <a href="https://advertising.amazon.com/register?" rel="noopener" target="_blank"> a quick and simple online
      registration</a> for new customers </li>
  <li class="text-muted mb-2"> If you already have an Amazon Advertising account, make sure you have the correct
    permissions to grant access to your profile to collect performance data. </li>
  <li class="text-muted mb-2">See the Amazon Advertising <a href="https://docs.openbridge.com/en/?q=amazon+advertising"
      rel="noopener" target="_blank">help docs</a> for guides, tips, and troubleshooting</li>
</ol>