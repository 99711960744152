import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
 productIdFacebookMarketing, productIdFacebookPageInsights, productIdGoogleAds, productIdInstagramInsights, productIdInstagramStories
} from '../product-ids';

export const googleProducts: Integration[] = [
  {
    productId: productIdGoogleAds,
    enabled: true,
    comingSoon: false,
    isConfigurable: true,
    brand: 'Google',
    name: 'Ads',
    type: 'source',
    sortOrder: 600,
    tagLine: 'Obtain Ads performance data for campaigns, keywords, creative and geography',
    routerLink: '/wizards/google-ads',
    logoPath: vendors.googleAds,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 8,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/google-ads',
      uri: 'https://developers.google.com/adwords/api/docs/guides/reporting'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'customer',
          'destination',
          'integrationName',
          'save'
        ],
        managed: [
          'checklist',
          'identity',
          'customer',
          'managed-customer',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: 3,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Google',
    name: 'Analytics 360',
    type: 'source',
    sortOrder: 700,
    tagLine: 'Enterprise, unsampled event level data for custom reporting, insights and attribution',
    routerLink: '/wizards/google-analytics-360',
    logoPath: vendors.googleAnalytics360,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/analytics',
      uri: 'https://support.google.com/analytics/answer/3437434?hl=en'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: 4,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Google',
    name: 'Campaign Manager',
    type: 'source',
    sortOrder: 800,
    tagLine: 'Reporting insights, benchmarks, and media performance across digital campaigns',
    routerLink: '/wizards/google-campaign-manager',
    logoPath: vendors.googleCampaignManager,
    frequency: 'Daily',
    standardIntegration: false,
    premiumIntegration: true,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/campaignmanager',
      uri: 'https://developers.google.com/doubleclick-advertisers'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: 7,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Google',
    name: 'Search Ads 360',
    type: 'source',
    sortOrder: 900,
    tagLine: 'Customer journey search data for campaigns, insights and attribution',
    routerLink: '/wizards/google-search-ads-360',
    logoPath: vendors.googleSearch360,
    frequency: 'Daily',
    standardIntegration: false,
    premiumIntegration: true,
    remoteIdentityTypeId: 1,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'google.com/search-ads',
      uri: 'https://developers.google.com/search-ads'
    },
    history: false,
    manageMenu: {
    }
  },
];

