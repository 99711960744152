import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-general',
  templateUrl: './help-general.component.html',
})
export class HelpGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
