import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authAccountToggle, authUserLogin, authUserLogout } from '../actions/auth.actions';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { AccountService } from 'src/app/core/services/account.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { stripeCustomerSet } from '../actions/stripe.actions';
import { Router } from '@angular/router';
import { subscriptionsGet } from '../actions/subscriptions.actions';
import { identitiesGet } from '../actions/identities.actions';
import { changedStateEpochUpdate, dailyPipelinesGet } from '../actions/account.actions';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$
    .pipe(
      ofType(authUserLogin),
      tap(async action => {

        const openbridgeDefaults = await action.profile['https://openbridge.com/openbridge_defaults'];

        const  currentAccount = {
          accountId: openbridgeDefaults.accountId,
          stripeCustomerId: action.profile['https://openbridge.com/stripe_customer_id'],
          userId: openbridgeDefaults.userId
        };

        this.store$.dispatch(authAccountToggle({ currentAccount: currentAccount }));

      })
    ),
    { dispatch: false }
  );

  logout$ = createEffect(() => this.actions$
    .pipe(
      ofType(authUserLogout),
      tap(async action => {
        this.router.navigateByUrl('/');
      })
    ),
    { dispatch: false }
  );


  accountToggle$ = createEffect(() => this.actions$
    .pipe(
      ofType(authAccountToggle),
      tap(async action => {
        const currentAccount = action.currentAccount;

        const stripeCustomer = await this.stripeService.retrieveStripeCustomer(currentAccount.stripeCustomerId);
        this.store$.dispatch(stripeCustomerSet({ stripeCustomer: stripeCustomer['data'] }));

        let cachedEpoch = this.cacheService.getCache('local', 'cachedDataModifiedEpoch') || null;

        // If epoch is null, generate it and ask for it again from local storage.
        if (cachedEpoch == null) {
          this.store$.dispatch(changedStateEpochUpdate());
          cachedEpoch = this.cacheService.getCache('local', 'cachedDataModifiedEpoch');
        }

        // See fif there are deltas from the API
        const hasDeltas = await this.accountService.hasDeltas(currentAccount.accountId, cachedEpoch);

        // If there are deltas then w
        if (hasDeltas) {
          console.info('Deltas exist, deleting cached data, if it exists.');
          this.cacheService.removeCache('local', 'list-subscriptions');
        }

        // Temporary removal of identity cache.
        this.cacheService.removeCache('local', 'list-identities');

        this.store$.dispatch(subscriptionsGet());
        this.store$.dispatch(identitiesGet());
        this.store$.dispatch(dailyPipelinesGet());
      })
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private stripeService: StripeService,
    private store$: Store<AppState>,
    private cacheService: CacheService,
    private accountService: AccountService
  ) { }

}

export interface CurrentAccount {
  accountId: number | null;
  stripeCustomerId: string;
  userId: number | null;
}