<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-address-card"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Profile </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Managing and updating your account profile </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> Understanding your account profile</h4>
<ol>
  <li class="text-muted mb-2">
    Your profile reflects the primary, admin level user account. The account profile email is the primary email used for notices, alerts, and announcements. 
  
  </li>
  <li class="text-muted mb-2">
  If you need to establish a separate billing contact, you can go to the  <a routerLink="/account/billing" class="light-link">customer billing portal</a>, select <strong>"Manage"</strong>. You will directed to the portal where you can manage additional billing contact information for payments, invoices, and receipts.
</li>

  <li class="text-muted mb-2">
    If you have verified your account email, you will see a <span class="badge bg-success-soft">Verified</span> status.
  </li>

  <li class="text-muted mb-2">
    If your account is <span class="badge bg-warning-soft">Unverified</span>, it means your did not confirm our email we sent to verify the address is valid. While your account will continue to work, an unverified email address may cause you to miss important notifications and updates. Please check your spam or junk mail folders for the verification email.
  </li>

 

  <li class="text-muted mb-2">
    If you need multiple primary account holders attached to your Openbridge account, we suggest using a company group email address. For example, <code>data-team@mycompany.com</code> which includes <code>sue@mycompany.com</code> and <code>joe@mycompany.com</code>. This ensures both Sue and Joe have access important account information, including billing, notifications, and alerts.
  </li>
</ol>