import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-google-ads-identity',
  templateUrl: './help-google-ads-identity.component.html'
})
export class HelpGoogleAdsIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
