import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-card-trial-plan',
  templateUrl: './card-trial-plan.component.html',
  styleUrls: ['./card-trial-plan.component.scss']
})
export class CardTrialPlanComponent implements OnInit {
  stripeReducer$: any;
  stripeCustomerData: any;
  subscriptionData: any[];
  daysDifferenceForTrial: number;
  totalDaysDifferenceForTrial: number;
  daysDifferenceForSubscription: number;
  totalDaysDifferenceForSubscription: number;

  isTrialCustomer = false;
  hasTrialSubscription = false;
  hasPaidSubscription = false;
  hasSubscription = false;
  stripeDetails: any = null;

  constructor(
    private store$: Store<AppState>,
  ) { }

  ngOnInit(): void {

    // Get the current time as a base epoch time without milliseconds
    const nowTime = Math.floor(new Date().getTime() / 1000);

    this.stripeReducer$ = this.store$.select('stripe').subscribe(response => {
      if (response.stripeCustomer !== null) {
        this.stripeCustomerData = { ...response.stripeCustomer };
        if (this.stripeCustomerData) {
          this.subscriptionData = this.stripeCustomerData.subscriptions.data;
          this.hasSubscription = (this.subscriptionData.length > 0) ? true : false;
          if (this.hasSubscription) {
            this.subscriptionData.forEach(val => {
              if (val.plan.id === environment.stripe.prices.trialPlanId) {
                this.hasTrialSubscription = true;
                this.daysDifferenceForTrial = this.calculateDifferenceInDays(val.current_period_end, nowTime);
                this.totalDaysDifferenceForTrial = this.calculateDifferenceInDays(val.current_period_end, val.current_period_start);
              }
              else {
                this.hasPaidSubscription = true;
                this.stripeDetails = this.processStripeData(this.stripeCustomerData);
                this.daysDifferenceForSubscription = this.calculateDifferenceInDays(this.stripeDetails.planCurrentPeriodEnd, nowTime);
                this.totalDaysDifferenceForSubscription = this.calculateDifferenceInDays(
                  this.stripeDetails.planCurrentPeriodEnd, this.stripeDetails.planCurrentPeriodStart
                );
              }
            });
            if (this.hasTrialSubscription && !this.hasPaidSubscription) {
              this.isTrialCustomer = true;
            }
          }
        }
      }
    });
  }

  processStripeData(stripeData): any {
    const subscriptions = stripeData.subscriptions;
    return {
      id: subscriptions.data[0].id,
      planId: subscriptions.data[0].plan.id,
      planName: subscriptions.data[0].plan.name,
      planCurrentPeriodStart: subscriptions.data[0].current_period_start,
      planCurrentPeriodEnd: subscriptions.data[0].current_period_end
    };
  }

  calculateDaysPassed(subscriptionDayLength: number, day: number): number {
    return subscriptionDayLength - day;
  }

  convertTimeStampToDate(timeStampDate): string {
    return moment(timeStampDate * 1000).format('MMM DD, YYYY');
  }

  calculateDifferenceInDays(endTime: number, nowTime: number): number {
    const difference = endTime - nowTime;
    const daysDifference = Math.ceil(difference / 86400);
    return daysDifference;
  }

  getInnerText(day): string {
    if (day > 9 && day <= 14) {
      return 'Welcome to your trial';
    }
    else if (day > 5 && day <= 9) {
      return 'Your trial has ' + day + ' days left.';
    }
    else if (day > 2 && day <= 5) {
      return 'Only ' + day + ' days left. Upgrade soon!';
    }
    else if (day > 0 && day <= 2) {
      return 'You have ' + day + ' days left. Upgrade now!';
    } else {
      return 'Trial plan is expired. Upgrade to continue service';
    }
  }

  getProgressBarWidthClassForTrial(day): string {
    switch (this.calculateDaysPassed(this.totalDaysDifferenceForTrial, day)) {
      case 0: {
        return 'progress-bar-width-day-0';
      }
      case 1: {
        return 'progress-bar-width-day-1';
      }
      case 2: {
        return 'progress-bar-width-day-2';
      }
      case 3: {
        return 'progress-bar-width-day-3';
      }
      case 4: {
        return 'progress-bar-width-day-4';
      }
      case 5: {
        return 'progress-bar-width-day-5';
      }
      case 6: {
        return 'progress-bar-width-day-6';
      }
      case 7: {
        return 'progress-bar-width-day-7';
      }
      case 8: {
        return 'progress-bar-width-day-8';
      }
      case 9: {
        return 'progress-bar-width-day-9';
      }
      case 10: {
        return 'progress-bar-width-day-10';
      }
      case 11: {
        return 'progress-bar-width-day-11';
      }
      case 12: {
        return 'progress-bar-width-day-12';
      }
      case 13: {
        return 'progress-bar-width-day-13';
      }
      case 14: {
        return 'progress-bar-width-day-14';
      }
      default:
        return 'progress-bar-width-day-0';
    }
  }

  getProgressBarWidthClassForSubscription(day): string {
    switch (this.calculateDaysPassed(this.totalDaysDifferenceForSubscription, day)) {
      case 0: {
        return 'progress-bar-width-For-Subscription-0';
      }
      case 1: {
        return 'progress-bar-width-For-Subscription-1';
      }
      case 2: {
        return 'progress-bar-width-For-Subscription-2';
      }
      case 3: {
        return 'progress-bar-width-For-Subscription-3';
      }
      case 4: {
        return 'progress-bar-width-For-Subscription-4';
      }
      case 5: {
        return 'progress-bar-width-For-Subscription-5';
      }
      case 6: {
        return 'progress-bar-width-For-Subscription-6';
      }
      case 7: {
        return 'progress-bar-width-For-Subscription-7';
      }
      case 8: {
        return 'progress-bar-width-For-Subscription-8';
      }
      case 9: {
        return 'progress-bar-width-For-Subscription-9';
      }
      case 10: {
        return 'progress-bar-width-For-Subscription-10';
      }
      case 11: {
        return 'progress-bar-width-For-Subscription-11';
      }
      case 12: {
        return 'progress-bar-width-For-Subscription-12';
      }
      case 13: {
        return 'progress-bar-width-For-Subscription-13';
      }
      case 14: {
        return 'progress-bar-width-For-Subscription-14';
      }
      case 15: {
        return 'progress-bar-width-For-Subscription-15';
      }
      case 16: {
        return 'progress-bar-width-For-Subscription-16';
      }
      case 17: {
        return 'progress-bar-width-For-Subscription-17';
      }
      case 18: {
        return 'progress-bar-width-For-Subscription-18';
      }
      case 19: {
        return 'progress-bar-width-For-Subscription-19';
      }
      case 20: {
        return 'progress-bar-width-For-Subscription-20';
      }
      case 21: {
        return 'progress-bar-width-For-Subscription-21';
      }
      case 22: {
        return 'progress-bar-width-For-Subscription-22';
      }
      case 23: {
        return 'progress-bar-width-For-Subscription-23';
      }
      case 24: {
        return 'progress-bar-width-For-Subscription-24';
      }
      case 25: {
        return 'progress-bar-width-For-Subscription-25';
      }
      case 26: {
        return 'progress-bar-width-For-Subscription-26';
      }
      case 27: {
        return 'progress-bar-width-For-Subscription-27';
      }
      case 28: {
        return 'progress-bar-width-For-Subscription-28';
      }
      case 29: {
        return 'progress-bar-width-For-Subscription-29';
      }
      case 30: {
        return 'progress-bar-width-For-Subscription-30';
      }
      default:
        return 'progress-bar-width-For-Subscription-0';
    }
  }

}
