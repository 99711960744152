import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-filter-identities',
  templateUrl: './filter-identities.component.html'
})
export class FilterIdentitiesComponent implements OnInit {

  @Input() filterableObjects: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();

  pageSize = 10;

  constructor() { }

  ngOnInit(): void {
    this.filteredObjects.emit([...this.filterableObjects]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredObjects.emit([...this.filterableObjects]);
  }

  processFilteredText(filterText: string): void {

    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        if (val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.firstName) {
          if (val.firstName.toLowerCase().includes(filterText.toLowerCase())) {
            filteredObjects.push(val);
          }
        }
        else if (val.lastName) {
          if (val.lastName.toLowerCase().includes(filterText.toLowerCase())) {
            filteredObjects.push(val);
          }
        }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }
}
