import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { subscriptionUpdate } from '../store/actions/subscriptions.actions';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { PipelineSubscription } from 'src/app/shared/models/PipelineSubscription.model';

import { IdentityService } from './identity.service';
import { DateService } from 'src/app/shared/services/date.service';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { ApiStoragesService } from './api-storages.service';

import { productIdAmazonAdvertising, productIdAmazonAttribution, productIdAwsAthena,
         productIdAwsRedshift, productIdAmazonDsp, productIdGoogleAds,
         productIdAwsRedshiftSpectrum, productIdAzureBlobStorage, productIdAzureDataLake,
         productIdGoogleBigquery, productIdSnowflakeWarehouse,
         productIdAmzSellingPartnersOrders, productIdAmzSellingPartnersFinanceRt,
         productIdAmzSellingPartnersInbound, productIdAmzSellingPartnersSettlement,
         productIdAmzSellingBusRepFullfillmentRt, productIdAmzSellingBusRepInventoryRt,
         productIdAmzSellingBusRepInventory, productIdAmzSellingBusRepSales,
         productIdAmzSellingBusRepFees
       } from 'src/app/shared/constants/product-ids';


import { EncryptionService } from './encryption.service';
import { changedStateEpochUpdate } from '../store/actions/account.actions';
// import { getResponse } from 'msw/lib/types/utils/getResponse';
// import { subscriptions } from 'src/app/shared/mock/subscriptions';


@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  pipelineSaveState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private identityService: IdentityService,
    protected apiStorages: ApiStoragesService,
    private dateService: DateService,
    private intetegrationService: IntegrationService,
    private encryptionService: EncryptionService
  ) { }


  async process(accountId: number, userId: number, configState: any): Promise<any> {
    let httpResponse = null;

    try {

      const delay = ms => new Promise(res => setTimeout(res, ms));

      this.pipelineSaveState$.next({ state: 'pipeline-process-initialize', payload: null });

      await delay(2500);

      const payload: PipelineCreation = await this.generatePipelineConfig(accountId, userId, configState);

      this.pipelineSaveState$.next({ state: 'pipeline-process-saving', payload: null });

      await delay(2500);

      const subscriptionPostUri = environment.openbridgeApiUris.subscription + '/sub';

      if (configState.config.wizardMode === 'edit') {
        httpResponse = await this.httpClient.patch(subscriptionPostUri + '/' + payload.data.id, payload,
          { observe: 'response' }).toPromise();
      }
      else if (configState.config.wizardMode === 'create') {
        httpResponse = await this.httpClient.post(subscriptionPostUri, payload,
          { observe: 'response' }).toPromise();
      }
      else {
        // tslint:disable-next-line: no-string-throw
        throw 'Unknown wizard mode ' + configState.config.wizardMode;
      }

      this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });

      if (httpResponse.body.hasOwnProperty('status') && httpResponse.body['status'] === 'error') {
        this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });
        throw httpResponse.body;
      }
      else {
        const transformedResponse = this.transformSubscriptionResponse(httpResponse.body['data'], httpResponse.body['included']);
        return transformedResponse;
      }

    } catch (error) {
      this.pipelineSaveState$.next({ state: 'pipeline-process-complete', payload: null });
      throw error;
    }
  }

  async patchPipeline(pipelineId: number, payload: any): Promise<any> {
    const response = await this.httpClient.patch(environment.openbridgeApiUris.subscription + '/sub/' + pipelineId, payload).toPromise();
    const transformedData = this.transformSubscriptionResponse(response['data'], response['included']);
    this.store$.dispatch(subscriptionUpdate({ pipelineSubscription: transformedData }));
    return response;
  }

  async pausePipelineSubscription(pipelineId: number): Promise<any> {
    try {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.apiStorages.storageTestState$.next({ state: 'pipeline-pause-initialize', payload: null });

      const payload = {
        data: {
          type: 'Subscription',
          id: pipelineId,
          attributes: {
            status: 'cancelled'
          }
        }
      };

      this.patchPipeline(pipelineId, payload);

      this.apiStorages.storageTestState$.next({ state: 'pipeline-pause-complete', payload: null });
      this.store$.dispatch(changedStateEpochUpdate());

    } catch (error) {
      this.apiStorages.storageTestState$.next({ state: 'pipeline-pause-complete', payload: null });
      throw error;
    }
  }

  async unpausePipelineSubscription(pipelineId: number): Promise<any> {
    try {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-initialize', payload: null });

      const payload = {
        data: {
          type: 'Subscription',
          id: pipelineId,
          attributes: {
            status: 'active'
          }
        }
      };

      const response = this.patchPipeline(pipelineId, payload);

      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-complete', payload: null });
      this.store$.dispatch(changedStateEpochUpdate());

    } catch (error) {
      this.apiStorages.storageTestState$.next({ state: 'pipeline-unpause-complete', payload: null });
      throw error;
    }
  }

  async deletePipelineSubscription(pipelineId: number): Promise<any> {
    try {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-initialize', payload: null });

      const payload = {
        data: {
          type: 'Subscription',
          id: pipelineId,
          attributes: {
            status: 'invalid'
          }
        }
      };

      const response = this.patchPipeline(pipelineId, payload);

      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-complete', payload: null });
      this.store$.dispatch(changedStateEpochUpdate());

    } catch (error) {
      this.apiStorages.storageTestState$.next({ state: 'pipeline-delete-complete', payload: null });
      throw error;
    }
  }

  async getAll(): Promise<any> {

    try {
      const pageSize = 100;
      const subscriptionsResponses = [];

      const firstPage = await this.requestSubscriptionPage(1, pageSize);
      const pages = firstPage['body'].meta.pagination.pages;
      subscriptionsResponses.push(firstPage.body);

      for (let index = 2; index < pages; index++) {
        const requestedPage = await this.requestSubscriptionPage(index, pageSize);
        subscriptionsResponses.push(requestedPage.body);
      }

      return subscriptionsResponses;
    }
    catch (error) {
      throw error;
    }
  }

  async requestSubscriptionPage(page: number, pageSize: number): Promise<any> {
    const baseUri = environment.openbridgeApiUris.subscription + '/sub';
    const subscriptionGetUri = baseUri + '?page_size=' + pageSize + '&page=' + page;
    return await this.httpClient.get(subscriptionGetUri, { observe: 'response' }).toPromise();
  }

  appendArrayToArray(firstArray, secondArray): any[] {
    return [
      ...firstArray,
      secondArray
    ];
  }


  transformSubscriptionResponse(data: any, included?: any[]): any {

    // Create a base pipeline subscription.
    const pipelineSubscription: PipelineSubscription = {
      id: +data.id,
      name: data.attributes.name,
      canonicalName: data.attributes.canonical_name,
      accountId: +data.attributes.account_id,
      productId: data.attributes.product_id,
      status: data.attributes.status,
      storageGroupId: data.attributes.storage_group_id,
      historyRequested: data.attributes.history_requested,
      createdAt: data.attributes.created_at,
      modifiedAt: data.attributes.modified_at,
      isStorage: false, // handled below
      premiumProduct: false, // data.attributes.product.is_premium,
      productName: null, // handled below
      remoteIdentityId: null, // handled below
      remoteIdentityName: '', // handled below
      storageGroupKeyName: null, // handled below
      storageGroupName: null, // handled below
      storageGroupProductId: null, // null as defaulthandled below
    };

    // Get the integration from the product ID.
    const integration = this.intetegrationService.findIntegrationFromId(pipelineSubscription.productId);

    // if Integration not found for given productId then not return those product
    if (integration) {

      // Set integration related values
      pipelineSubscription.productName = integration.name;
      pipelineSubscription.isStorage = (integration.type === 'destination') ? true : false;
      pipelineSubscription.premiumProduct = integration.premiumIntegration;

      const storageGroup = this.findIncluded(included, 'StorageGroup', data.attributes.storage_group_id);

      if (storageGroup) {
        pipelineSubscription.storageGroupName = storageGroup.attributes.name;
        pipelineSubscription.storageGroupKeyName = storageGroup.attributes.key_name;
        pipelineSubscription.storageGroupProductId = storageGroup.attributes.product_id;
      }

      if (data.attributes.remote_identity_id !== null) {
        const pipelineIdentity = this.identityService.getIdentityById(data.attributes.remote_identity_id);
        if (pipelineIdentity) {
          pipelineSubscription.remoteIdentityId = pipelineIdentity.id;
          pipelineSubscription.remoteIdentityName = pipelineIdentity.name;
        }
      }
      return pipelineSubscription;
    }

  }

  findStorageGroupSubscriptionByStorageGroupId(storageGroupId: number): any {

    let subscription: any = null;

    this.store$.select('subscriptions').pipe(take(1)).subscribe(response => {
      response.subscriptions.forEach(value => {
        if (value.isStorage && value.storageGroupId === storageGroupId) {
          subscription = value;
        }
      });
    });

    if (subscription) {
      return subscription;
    }

    return null;
  }

  async generatePipelineConfig(accountId: number, userId: number, wizardState: any): Promise<PipelineCreation> {

    let pipelineMeta: any[] = [];

    switch (wizardState.productId) {
      /**
       *  Place conditions for destinations alphebetally under here
       */
      case productIdAwsAthena:
        pipelineMeta = await this.generateAwsAthenaPipelineMeta(wizardState.config);
        break;
      case productIdAwsRedshift:
        pipelineMeta = await this.generateAwsRedshiftPipelineMeta(wizardState.config);
        break;
      case productIdAwsRedshiftSpectrum:
        pipelineMeta = await this.generateAwsSpectrumPipelineMeta(wizardState.config);
        break;
      case productIdAzureBlobStorage:
        pipelineMeta = await this.generateAzureBlobStoragePipelineMeta(wizardState.config);
        break;
      case productIdAzureDataLake:
        pipelineMeta = await this.generateAzureDataLakePipelineMeta(wizardState.config);
        break;
      case productIdGoogleBigquery:
        pipelineMeta = await this.generateGoogleBigQueryPipelineMeta(wizardState.config);
        break;
      case productIdSnowflakeWarehouse:
        pipelineMeta = await this.generateSnowflakePipelineMeta(wizardState.config);
        break;

      /**
       *  Place conditions for sources alphebetally under here
       */
      case productIdAmazonAdvertising:
        pipelineMeta = await this.generateAmazonAdvertisingPipelineMeta(wizardState.config);
        break;
      case productIdAmazonAttribution:
        pipelineMeta = await this.generateAmazonAttributionPipelineMeta(wizardState.config);
        break;
      case productIdAmazonDsp:
        pipelineMeta = await this.generateAmazonDspPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingPartnersOrders:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingPartnersFinanceRt:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingPartnersInbound:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingPartnersSettlement:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingBusRepFullfillmentRt:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingBusRepInventoryRt:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingBusRepInventory:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingBusRepSales:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdAmzSellingBusRepFees:
        pipelineMeta = await this.generateSpapiGeneralPipelineMeta(wizardState.config);
        break;

      case productIdGoogleAds:
        pipelineMeta = await this.generateGoogleAdsPipelineMeta(wizardState.config);
        break;

      default:
        // tslint:disable-next-line: no-string-throw
        throw 'Failed to build metadata for product';
    }

    if (pipelineMeta.length === 0) {
      // tslint:disable-next-line: no-string-throw
      throw 'Pipeline config contains no meta and is invalid';
    }

    const storageGroupId = (wizardState.config.destination) ? +wizardState.config.destination : null;
    const remoteIdentityId = (wizardState.config.remoteIdentityId) ? wizardState.config.remoteIdentityId : null;
    const pipeline = this.generateBasicPipeline(wizardState.config.integrationName, pipelineMeta,
      accountId, userId, wizardState.productId, storageGroupId, remoteIdentityId, wizardState.config.subscriptionId);

    return pipeline;
  }

  // tslint:disable-next-line: max-line-length
  private async generateBasicPipeline(pipelineName: string, pipelineMeta: any[], accountId: number, userId: number, productId: number, storageGroupId?: number, remoteIdentityId?: number, subscriptionId?: number): Promise<PipelineCreation> {

    const dateStart = this.dateService.getFormattedDate();

    const pipeline: PipelineCreation = {
      data: {
        type: 'Subscription',
        attributes: {
          account: accountId,
          user: userId,
          product: productId,
          name: pipelineName,
          status: 'active',
          subscription_product_meta_attributes: pipelineMeta,
          quantity: 1,
          price: 0.00,
          auto_renew: 1,
          date_start: dateStart,
          date_end: dateStart, // '0000-00-00 00:00:00',
          invalid_subscription: 0,
          rabbit_payload_successful: 0,
          stripe_subscription_id: ''
        }
      }
    };

    if (subscriptionId) {
      pipeline.data.id = subscriptionId;
    }

    if (storageGroupId != null) {
      pipeline.data.attributes.storage_group = storageGroupId;
    }

    if (remoteIdentityId != null) {
      pipeline.data.attributes.remote_identity = remoteIdentityId;
    }
    return pipeline;
  }

  /**
   *  Place functions for destinations alphebetally under here
   */
  private async generateAwsAthenaPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['awsRegion', 's3Bucket', 'awsDatabaseName', 'awsAccessKey', 'awsSecretKey'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      awsSecretKey: configState.awsSecretKey
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'awsRegion') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaRegion',
          data_value: configState.awsRegion,
          product: productIdAwsAthena
        };
      }
      else if (value === 's3Bucket') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaBucket',
          data_value: configState.s3Bucket,
          product: productIdAwsAthena
        };
      }
      else if (value === 'awsDatabaseName') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaDatabase',
          data_value: configState.awsDatabaseName,
          product: productIdAwsAthena
        };
      }
      else if (value === 'awsAccessKey') {
        metaRow = {
          data_id: 0,
          data_key:
            'athenaAccessKeyId',
          data_value: configState.awsAccessKey,
          product: productIdAwsAthena
        };
      }
      else if (value === 'awsSecretKey') {
        metaRow = {
          data_id: 0,
          data_key: 'athenaSecretAccessKey',
          data_value: encryptedResponse.data.attributes.awsSecretKey,
          product: productIdAwsAthena
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAwsRedshiftPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['dbname', 'host', 'password', 'port', 'username'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'host') {
        metaRow = {
          data_id: 0,
          data_key: 'hostname',
          data_value: configState.host,
          product: productIdAwsRedshift
        };
      }
      else if (value === 'port') {
        metaRow = {
          data_id: 0,
          data_key: 'port',
          data_value: configState.port,
          product: productIdAwsRedshift
        };
      }
      else if (value === 'dbname') {
        metaRow = {
          data_id: 0,
          data_key: 'databaseName',
          data_value: configState.dbname,
          product: productIdAwsRedshift
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'username',
          data_value: configState.username,
          product: productIdAwsRedshift
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'password',
          data_value: encryptedResponse.data.attributes.password,
          product: productIdAwsRedshift
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateAwsSpectrumPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = [
      'dbname', 'host', 'password', 'port', 'username',
      'awsRegion', 'awsBucket', 'spectrumDb', 'spectrumSchema', 'awsIamRoleArn',
      'awsAccessKey', 'awsSecretKey'
    ];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password,
      awsSecretKey: configState.awsSecretKey
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'host') {
        metaRow = {
          data_id: 0,
          data_key: 'hostname',
          data_value: configState.host,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'port') {
        metaRow = {
          data_id: 0,
          data_key: 'port',
          data_value: configState.port,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'dbname') {
        metaRow = {
          data_id: 0,
          data_key: 'databaseName',
          data_value: configState.dbname,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'username',
          data_value: configState.username,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'password',
          data_value: encryptedResponse.data.attributes.password,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'awsRegion') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumRegion',
          data_value: configState.awsRegion,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'awsBucket') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumBucket',
          data_value: configState.awsBucket,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'spectrumDb') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumDatabase',
          data_value: configState.spectrumDb,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'awsIamRoleArn') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumIamRole',
          data_value: configState.awsIamRoleArn,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'awsAccessKey') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumAccessKeyId',
          data_value: configState.awsAccessKey,
          product: productIdAwsRedshiftSpectrum
        };
      }
      else if (value === 'awsSecretKey') {
        metaRow = {
          data_id: 0,
          data_key: 'spectrumSecretAccessKey',
          data_value: encryptedResponse.data.attributes.awsSecretKey,
          product: productIdAwsRedshiftSpectrum
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAzureBlobStoragePipelineMeta(configState: any): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['storageContainer', 'connectionString'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      connectionString: configState.connectionString
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'storageContainer') {
        metaRow = {
          data_id: 0,
          data_key: 'azureContainer',
          data_value: configState.storageContainer,
          product: productIdAzureBlobStorage
        };
      }
      else if (value === 'connectionString') {
        metaRow = {
          data_id: 0,
          data_key: 'azureConnectionString',
          data_value: encryptedResponse.data.attributes.connectionString,
          product: productIdAzureBlobStorage
        };
      }
      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAzureDataLakePipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['storageContainer', 'connectionString'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      connectionString: configState.connectionString
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'storageContainer') {
        metaRow = {
          data_id: 0,
          data_key: 'azureContainer',
          data_value: configState.storageContainer,
          product: productIdAzureDataLake
        };
      }
      else if (value === 'connectionString') {
        metaRow = {
          data_id: 0,
          data_key: 'azureConnectionString',
          data_value: encryptedResponse.data.attributes.connectionString,
          product: productIdAzureDataLake
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateGoogleBigQueryPipelineMeta(configState: any): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'project_id', 'dataset_id'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value) => {

      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdGoogleBigquery
        };
      }
      else if (value === 'project_id') {
        metaRow = {
          data_id: 0,
          data_key: 'project_id',
          data_value: configState.projectId,
          product: productIdGoogleBigquery
        };
      }
      else if (value === 'dataset_id') {
        metaRow = {
          data_id: 0,
          data_key: 'dataset_id',
          data_value: configState.datasetId,
          product: productIdGoogleBigquery
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;
  }

  private async generateSnowflakePipelineMeta(configState: any): Promise<MetaRow[]> {
    const metaArray: any[] = [];
    const metaKeys: string[] = ['account', 'databaseName', 'warehouse', 'schema', 'username', 'password'];
    let metaRow: MetaRow = null;

    const encryptedResponse = await this.encryptionService.encryptStringList({
      password: configState.password
    });

    metaKeys.forEach((value, idx) => {

      if (value === 'account') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeAccount',
          data_value: configState.account,
          product: productIdSnowflakeWarehouse
        };
      }
      else if (value === 'databaseName') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeDatabase',
          data_value: configState.databaseName,
          product: productIdSnowflakeWarehouse
        };
      }
      else if (value === 'warehouse') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeWarehouse',
          data_value: configState.warehouse,
          product: productIdSnowflakeWarehouse
        };
      }
      else if (value === 'schema') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeSchema',
          data_value: configState.schema,
          product: productIdSnowflakeWarehouse
        };
      }
      else if (value === 'username') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakeUser',
          data_value: configState.username,
          product: productIdSnowflakeWarehouse
        };
      }
      else if (value === 'password') {
        metaRow = {
          data_id: 0,
          data_key: 'snowflakePassword',
          data_value: encryptedResponse.data.attributes.password,
          product: productIdSnowflakeWarehouse
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  /**
   *  Place functions for destinations alphebetally under here
   */
  private async generateAmazonAdvertisingPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile', 'marketplace', 'type', 'kdpState'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'marketplace') {
        metaRow = {
          data_id: 0,
          data_key: 'marketplace',
          data_value: configState.marketplace,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'type') {
        metaRow = {
          data_id: 0,
          data_key: 'type',
          data_value: configState.type,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'kdpState') {
        metaRow = {
          data_id: 0,
          data_key: 'kdp_state',
          data_value: configState.kdpState,
          product: productIdAmazonAdvertising
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonAttributionPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          product: productIdAmazonAdvertising
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateAmazonDspPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'profile'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'profile') {
        metaRow = {
          data_id: 0,
          data_key: 'profile_id',
          data_value: configState.profile,
          product: productIdAmazonAdvertising
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateSpapiGeneralPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdAmazonAdvertising
        };
      }
      metaArray.push(metaRow);
    });

    return metaArray;

  }

  private async generateGoogleAdsPipelineMeta(configState: any): Promise<MetaRow[]> {

    const metaArray: any[] = [];
    const metaKeys: string[] = ['remote_identity_id', 'manager_customer_id', 'client_customer_id'];
    let metaRow: MetaRow = null;

    metaKeys.forEach((value, idx) => {
      if (value === 'remote_identity_id') {
        metaRow = {
          data_id: 0,
          data_key: 'remote_identity_id',
          data_value: configState.remoteIdentityId,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'client_customer_id') {
        metaRow = {
          data_id: 0,
          data_key: 'client_customer_id',
          data_value: configState.customerId,
          product: productIdAmazonAdvertising
        };
      }
      else if (value === 'manager_customer_id') {
        metaRow = {
          data_id: 0,
          data_key: 'manager_customer_id',
          data_value: (configState.managerId != null) ? configState.managerId : configState.customerId,
          product: productIdAmazonAdvertising
        };
      }

      metaArray.push(metaRow);
    });

    return metaArray;

  }


  private findIncluded(includedArray: any[], relationType: string, relationId: number): any {
    return includedArray.find(o => (o.type === relationType && o.id === relationId.toString()));
  }

}

export interface PipelineCreation {
  data: {
    id?: number,
    type: 'Subscription';
    attributes: {
      account: number;
      product: number;
      product_plan?: number;
      remote_identity?: number;
      storage_group?: number;
      user: number;
      name: string,
      quantity: number,
      price: number,
      status?: string,
      auto_renew?: number,
      rabbit_payload_successful?: number,
      stripe_subscription_id?: string,
      primary_job_id?: number,
      pipeline?: string,
      date_start: string,
      date_end: string,
      created_at?: string,
      invalidated_at?: string,
      modified_at?: string,
      notified_at?: string,
      invalid_subscription?: number,
      canonical_name?: string,
      subscription_product_meta_attributes: any[]
    }
  };
}

export interface Pipeline {
  data: {
    type: 'Subscription',
    attributes: {
      account: {
        type: 'Account',
        id: number
      },
      product: {
        type: 'Product',
        id: number
      },
      product_plan?: {
        type: 'ProductPlan',
        id: number
      },
      storage_group: {
        type: 'StorageGroup',
        id: number
      },
      user: {
        type: 'User',
        id: number
      },
      name: string,
      quantity: number,
      price: number,
      status: string,
      auto_renew: boolean,
      rabbit_payload_successful?: boolean,
      stripe_subscription_id?: string,
      primary_job_id?: number,
      pipeline?: string,
      created_at: string,
      invalidated_at: string | null,
      modified_at: string,
      notified_at: string,
      invalid_subscription?: number,
      canonical_name: string,
      subscription_product_meta_attributes?: any[]
    }
  };
}

interface MetaRow {
  data_id: number;
  data_key: string;
  data_value: string;
  product: number;
}