<div class="modal-header">
  <h4 class="modal-title pull-left">Saving Pipeline</h4>
</div>
<div class="modal-body">
  <div class="mb-1">
    <div [ngSwitch]="state">
      <div *ngSwitchCase="'pipeline-process-complete'">
        Pipeline save finished.
      </div>
      <div *ngSwitchCase="'pipeline-process-saving'">
        Your pipeline is being saved.
      </div>
      <div *ngSwitchDefault>
        Preparing to save your pipeline.
      </div>
    </div>
  </div>
  <div class="progress">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" [style]="widthStyle"></div>
  </div>
</div>
