import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identity-shopify-create',
  templateUrl: './identity-shopify-create.component.html'
})
export class IdentityShopifyCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  createIdentity(): void {
  }

}
