<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-shield-check"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Security </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Updating your password </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> Changing your account password </h4>
<ol>
  <li class="text-muted mb-2">
    Requesting a password reset will trigger an email to be sent to the email address listed on your account page. Please check your spam or junk mail folders if the email does not arrive within a few minutes of requesting it.
  </li>

  <li class="text-muted mb-2">
    After submitting the new password, a confirmation is displayed that you can now log in with new credentials. 
  </li>
  <li class="text-muted mb-2">
    The reset password link in the email is valid for one use only. If the you receive multiple password reset emails, only the password link in the most recent email is valid.
  </li>
  <li class="text-muted mb-2">
    After changing your password, you will be required to login with your new credentials.
  </li>


</ol>