import { Integration } from '../../models/integration.model';
import { destinations } from './destinations';
import { amzAdsProducts  } from './amz-ads-products';
import { facebokInstagramProducts  } from './facebook-products';
import { googleProducts  } from './google-products';
import { amzSellerPartnerProducts  } from './amz-seller-partner-products';
import { youtubeProducts  } from './youtube-products';
import { vendors } from '../logos';



/**
 * Only include sources in the array below, destinations are included in the array above.
 */
export const sources: Integration[] = [
  ...destinations,
  ...amzAdsProducts,
  ...facebokInstagramProducts,
  ...googleProducts,
  ...amzSellerPartnerProducts,
  ...youtubeProducts,

  // Integrations from brands that only have 1 integration each.
  {
    productId: 33,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'CSV',
    type: 'source',
    sortOrder: 200,
    keywords: [
      'amazon'
    ],
    tagLine: 'something something dark side',
    routerLink: '/wizards/∂aws-sftp-batch-processing',
    logoPath: vendors.batch,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'aws.amazon.com/redshift',
      uri: 'https://aws.amazon.com/redshift'
    },
    history: false,
    manageMenu: {
    }
  },
  {
    productId: 35,
    enabled: false,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'AWS Kinesis Webhooks',
    type: 'source',
    sortOrder: 300,
    keywords: [
      'amazon'
    ],
    tagLine: 'something something dark side',
    routerLink: '/wizards/aws-sftp-batch-processing',
    logoPath: vendors.kinesis,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    docs: {
      text: '/amazon+advertising',
      uri: 'https://docs.openbridge.com/en/?q=amazon+advertising'
    },
    vendor: {
      text: 'Amazon Advertising API',
      uri: 'https://advertising.amazon.com/API/docs/en-us/'
    },
    history: false,
    manageMenu: {
    },
  },
  {
    productId: 49,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Shopify',
    name: 'Insights',
    type: 'source',
    sortOrder: 1600,
    tagLine: 'Operations insights for merchant orders, returns, customers, shipments, and many more',
    routerLink: '/wizards/shopify',
    logoPath: vendors.shopify,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 16,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'shopify.dev/api',
      uri: 'https://shopify.dev/docs/admin-api'
    },
    history: false,
    manageMenu: {
    }
  },
  // {
  //   productId: 42,
  //   enabled: false,
  //   comingSoon: true,
  //   isConfigurable: false,
  //   brand: 'Amazon',
  //   name: 'Orders Real-Time',
  //   type: 'source',
  //   sortOrder: 1200,
  //   tagLine: 'Optimized for real-time sales data synchronization, order updates, and order research',
  //   routerLink: '/wizards/mws-realtime-reporting',
  //   logoPath: vendors.amazon,
  //   frequency: 'Hourly',
  //   standardIntegration: true,
  //   premiumIntegration: false,
  //   remoteIdentityTypeId: 13,
  //   docs: {
  //     text: 'docs.openbridge.com',
  //     uri: 'https://docs.openbridge.com'
  //   },
  //   vendor: {
  //     text: 'github.com/selling-partner',
  //     uri: 'https://github.com/amzn/selling-partner-api-docs'
  //   },
  //   manageMenu: {
  //   }
  // }
];

