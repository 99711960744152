import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-list',
  templateUrl: './pick-list.component.html'
})
export class PickListComponent{
  @Input() cardData = [];
  @Input() parentForm = FormGroup;
  @Input() controlName: string = null;

  constructor(
    private changeDetection: ChangeDetectorRef
 ){}

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }


}
