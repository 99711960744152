
<!-- Image -->
<div class="avatar avatar-sm card-avatar">
    <img src="../assets/img/avatars/products/amazon.svg" class="avatar-img rounded" alt="...">
  </div>
  <!-- Heading -->
  <h2 class="text-center mb-2"> Profiles </h2>
  <!-- Text -->
  <p class="text-center mb-4"> Select A Profile </p>
  <!-- Divider -->
  <hr class="mb-4">
  <!-- Heading-->
  <h4 class="mt-2 mb-2"> Select your {{ integration | integrationFullname }} profile to collect data from </h4>
  <ol>

    <li class="text-muted mb-2">
        The <code>Selling Parter ID</code> reflects foo and bar. The <code>Names</code>, <code>Country Codes</code>, <code>Default Languages</code>, and <code>Default Currencies</code> are supplied directly by the API. These reflect settings on your account at Amazon.
      </li>

    <li class="text-muted mb-2"> The listed profile(s) reflect the permissions associated with the 
        a seller account authorizations. The profile list is dynamically generated based on the response
          from the API. If you do not see a seller or vendor profile listed, you need to verify that the
          user has the correct access permissions. </li>


    <li class="text-muted mb-2">
      Sellers who operate in more than one marketplace (i.e., Amazon.com, Amazon.co.uk, Amazon.co.jp) will have one
      profile for each each marketplace, <a
      href="https://docs.openbridge.com/en/articles/5013964-amazon-multi-marketplace-seller-accounts" rel="noopener" target="_blank">unless they are configured as multi-marketplace accounts</a>. </li>
  
    <li class="text-muted mb-2">
      In the event you do not see profile information, registering in the <a
        href="https://brandservices.amazon.com/" rel="noopener" target="_blank">Amazon Brand Registry</a> is required.
    </li>

  
  </ol>