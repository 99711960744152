import { rest } from 'msw';
import { findRequestURL } from '../mocks/function';

import { responseUserDetailsUpdate } from './data/response-user-details-update';
import { responsePasswordChange } from './data/response-password-change';
import { responseAmazonDSPSubscription } from './data/amazon-dsp/response-amazon-dsp-subscriptions';
import { responseAmazonDSPBrands } from './data/amazon-dsp/response-amazon-dsp-brand';
import { responseAmazonDSPProfiles } from './data/amazon-dsp/response-amazon-dsp-profiles';
import { responseBigQuerySubscription } from './data/big-query/response-big-query-subscription';
import { responseAmazonAdvertisingSubscription } from './data/amazon-advertising/response-amazon-advertising-subscription';
import { responseAmazonAdvertisingBrands } from './data/amazon-advertising/response-amazon-advertising-brand';
import { responseAmazonAttributionSubscription } from './data/amazon-attribution/response-amazon-attribution-subscription';
import { responseAmazonAttributionBrands } from './data/amazon-attribution/response-amazon-attribution-brand';
import { responseAmazonAdvertisingProfiles } from './data/amazon-advertising/response-amazon-advertising-profiles';
import { responseBigQueryDataSet } from './data/big-query/response-big-query-dataset';
import { responseBigQueryRemoteIdentityMeta } from './data/big-query/response-big-query-identity-meta';
import { responseBigQueryRemoteIdentity } from './data/big-query/response-big-query-remote-identity';
import { responseBigQueryValidationEncryption } from './data/big-query/response-big-query-validation-encryption';
import { responseBigQueryValidation } from './data/big-query/response-big-query-validation';
import { responseCustomerDetails } from './data/response-customer-details';
import { responsePipelineCount } from './data/response-pipeline-count';
import { responseSubscriptions } from './data/response-subscriptions';
import { responseSharedRemoteIdentities } from './data/response-shared-remote-identity';
import { responseAthenaAccountMappingEncryption } from './data/aws-athena/response-athena-account-mapping-encryption';
import { responseBlobAccountMappingEncryption } from './data/azure-blob-storage/response-blob-account-mapping-encryption';
import { responseDataLakeAccountMappingEncryption } from './data/azure-data-lake/response-data-lake-account-mapping-encryption';
import { responseAthenaStorages } from './data/aws-athena/response-athena-storages';
import { responseBlobStorages } from './data/azure-blob-storage/response-blob-storages';
import { responseDataLakeStorages } from './data/azure-data-lake/response-data-lake-storages';
import { responseAthenaStorage } from './data/aws-athena/response-athena-storage';
import { responseBlobStorage } from './data/azure-blob-storage/response-blob-storage';
import { responseDataLakeStorage } from './data/azure-data-lake/response-data-lake-storage';
import { responseAthenaEncryption } from './data/aws-athena/response-athena-encryption';
import { responseBlobEncryption } from './data/azure-blob-storage/response-blob-encryption';
import { responseDataLakeEncryption } from './data/azure-data-lake/response-data-lake-encryption';
import { responseAthenaAccountMapping } from './data/aws-athena/response-athena-account-mapping';
import { responseBlobAccountMapping } from './data/azure-blob-storage/response-blob-account-mapping';
import { responseDataLakeAccountMapping } from './data/azure-data-lake/response-data-lake-account-mapping';
import { responseAthenaSubscription } from './data/aws-athena/response-athena-subscription';
import { responseBlobSubscription } from './data/azure-blob-storage/response-blob-subscription';
import { responseDataLakeSubscription } from './data/azure-data-lake/response-data-lake-subscription';
import { responseAmazonAttributionProfiles } from './data/amazon-attribution/response-amazon-attribution-profiles';

import { responseBigQueryAccountMappingEncryption } from './data/big-query/response-big-query-account-mapping-encryption';
import { responseBigQueryAccountMapping } from './data/big-query/response-big-query-account-mapping';
import { responseBigQueryStorages } from './data/big-query/response-big-query-storages';
import { responseBigQueryStorage } from './data/big-query/response-big-query-storage';

import { responseSnowflakeAccountMappingEncryption } from './data/snowflake/response-snowflake-account-mapping-encryption';
import { responseSnowflakeAccountMapping } from './data/snowflake/response-snowflake-account-mapping';
import { responseSnowflakeEncryption } from './data/snowflake/response-snowflake-encryption';
import { responseSnowflakeStorage } from './data/snowflake/response-snowflake-storage';
import { responseSnowflakeStorages } from './data/snowflake/response-snowflake-storages';
import { responseSnowflakeSubscription } from './data/snowflake/response-snowflake-subscription';

import { responseRedshiftAccountMappingEncryption } from './data/aws-redshift/response-redshift-account-mapping-encryption';
import { responseRedshiftAccountMapping } from './data/aws-redshift/response-redshift-account-mapping';
import { responseRedshiftEncryption } from './data/aws-redshift/response-redshift-encryption';
import { responseRedshiftStorage } from './data/aws-redshift/response-redshift-storage';
import { responseRedshiftStorages } from './data/aws-redshift/response-redshift-storages';
import { responseRedshiftSubscription } from './data/aws-redshift/response-redshift-subscription';

// export const handlers = [];

export const handlers = [
  // public API used in identities unit test for initial MSW test
  // rest.get('https://cat-fact.herokuapp.com/facts/', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.json({
  //       id: 'test-id-001',
  //       firstName: 'John',
  //       lastName: 'Doe',
  //     }),
  //   );
  // }),

  // Shared remote identity get
  rest.get(findRequestURL('sharedIdentitiesURL'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseSharedRemoteIdentities),
    );
  }),

  // user data update
  rest.post(findRequestURL('userUpdateURL'), (req, res, ctx) => {
    const id = req.params.id;
    return res(
      ctx.delay(3000),
      ctx.status(200),
      ctx.json(responseUserDetailsUpdate),
    );
  }),

  // password change
  rest.post(findRequestURL('passwordChangeURL'), (req, res, ctx) => {
    return res(
      ctx.delay(3000),
      ctx.status(200),
      ctx.json(responsePasswordChange),
    );
  }),

  // subscription
  rest.get(findRequestURL('subscriptionURI'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseSubscriptions),
    );
  }),

  // pipe-line count
  rest.get(findRequestURL('pipelineURL'), (req, res, ctx) => {
    const query = req.url.searchParams;
    const date = query.get('date');
    return res(
      ctx.status(200),
      ctx.json(responsePipelineCount),
    );
  }),

  // customer details
  rest.get(findRequestURL('stripeCustomer'), (req, res, ctx) => {
    const user = req.params;

    return res(
      ctx.status(200),
      ctx.json(responseCustomerDetails),
    );
  }),

  // account mapping encryption
  rest.post(findRequestURL('accMappingEncryptionURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage;

    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaAccountMappingEncryption),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(200),
          ctx.json(responseBlobAccountMappingEncryption),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(200),
          ctx.json(responseDataLakeAccountMappingEncryption),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(200),
          ctx.json(responseSnowflakeAccountMappingEncryption),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(200),
          ctx.json(responseRedshiftAccountMappingEncryption),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(200),
          ctx.json(responseBigQueryAccountMappingEncryption),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }

  }),

  //  account mapping
  rest.post(findRequestURL('accMappingURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage;
    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaAccountMapping),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(201),
          ctx.json(responseBlobAccountMapping),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(201),
          ctx.json(responseDataLakeAccountMapping),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(201),
          ctx.json(responseSnowflakeAccountMapping),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(201),
          ctx.json(responseRedshiftAccountMapping),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(201),
          ctx.json(responseBigQueryAccountMapping),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }

  }),

  //  storages
  rest.post(findRequestURL('storagesURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage_type;

    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaStorages),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(201),
          ctx.json(responseBlobStorages),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(201),
          ctx.json(responseDataLakeStorages),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(201),
          ctx.json(responseSnowflakeStorages),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(201),
          ctx.json(responseRedshiftStorages),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(201),
          ctx.json(responseBigQueryStorages),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }

  }),

  //  storage
  rest.get(findRequestURL('storagesURI') + '/:id', (req, res, ctx) => {
    const id = req.params.id;

    switch (+id) {
      case 941: {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaStorage),
        );
      }
      case 958: {
        return res(
          ctx.status(200),
          ctx.json(responseBlobStorage),
        );
      }
      case 959: {
        return res(
          ctx.status(200),
          ctx.json(responseDataLakeStorage),
        );
      }
      case 1014: {
        return res(
          ctx.status(200),
          ctx.json(responseSnowflakeStorage),
        );
      }
      case 1016: {
        return res(
          ctx.status(200),
          ctx.json(responseRedshiftStorage),
        );
      }
      case 972: {
        return res(
          ctx.status(200),
          ctx.json(responseBigQueryStorage),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }

  }),

  //  encryption
  rest.post(findRequestURL('encryptURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;

    if (data.attributes.connectionString === 'DefaultEndpointsProtocol=https;AccountName=blobtest;AccountKey=xyz;EndpointSuffix=xyz') {
      return res(
        ctx.status(200),
        ctx.json(responseBlobEncryption),
      );
    }
    else if (data.attributes.connectionString === 'DefaultEndpointsProtocol=https;AccountName=datalaketest;AccountKey=xyz;EndpointSuffix=xyz') {
      return res(
        ctx.status(200),
        ctx.json(responseDataLakeEncryption),
      );
    }
    else if (data.attributes.awsSecretKey === 'awsAthenaSecretKey') {
      return res(
        ctx.status(200),
        ctx.json(responseAthenaEncryption),
      );
    }
    else if (data.attributes.password === 'snowflakePswd') {
      return res(
        ctx.status(200),
        ctx.json(responseSnowflakeEncryption),
      );
    }
    else if (data.attributes.password === 'awsRedshiftPassword') {
      return res(
        ctx.status(200),
        ctx.json(responseRedshiftEncryption),
      );
    }
    else if (data.attributes.serviceAccount) {
      // big query after account validation
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryValidationEncryption),
      );
    }
    else {
      return res(
        ctx.status(400),
        ctx.json('something went wrong !!'),
      );
    }

  }),

  // add new subscription
  rest.post(findRequestURL('subscriptionURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const destinationName: string = data.attributes.name;

    switch (destinationName) {
      case 'aws-athena-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseAthenaSubscription),
        );
      }
      case 'blob-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseBlobSubscription),
        );
      }
      case 'data-lake-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseDataLakeSubscription),
        );
      }
      case 'snowflake-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseSnowflakeSubscription),
        );
      }
      case 'aws-redshift-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseRedshiftSubscription),
        );
      }
      case 'amazon-advertizing-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseAmazonAdvertisingSubscription),
        );
      }
      case 'amazon-attribution-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseAmazonAttributionSubscription),
        );
      }
      case 'big-query-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseBigQuerySubscription),
        );
      }
      case 'amazon-dsp-mock-1': {
        return res(
          ctx.status(201),
          ctx.json(responseAmazonDSPSubscription),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }
  }),

  // big-query account validation
  rest.get(findRequestURL('bigQueryAccountValidationURI'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseBigQueryValidation),
    );
  }),

  // remote identity
  rest.post(findRequestURL('baseIdentitiesURL'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    return res(
      ctx.status(201),
      ctx.json(responseBigQueryRemoteIdentity),
    );
  }),

  // big-query remote identity meta
  rest.get(findRequestURL('remoteIdentityMetaEndpoint'),
    (req, res, ctx) => {
      const remoteIdentity = req.url.searchParams.get('remote_identity');
      const remoteIdentityTypeMetaKey = req.url.searchParams.get('remoteidentitytypemetakey');
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryRemoteIdentityMeta),
      );
    }),

  // big-query dataset
  rest.get(findRequestURL('serviceApiEndpoint') + 'bq/datasets/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryDataSet),
      );
    }),

  // amazon profiles-only
  rest.get(findRequestURL('serviceApiEndpoint') + 'amzadv/profiles-only/:id',
    (req, res, ctx) => {
      const profileTypes = req.url.searchParams.get('profile_types');
      const id = req.params.id;

      if (id === '22' && profileTypes === 'seller,vendor') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAdvertisingProfiles),
        );
      }
      else if (id === '112' && profileTypes === 'attribution') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAttributionProfiles),
        );
      }
      else if (id === '22' && profileTypes === 'dsp') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonDSPProfiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }),

  // amazon brands
  rest.get(findRequestURL('serviceApiEndpoint') + 'amzadv/brands/:id',
    (req, res, ctx) => {
      const profiles = req.url.searchParams.get('profiles');
      const id = req.params.id;

      if (id === '22' && profiles === '586011285459526,2831667069998299') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAdvertisingBrands),
        );
      }
      else if (id === '112' && profiles === '2116350962252689') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAttributionBrands),
        );
      }
      else if (id === '22' && profiles === '693136736383016') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonDSPBrands),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json('something went wrong !!'),
        );
      }
    }),

];



