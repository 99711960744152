import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { wizardIntegrationConfig, wizardIntegrationInit, wizardIntegrationPreviousStage,
         wizardIntegrationRequestCacheUpdate,
         wizardIntegrationSave, wizardIntegrationSpecificStage, wizardIntegrationStagelessConfig } from 'src/app/core/store/actions/wizard.actions';
import { AppState } from 'src/app/core/store/reducers';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { flashNotificationsFormValidationError,
         flashNotificationsFormValidationErrorWithDetails,
         flashNotificationsFormValidationSuccess } from 'src/app/core/store/actions/flash-notifications.actions';
import { generateKeyFromObject } from 'src/app/core/functions/cache';
import { wizardCurrentCacheSelector } from 'src/app/core/store/selectors/wizard.selectors';

@Injectable({
  providedIn: 'root'
})
export class BaseWizardService {

  currentStagePosition: number;
  totalNumberOfStages: number;
  wizardRequesetCacheState$ = this.store$.pipe(select(wizardCurrentCacheSelector));
  wizardRequestCache: object;

  currentStageIndex: number;
  stageListState$: any = null;
  stageDataRequestState$ = new Subject<any>();
  stageData$ = new Subject<any>();
  stageProcessed = new Subject<any>();
  stages: string[];
  stageIndex = 0;

  constructor(
    protected httpClient: HttpClient,
    protected store$: Store<AppState>
  ) {
    this.stageListState$ = this.store$.select('wizard').subscribe(response => {
      if (response.stages != null && response.stages.length > 0) {
        this.totalNumberOfStages = response.stages.length - 1;
        this.currentStagePosition = this.findCurrentStageInWizard(response.stages, response.stage);

        this.stages = response.stages;
        this.stageIndex = this.stages.findIndex((element) => element === response.stage);
      }
    });

    this.wizardRequesetCacheState$.subscribe(response => {
      this.wizardRequestCache = response;
    });
  }

  initializeWizardState(routerLink: string, stage: string, stages: string[], config: any, productId?: number): void {
    this.store$.dispatch(wizardIntegrationInit({
      config: config,
      productId: (productId) ? productId : null,
      routerLink: routerLink,
      stage: stage,
      stages: stages
    }));
  }

  validateFormGroup(formGroup: FormGroup): void {
    const errors: string[] = [];
    const controls = Object.keys(formGroup.controls);

    controls.forEach((controlKey) => {
      const controlErrors: any[] = [];

      if (formGroup.controls[controlKey].status === 'INVALID') {
        const errorObj = formGroup.controls[controlKey].errors;
        const errorKeys = Object.keys(errorObj);
        errorKeys.forEach((errorKey) => {
          if (errorObj[errorKey] === true) {
            controlErrors.push(errorKey);
          }
        });

      }
    });
  }

  doProcessConfig(config: any): void {

    if (this.stages[(this.stageIndex + 1)] === 'save') {
      // Dispatch
      this.store$.dispatch(wizardIntegrationStagelessConfig({
        config: config
      }));

      this.store$.dispatch(wizardIntegrationSave());
    } else {
      this.nextStageIndex();
      const nextStage = this.stages[this.stageIndex];

      // Dispatch save integration.
      this.store$.dispatch(wizardIntegrationConfig({
        config: config,
        stage: nextStage
      }));
    }
  }

  resetStageIndex(): void {
    this.stageIndex = 0;
  }

  doProcessError(error: string): void {
    this.store$.dispatch(flashNotificationsFormValidationError({ message: error }));
  }

  doProcessErrorWithDetails(error: string, details: string): void {
    this.store$.dispatch(flashNotificationsFormValidationErrorWithDetails({ message: error, details: details }));
  }

  doProcessSuccess(success: string): void {
    this.store$.dispatch(flashNotificationsFormValidationSuccess({ message: success }));
  }

  // Old functions below. we may keep some we may not keep others..
  nextStageIndex(): void {
    this.stageIndex = (this.stageIndex !== (this.stages.length - 1)) ? this.stageIndex + 1 : this.stageIndex;
  }

  previousStageIndex(): void {
    this.stageIndex = (this.stageIndex !== 0) ? this.stageIndex - 1 : this.stageIndex;
  }

  previousStage(): void {
    this.previousStageIndex();
    const previousStage = this.stages[this.stageIndex];
    // Dispatch
    this.store$.dispatch(wizardIntegrationPreviousStage({
      stage: previousStage,
    }));
  }

  nextStage(): void {
    if (this.currentStageIndex < this.stages.length) {
      this.currentStageIndex++;
    }
  }

  specficStage(stageIndex: number): void {
    this.stageIndex = stageIndex;
    const stage = this.stages[this.stageIndex];

    // Dispatch
    this.store$.dispatch(wizardIntegrationSpecificStage({
      stage: stage,
    }));
  }

  setServiceStages(stages: string[]): void {
    this.stages = stages;
  }

  generatePayload(stagePosition: number, totalStages: number, nextStage: string, formValue: any): any {
    return {
      stagePosition: stagePosition,
      totalStages: totalStages,
      nextStage: this.stages[this.currentStageIndex],
      formValue: formValue
    };
  }

  findRecordForSegment(segmentName: string, segmentValue: string, objectList: any[]): any {
    return objectList.find(x => x[segmentName] === segmentValue);
  }

  async getIdentityMetaForIdentityId(identityId: number, identityTypeMetaKeyId?: number): Promise<any> {

    const apiEndpoint = environment.openbridgeApiUris.identities + '/rim?remote_identity='
      + identityId + '&remoteidentitytypemetakey=' + identityTypeMetaKeyId;
    const response = await this.httpClient.get(apiEndpoint).toPromise();

    // There can be only 1, so return just the data we care about.
    if (response['data'].length > 0) {
      return response['data'][0];
    }

    return false;
  }

  async getDataFromProvider(provider: string, method: string, identityId: number, params?: object): Promise<object> {

    let paramString: string = null;

    if (params) {
      paramString = this.simpleSerialize(params);
    }

    const cacheParams = {
      provider: provider,
      method: method,
      identityId: identityId,
      ...params
    };

    const cacheKey = this.getCacheKey(cacheParams);
    const cacheObjectKeys = Object.keys(this.wizardRequestCache);

    if (cacheObjectKeys.includes(cacheKey)) {
      return JSON.parse(this.wizardRequestCache[cacheKey]);
    }

    let apiEndpoint = environment.openbridgeApiUris.service + '/service/'
      + provider + '/' + method + '/' + identityId.toString();

    if (paramString) {
      apiEndpoint += ('?' + paramString);
    }

    const response = await this.httpClient.get(apiEndpoint).toPromise();
    const cacheData = {key: cacheKey, cache: JSON.stringify(response) };
    this.store$.dispatch(wizardIntegrationRequestCacheUpdate(cacheData));
    return response;

  }

  async getDataForService(urlParams: any[], params?: object): Promise<object> {

    let paramString: string = null;
    const urlPath = urlParams.join('/');

    if (params) {
      paramString = this.simpleSerialize(params);
    }

    const cacheKey = this.getCacheKey(params);
    const cacheObjectKeys = Object.keys(this.wizardRequestCache);

    if (cacheObjectKeys.includes(cacheKey)) {
      return JSON.parse(this.wizardRequestCache[cacheKey]);
    }

    let apiEndpoint = environment.openbridgeApiUris.service + '/service/'
    + urlPath;

    if (paramString) {
      apiEndpoint += ('?' + paramString);
    }

    const response = await this.httpClient.get(apiEndpoint).toPromise();

    const cacheData = {key: cacheKey, cache: JSON.stringify(response) };
    this.store$.dispatch(wizardIntegrationRequestCacheUpdate(cacheData));

    return response;
  }

  protected emitStageDataObject(stageData: any): void {
    this.stageData$.next(stageData);
  }

  protected simpleSerialize(params: object): string {
    const keys = Object.keys(params);
    let serializedString = '';

    keys.forEach((value, idx) => {
      const paramString = value + '=' + encodeURI(params[value]);

      if (serializedString !== '') {
        serializedString += '&';
      }
      serializedString += paramString;
    });

    return serializedString;
  }

  initializeConfigState(wizardMode: string, subscription: any, spm: any): any {
    // Initialize the stage index.
    this.stageIndex = 0;
  }

  async getSubscriptionProductMeta(subscriptionId: number): Promise<any> {
    return await this.httpClient.get(environment.openbridgeApiUris.subscription + '/spm?subscription=' + subscriptionId).toPromise();
  }

  protected buildObjectFromSpmDataArray(spmDataArray: any[]): any {
    const dataObject = {};

    spmDataArray.forEach((val) => {
      dataObject[val.attributes.data_key] = val.attributes.data_value;
    });

    return dataObject;
  }

  findCurrentStageInWizard(totalStages, currentStage): number {
    const index = totalStages.indexOf(currentStage);
    return index + 1;
  }

  getCacheKey(params: object): string {
    return generateKeyFromObject(params);
  }


}
