import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-filter-nav',
  templateUrl: './filter-nav.component.html'
})
export class FilterNavComponent implements OnInit {
  @Input() integrations: Integration[] = [];
  @Output() filteredIntegrations = new EventEmitter<Integration[]>();
  allIntegrationCount: number;
  allStandardCount: number;
  allPremiumCount: number;
  activeFilterType: 'all' | 'standard' | 'premium' | 'enterprise' = 'all';

  constructor() { }

  ngOnInit(): void {
    this.allIntegrationCount = this.integrations.length;
    this.allStandardCount = this.countIntegrationsByType('standard');
    this.allPremiumCount = this.countIntegrationsByType('premium');
    this.setFilter('all');
  }

  countIntegrationsByType(filterType: 'standard' | 'premium' | 'enterprise'): number {
    let count = 0;
    this.integrations.forEach((val, idx) => {
      if (filterType === 'standard' && val.standardIntegration === true && (val.comingSoon || val.enabled)) {
        count++;
      }
      if (filterType === 'premium' && val.premiumIntegration === true && (val.comingSoon || val.enabled)) {
        count++;
      }
    });
    return count;
  }

  setFilter(filterType: 'all' | 'standard' | 'premium' | 'enterprise'): boolean {
    this.activeFilterType = filterType;
    let filteredIntegrations: Integration[] = [];
    if (filterType === 'all') {
      filteredIntegrations = [...this.integrations];
    }
    else {
      this.integrations.forEach((val, idx) => {
        if (filterType === 'standard' && val.standardIntegration === true && (val.comingSoon || val.enabled)) {
          filteredIntegrations.push(val);
        }
        if (filterType === 'premium' && val.premiumIntegration === true && (val.comingSoon || val.enabled)) {
          filteredIntegrations.push(val);
        }
      });
    }

    this.filteredIntegrations.emit(filteredIntegrations);

    // Return false to prevent default.
    return false;
  }

}
