import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AppState } from 'src/app/core/store/reducers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService implements OnInit {
  stripeCustomerUpdated$: Subject<boolean> = new Subject();
  stripeSession$: Subject<string> = new Subject();

  constructor(
    protected store$: Store<AppState>,
    protected http: HttpClient
  ) {
    this.stripeCustomerUpdated$ = new Subject<boolean>();
  }

  ngOnInit(): void {
  }

  createStripeCheckoutSession(data: any): void {
    const hostname = window.location.host;

    const payload = {
      data: {
        type: 'Service',
        attributes: {
          allow_promotion_codes: true,
          customer: data.customerId,
          mode: 'subscription',
          payment_method_types: ['card'],
          subscription: (data.subscriptionId !== null) ? data.subscriptionId : null,
          line_items: [
            {
              price: data.selectedPlan
            }
          ],
          success_url: 'https://' + hostname + '/account/billing',
          cancel_url: window.location.href + '?checkout-cancelled=true'
        }
      }
    };

    this.http.post(environment.openbridgeApiUris.service + '/service/stripe/checkout-session', payload).subscribe(response => {
      this.stripeSession$.next(response['data']['id']);
    });
  }

  processStripeCustomer(stripeCustomer: any): any {
    if (stripeCustomer !== null) {

      const subscriptionDetails = this.processSubscriptionDetails(stripeCustomer);

      const details = {
        customerId: stripeCustomer.id,
        subscriptionId: subscriptionDetails.id,
        planId: subscriptionDetails.planId,
        planName: subscriptionDetails.planName,
        planCurrentPeriodEnds: subscriptionDetails.planCurrentPeriodEnd
      };

      return details;
    }
    return {
      customerId: null,
      subscriptionId: null,
      planId: null,
      planName: null
    };
  }

  private processSubscriptionDetails(stripeCustomer: any): any {
    const subscriptions = stripeCustomer.subscriptions;

    if (subscriptions.total_count > 0) {
      return {
        id: subscriptions.data[0].id,
        planId: subscriptions.data[0].plan.id,
        planCurrentPeriodEnd: subscriptions.data[0].current_period_end,
        planName: subscriptions.data[0].plan.name,
        cancelAtPeriodEnd: subscriptions.data[0].cancel_at_period_end
      };
    }
    else {
      return {
        id: null,
        planId: null,
        planCurrentPeriodEnd: null,
        planName: 'Expired',
        cancelAtPeriodEnd: null,
      };
    }
  }

  async retrieveStripeCustomer(stripeCustomerId: string): Promise<any> {
    return await this.http.get(environment.openbridgeApiUris.service + '/service/stripe/customer/' + stripeCustomerId).toPromise();
  }

  stripePlanIsLegacyPlan(stripePlanId: string): boolean {
    const priceIds = Object.values(environment.stripe.prices);
    return (!priceIds.includes(stripePlanId)) ? true : false;
  }

  stripePlanCanHavePremiumProducts(stripePlanId: string): boolean {

    switch (stripePlanId) {
      case environment.stripe.prices.trialPlanId:
        return environment.premiumEnabledPrices.trialPlanId;
      case environment.stripe.prices.standardPriceId:
        return environment.premiumEnabledPrices.standardPriceId;
      case environment.stripe.prices.premiumPriceId:
        return environment.premiumEnabledPrices.premiumPriceId;
      case environment.stripe.prices.businessPriceId:
        return environment.premiumEnabledPrices.businessPriceId;
      case environment.stripe.prices.enterprisePriceId:
        return environment.premiumEnabledPrices.enterprisePriceId;
      default:
        return false;
    }
  }

}
