  <canvas baseChart *ngIf="chartDataSets"
    [chartType]="chartType"
    [labels]="chartLabels"
    [datasets]="chartDataSets"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [legend]="chartLegend"
    [ngClass]="chartClass"
    height="{{ chartHeight }}"
    width="{{ chartWidth }}"
    >
  </canvas>  
<!-- style="{{ chartStyle }}" -->
