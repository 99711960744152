import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HealthchecksService {
  transactionsSubscription$: Subject<any> = new Subject<any>();
  healthCheckRequestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private http: HttpClient
  ) { }

  async getHealthChecks(accountId: number, page: number, startDate: string, endDate: string, status: string): Promise<void> {
    try {
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-initialize', payload: null });
      const params = {
        page: page,
        status: status,
        modified_at__gte: startDate,
        modified_at__lte: endDate,
      };

      const httpParams: HttpParams = new HttpParams({ fromObject: params });
      const healthCheckRequestUrl = environment.openbridgeApiUris.service +
        '/service/healthchecks/' + environment.openbridgeApiUris.partialIdentifier +
        '/healthchecks/account/' + accountId + '?' + httpParams.toString();

      await this.getHealthChecksFromUrl(healthCheckRequestUrl);
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-complete', payload: null });
    }
    catch (error) {
      this.healthCheckRequestState$.next({ state: 'healthcheck-request-complete', payload: null });
      throw error;
    }
  }

  async getHealthChecksFromUrl(url: string): Promise<void> {
    const response = await this.http.get(url).toPromise();
    this.transactionsSubscription$.next(response);
  }
}
