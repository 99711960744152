<div class="modal-header">
  <h1 class="modal-title pull-left"><i class="me-3 text-premium fa-duotone fa-badge-check"></i>You discovered a premium service!</h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>An active Premium plan unlocks your ability to create <span class="badge bg-premium"><i
      class="fa-duotone fa-badge-check"></i> Premium</span> and <span class="badge bg-standard">Standard</span> data pipelines. Getting started with a Premium plan is quick and easy. </p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-premium" routerLink="/plans" (click)="bsModalRef.hide()">
    Upgrade Now <i class="fa-duotone fa-arrow-from-left ms-2 fa-lg"></i>
  </button>
</div>