<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/logos/openbridge.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Select Your Destination </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline">Choose The Destination For Your Source Data </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading -->
<h4 class="mb-2">
</h4>
<ol>
  <li class="text-muted mb-2"> Openbridge collects, processes, routes, and loads data to a target destination of your choosing. A target is a single data warehouse or data lake that reflects a unique cluster, host, data set, or database. Openbridge allows you to connect to multiple destinations based on your plan. Once a warehouse or data lake is registered within Openbridge, it is displayed as a destination. </li>
  
 
  <li class="text-muted mb-2"> Due to the scale, complexity, and volume of data, use of a data lake or warehouse is needed. A destination acts as a central repository of data aggregated from disparate sources. The destination becomes the hub that allows you to plug in your favorite tools so you can query, discover, visualize or model that data.</li>

  <li class="text-muted mb-2"> For more information on destinations, see <a
    href="https://docs.openbridge.com/en/?q=Data+Destination"
    rel="noopener" target="_blank" class="light-link">data destination FAQs</a> for more information.</li>
</ol>