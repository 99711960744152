<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-usd-square"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Overview </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline">Viewing and managing billing via the customer portal   </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> Payments, billing, and invoices</h4>
<ol>
  <li class="text-muted mb-2">
    The billing portal supports updating subscriptions, canceling subscriptions, viewing active subscriptions, and invoice history. You can also download invoices and update payment methods. Select the <strong>"Manage"</strong> to visit the portal.
  </li>
  <li class="text-muted mb-2">
    The customer billing portal is a secure, Stripe-hosted system that lets you manage their payment, subscription, and billing details. 
  </li>
  <li class="text-muted mb-2"> Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry. To accomplish this, Stripe uses best-in-class security tools and practices to maintain a high level of security.</li>
  <li class="text-muted mb-2">Our pricing is based on usage or "pay-as-you-go" for pipelines. All plans leverage
    pay-as-you-go pricing for data pipelines. Why? Pay-as-you-go allows you to quickly adapt to changing needs and
    get charged based on actual usage levels. We detail pricing uses cases in our <a
        href="https://docs.openbridge.com/en/articles/5231237-how-pricing-and-billing-work-preview#h_07b149aa39"
        rel="noopener" target="_blank" class="light-link">pricing examples</a>.</li>

        <li class="text-muted mb-2">
          Daily data pipeline charts show the count of active daily pipelines, by day, for the prior 30 days.
        </li>

</ol>