<div class="modal-header">
  <h1 class="modal-title pull-left">Deactivate the pipeline!</h1>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-1">
    <p>Please Deactivate the current pipeline first to delete it.</p>
  </div>
</div>
<!-- <div class="modal-footer">
  <button class="btn btn-primary" routerLink="/plans" (click)="bsModalRef.hide()">
    Upgrade Now <i class="fa-duotone fa-up-from-line fa-lg ms-1 ps-1"></i>
  </button>
</div> -->