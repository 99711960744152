import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AccMappingAmazonAthena, AccMappingAzureBlob, AccMappingAzureDataLake, AccMappingBigquery,
  AccMappingRedshift, AccMappingSnowflake, AccMappingSpectrum, AccMappingTypes
} from 'src/app/shared/models/accmapping.interface';
import { ConfigAwsAthena, ConfigAwsSpectrum, ConfigAzureBlobStorage, ConfigAzureDataLake, ConfigGoogleBigquery, ConfigSnowflake } from 'src/app/shared/models/product-configuration.types';
import { environment } from 'src/environments/environment';
import { AccmappingService } from './accmapping.service';

@Injectable({
  providedIn: 'root'
})
export class ApiStoragesService {

  storageTestState$: Subject<{ state: string, payload: any }> = new Subject<{ state: string, payload: any }>();

  constructor(
    private accMapping: AccmappingService,
    private httpClient: HttpClient
  ) { }

  async testAthenaDestination(config: ConfigAwsAthena): Promise<any> {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(1, true);

    // // Create the account mapping configuration.
    const mappingConfig: AccMappingAmazonAthena = {
      data: {
        attributes: {
          is_temp: true,
          athena_access_key_id: config.awsAccessKey,
          athena_bucket: config.s3Bucket,
          athena_database: config.awsDatabaseName,
          athena_region: config.awsRegion,
          athena_secret_access_key: config.awsSecretKey,
          path: this.accMapping.generateAccmappingPath(storageKey),
          region: 'us-east-1',
          s3_bucket: environment.accMapping.bucketKey,
          storage: 'athena'
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  async testAzureBlobDestination(config: ConfigAzureBlobStorage) {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(1, true);

    let mappingConfig: AccMappingAzureBlob = {
      'data': {
        'attributes': {
          'is_temp': true,
          'container': config.storageContainer,
          'connection_string': config.connectionString,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'region': 'us-east-1',
          's3_bucket': environment.accMapping.bucketKey,
          'storage': 'azure_blob',
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  async testAzureLakeDestination(config: ConfigAzureDataLake) {

    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(1, true);

    const mappingConfig: AccMappingAzureDataLake = {
      'data': {
        'attributes': {
          'is_temp': true,
          'container': config.storageContainer,
          'connection_string': config.connectionString,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'region': 'us-east-1',
          's3_bucket': environment.accMapping.bucketKey,
          'storage': 'azure_datalake',
        }
      }
    };

    return await this.startTest(mappingConfig);

  }

  //  Need to figure out what to do with big query.
  async testBigQueryDestination(config: any) {
    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(1, true);

    const mappingConfig: AccMappingBigquery = {
      'data': {
        'attributes': {
          'is_temp': true,
          'bigquery_dataset_id': config.datasetId,
          'bigquery_project_id': config.projectId,
          'bigquery_service_account': config.serviceAccountJson,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'region': 'us-east-1',
          's3_bucket': environment.accMapping.bucketKey,
          'storage': 'bigquery',
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testRedshiftDestination(config: any) {

    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(1, true);

    // Create the account mapping configuration.
    const mappingConfig: AccMappingRedshift = {
      'data': {
        'attributes': {
          'is_temp': true,
          'database': config.dbname,
          'host': config.host,
          'password': config.password,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'port': config.port,
          'region': 'us-east-1',
          'storage': 'redshift',
          'user': config.username
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  async testSnowflakeDestination(config: ConfigSnowflake) {
    this.sendInitializationMessage();
    const storageKey = this.accMapping.generateStorageKey(1, true);

    const mappingConfig: AccMappingSnowflake = {
      'data': {
        'attributes': {
          'is_temp': true,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'region': 'us-east-1',
          's3_bucket': environment.accMapping.bucketKey,
          'snowflake_account': config.account,
          'snowflake_database': config.databaseName,
          'snowflake_schema': config.schema,
          'snowflake_password': config.password,
          'snowflake_user': config.username,
          'snowflake_warehouse': config.warehouse,
          'storage': 'snowflake',
          'templates': 'https://s3.amazonaws.com/ob_internal/templates/default.json'
        }
      }
    };;

    return await this.startTest(mappingConfig);
  }

  async testSpectrumDestination(config: ConfigAwsSpectrum) {
    this.sendInitializationMessage();

    const storageKey = this.accMapping.generateStorageKey(1, true);

    const mappingConfig: AccMappingSpectrum = {
      'data': {
        'attributes': {
          'is_temp': true,
          'database': config.dbname,
          'host': config.host,
          'user': config.username,
          'password': config.password,
          'path': this.accMapping.generateAccmappingPath(storageKey),
          'port': +config.port,
          'spectrum_bucket': config.awsBucket,
          'spectrum_database': config.spectrumDb,
          'spectrum_iam_role': config.awsIamRoleArn,
          'spectrum_region': config.awsRegion,
          'spectrum_schema': config.spectrumSchema,
          'region': 'us-east-1',
          's3_bucket': environment.accMapping.bucketKey,
          'storage': 'spectrum',
        }
      }
    };

    return await this.startTest(mappingConfig);
  }

  private sendInitializationMessage() {
    this.storageTestState$.next({ state: 'storage-test-initialized', payload: null });
  }

  private async startTest(mappingConfig: AccMappingTypes) {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    try {

      this.storageTestState$.next({ state: 'storage-test-store-map', payload: null });

      const accMappingCreateResponse = await this.accMapping.createAccountMapping(mappingConfig, true);

      let accMappingDirPath = accMappingCreateResponse.path;

      let storageKey = this.getStorageKeyFromPath(accMappingDirPath);

      if (environment.enableMock) {
        await delay(2000);
      }

      this.storageTestState$.next({ state: 'storage-test-submitted', payload: null });

      const storageTestId = this.submitPathForTest(accMappingDirPath, mappingConfig.data.attributes.storage, storageKey);

      this.storageTestState$.next({ state: 'storage-test-start-poll', payload: null });

      if (environment.enableMock) {
        await delay(2000);
      }

      const validationResponse = await this.pollValidationResponse((await storageTestId));
      this.storageTestState$.next({ state: 'storage-test-complete', payload: validationResponse });

      return validationResponse;

    }
    catch (error) {
      this.storageTestState$.next({ state: 'storage-test-complete', payload: error });
    }
  }

  private async submitPathForTest(accMappingDirPath: string, storageType: string, storageKey: string) {

    const payload = {
      'data': {
        'attributes': {
          'storage_type': storageType,
          'path': accMappingDirPath + '/storage_validation',
          'account_id': storageKey
        }
      }
    };

    const storagesPostUri = environment.openbridgeApiUris.service + '/service/storages/' + environment.openbridgeApiUris.partialIdentifier + '/v1/storages';

    const postResponse = await this.httpClient.post(storagesPostUri, payload, { observe: 'response' }).toPromise();

    return postResponse.body['data']['id'];

  }

  private async pollValidationResponse(storageTestId: string): Promise<any> {

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const pollLimit = 20;
    let pollCount = 0;
    let postResponse = null;
    let validationResponse = false;
    let status = '';

    try {
      const storagesPostUri = environment.openbridgeApiUris.service + '/service/storages/' +
        environment.openbridgeApiUris.partialIdentifier + '/v1/storages/' + storageTestId;

      while (!validationResponse) {
        postResponse = await this.httpClient.get(storagesPostUri, { observe: 'response' }).toPromise();

        // Simplify the status.
        status = postResponse.body.data.attributes.status;

        if (status === 'ACTIVE' || status === 'FAILED') {
          validationResponse = true;
        }

        if (pollCount++ === environment.storageValidation.numberOfPollsBeforeFailure) {
          throw 'Unable to resolve test with test id: ' + storageTestId;
        }

        // If we didn't pass wait 2 seconds.
        if (!validationResponse) {
          await delay(environment.storageValidation.timeBetweenPollsInMilliseconds);
        }
      }
    }
    catch {
      postResponse = { message: "Polling timed out before testing could resolve." };
    }


    return postResponse;
  }

  private getPathFromURI(path: string) {
    const uriPathParts = path.split('/');
    return decodeURIComponent(uriPathParts[(uriPathParts.length - 1)]);
  }

  private getStorageKeyFromPath(path: string) {
    const uriPathParts = path.split('/');
    return uriPathParts[(uriPathParts.length - 1)];
  }

}
