<div class="help-drawer-overlay" [@helpDrawerOverlay]="helpDrawerOverlay" (click)="toggleModal()"></div>
<div>

  <div [@helpDrawer]="helpDrawer" class="help-drawer">
    <div class="modal-content">
      <div class="modal-body">
        <a class="close" id="helpDrawerCloseBtn" href (click)="toggleModal()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </a>
        <div [ngSwitch]="helpComponentIdentifier">
          <div *ngSwitchCase="'sources'">
            <app-help-sources></app-help-sources>
          </div>
          <div *ngSwitchCase="'destinations'">
            <app-help-destinations></app-help-destinations>
          </div>
          <div *ngSwitchCase="'identities'">
            <app-help-identities></app-help-identities>
          </div>
          <div *ngSwitchCase="'pipelines'">
            <app-help-pipelines></app-help-pipelines>
          </div>
          <div *ngSwitchCase="'healthchecks'">
            <app-help-healthchecks></app-help-healthchecks>
          </div>
          <div *ngSwitchCase="'pricing'">
            <app-help-pricing></app-help-pricing>
          </div>
          <div *ngSwitchCase="'general'">
            <app-help-general></app-help-general>
          </div>
          <div *ngSwitchCase="'billing'">
            <app-help-billing></app-help-billing>
          </div>
          <div *ngSwitchCase="'security'">
            <app-help-security></app-help-security>
          </div>
          <div *ngSwitchCase="'destinations-select'">
            <app-help-destinations-select></app-help-destinations-select>
          </div>
          <div *ngSwitchCase="'amazon-advertising-checklist'">
            <app-help-amazon-advertising-checklist></app-help-amazon-advertising-checklist>
          </div>
          <div *ngSwitchCase="'amazon-advertising-identity'">
            <app-help-amazon-advertising-identity></app-help-amazon-advertising-identity>
          </div>
          <div *ngSwitchCase="'amazon-advertising-details'">
            <app-help-amazon-advertising-details></app-help-amazon-advertising-details>
          </div>
          <div *ngSwitchCase="'amazon-attribution-checklist'">
            <app-help-amazon-attribution-checklist></app-help-amazon-attribution-checklist>
          </div>
          <div *ngSwitchCase="'amazon-attribution-identity'">
            <app-help-amazon-attribution-identity></app-help-amazon-attribution-identity>
          </div>
          <div *ngSwitchCase="'amazon-attribution-details'">
            <app-help-amazon-attribution-details></app-help-amazon-attribution-details>
          </div>
          <div *ngSwitchCase="'amazon-dsp-checklist'">
            <app-help-amazon-dsp-checklist></app-help-amazon-dsp-checklist>
          </div>
          <div *ngSwitchCase="'amazon-dsp-identity'">
            <app-help-amazon-dsp-identity></app-help-amazon-dsp-identity>
          </div>
          <div *ngSwitchCase="'amazon-dsp-details'">
            <app-help-amazon-dsp-details></app-help-amazon-dsp-details>
          </div>

          <div *ngSwitchCase="'amazon-spapi-checklist'">
            <app-help-amazon-spapi-checklist [integration]="integration"></app-help-amazon-spapi-checklist>
          </div>
          <div *ngSwitchCase="'amazon-spapi-identity'">
            <app-help-amazon-spapi-identity [integration]="integration"></app-help-amazon-spapi-identity>
          </div>
          <div *ngSwitchCase="'amazon-spapi-details'">
            <app-help-amazon-spapi-details [integration]="integration"></app-help-amazon-spapi-details>
          </div>




          <div *ngSwitchCase="'amzn-athena-checklist'">
            <app-help-amzn-athena-checklist></app-help-amzn-athena-checklist>
          </div>
          <div *ngSwitchCase="'amzn-athena-details'">
            <app-help-amzn-athena-details></app-help-amzn-athena-details>
          </div>
          <div *ngSwitchCase="'amzn-redshift-checklist'">
            <app-help-amzn-redshift-checklist></app-help-amzn-redshift-checklist>
          </div>
          <div *ngSwitchCase="'amzn-redshift-details'">
            <app-help-amzn-redshift-details></app-help-amzn-redshift-details>
          </div>
          <div *ngSwitchCase="'amzn-spectrum-checklist'">
            <app-help-amzn-spectrum-checklist></app-help-amzn-spectrum-checklist>
          </div>
          <div *ngSwitchCase="'amzn-spectrum-details'">
            <app-help-amzn-spectrum-details></app-help-amzn-spectrum-details>
          </div>
          <div *ngSwitchCase="'azure-blob-storage-checklist'">
            <app-help-azure-blob-storage-checklist></app-help-azure-blob-storage-checklist>
          </div>
          <div *ngSwitchCase="'azure-blob-storage-details'">
            <app-help-azure-blob-storage-details></app-help-azure-blob-storage-details>
          </div>
          <div *ngSwitchCase="'azure-data-lake-checklist'">
            <app-help-azure-data-lake-checklist></app-help-azure-data-lake-checklist>
          </div>
          <div *ngSwitchCase="'azure-data-lake-details'">
            <app-help-azure-data-lake-details></app-help-azure-data-lake-details>
          </div>
          <div *ngSwitchCase="'bigquery-checklist'">
            <app-help-bigquery-checklist></app-help-bigquery-checklist>
          </div>
          <div *ngSwitchCase="'bigquery-identity'">
            <app-help-bigquery-identity></app-help-bigquery-identity>
          </div>
          <div *ngSwitchCase="'bigquery-details'">
            <app-help-bigquery-details></app-help-bigquery-details>
          </div>
          <div *ngSwitchCase="'pipeline-name'">
            <app-help-common-pipeline-name></app-help-common-pipeline-name>
          </div>
          <div *ngSwitchCase="'destination-name'">
            <app-help-common-destination-name></app-help-common-destination-name>
          </div>
          <div *ngSwitchCase="'destination-select'">
            <app-help-common-destination-select></app-help-common-destination-select>
          </div>
          <div *ngSwitchCase="'destination-complete'">
            <app-help-common-complete></app-help-common-complete>
          </div>
          <div *ngSwitchCase="'facebook-ads-checklist'">
            <app-help-facebook-ads-checklist></app-help-facebook-ads-checklist>
          </div>
          <div *ngSwitchCase="'facebook-ads-identity'">
            <app-help-facebook-ads-identity></app-help-facebook-ads-identity>
          </div>
          <div *ngSwitchCase="'facebook-ads-details'">
            <app-help-facebook-ads-details></app-help-facebook-ads-details>
          </div>
          <div *ngSwitchCase="'facebook-page-insights-checklist'">
            <app-help-facebook-page-insights-checklist></app-help-facebook-page-insights-checklist>
          </div>
          <div *ngSwitchCase="'facebook-page-insights-identity'">
            <app-help-facebook-page-insights-identity></app-help-facebook-page-insights-identity>
          </div>
          <div *ngSwitchCase="'facebook-page-insights-details'">
            <app-help-facebook-page-insights-details></app-help-facebook-page-insights-details>
          </div>
          <div *ngSwitchCase="'google-ads-checklist'">
            <app-help-google-ads-checklist></app-help-google-ads-checklist>
          </div>
          <div *ngSwitchCase="'google-ads-identity'">
            <app-help-google-ads-identity></app-help-google-ads-identity>
          </div>
          <div *ngSwitchCase="'google-ads-customer'">
            <app-help-google-ads-customer></app-help-google-ads-customer>
          </div>
          <div *ngSwitchCase="'google-ads-managed-customer'">
            <app-help-google-ads-managed-customer></app-help-google-ads-managed-customer>
          </div>
          <div *ngSwitchCase="'googles-analytics-checklist'">
            <app-help-googles-analytics-checklist></app-help-googles-analytics-checklist>
          </div>
          <div *ngSwitchCase="'googles-analytics-identity'">
            <app-help-googles-analytics-identity></app-help-googles-analytics-identity>
          </div>
          <div *ngSwitchCase="'googles-analytics-details'">
            <app-help-googles-analytics-details></app-help-googles-analytics-details>
          </div>
          <div *ngSwitchCase="'googles-campaign-manager-checklist'">
            <app-help-googles-campaign-manager-checklist></app-help-googles-campaign-manager-checklist>
          </div>
          <div *ngSwitchCase="'googles-campaign-manager-identity'">
            <app-help-googles-campaign-manager-identity></app-help-googles-campaign-manager-identity>
          </div>
          <div *ngSwitchCase="'googles-campaign-manager-details'">
            <app-help-googles-campaign-manager-details></app-help-googles-campaign-manager-details>
          </div>
          <div *ngSwitchCase="'googles-search-ads-checklist'">
            <app-help-googles-search-ads-checklist></app-help-googles-search-ads-checklist>
          </div>
          <div *ngSwitchCase="'googles-search-ads-identity'">
            <app-help-googles-search-ads-identity></app-help-googles-search-ads-identity>
          </div>
          <div *ngSwitchCase="'googles-search-ads-details'">
            <app-help-googles-search-ads-details></app-help-googles-search-ads-details>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-checklist'">
            <app-help-instagram-business-iq-checklist></app-help-instagram-business-iq-checklist>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-identity'">
            <app-help-instagram-business-iq-identity></app-help-instagram-business-iq-identity>
          </div>
          <div *ngSwitchCase="'instagram-business-iq-details'">
            <app-help-instagram-business-iq-details></app-help-instagram-business-iq-details>
          </div>
          <div *ngSwitchCase="'instagram-stories-checklist'">
            <app-help-instagram-stories-checklist></app-help-instagram-stories-checklist>
          </div>
          <div *ngSwitchCase="'instagram-stories-identity'">
            <app-help-instagram-stories-identity></app-help-instagram-stories-identity>
          </div>
          <div *ngSwitchCase="'instagram-stories-details'">
            <app-help-instagram-stories-details></app-help-instagram-stories-details>
          </div>
          <div *ngSwitchCase="'mws-finances-checklist'">
            <app-help-mws-finances-checklist></app-help-mws-finances-checklist>
          </div>
          <div *ngSwitchCase="'mws-finances-identity'">
            <app-help-mws-finances-identity></app-help-mws-finances-identity>
          </div>
          <div *ngSwitchCase="'mws-realtime-orders-checklist'">
            <app-help-mws-realtime-orders-checklist></app-help-mws-realtime-orders-checklist>
          </div>
          <div *ngSwitchCase="'mws-realtime-orders-identity'">
            <app-help-mws-realtime-orders-identity></app-help-mws-realtime-orders-identity>
          </div>
          <div *ngSwitchCase="'mws-reporting-checklist'">
            <app-help-mws-reporting-checklist></app-help-mws-reporting-checklist>
          </div>
          <div *ngSwitchCase="'mws-reporting-identity'">
            <app-help-mws-reporting-identity></app-help-mws-reporting-identity>
          </div>
          <div *ngSwitchCase="'mws-settlement-reports-checklist'">
            <app-help-mws-settlement-reports-checklist></app-help-mws-settlement-reports-checklist>
          </div>
          <div *ngSwitchCase="'mws-settlement-reports-identity'">
            <app-help-mws-settlement-reports-identity></app-help-mws-settlement-reports-identity>
          </div>
          <div *ngSwitchCase="'postgresql-checklist'">
            <app-help-postgresql-checklist></app-help-postgresql-checklist>
          </div>
          <div *ngSwitchCase="'postgresql-details'">
            <app-help-postgresql-details></app-help-postgresql-details>
          </div>
          <div *ngSwitchCase="'snowflake-checklist'">
            <app-help-snowflake-checklist></app-help-snowflake-checklist>
          </div>
          <div *ngSwitchCase="'snowflake-details'">
            <app-help-snowflake-details></app-help-snowflake-details>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-checklist'">
            <app-help-youtube-channel-insights-checklist></app-help-youtube-channel-insights-checklist>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-identity'">
            <app-help-youtube-channel-insights-identity></app-help-youtube-channel-insights-identity>
          </div>
          <div *ngSwitchCase="'youtube-channel-insights-details'">
            <app-help-youtube-channel-insights-details></app-help-youtube-channel-insights-details>
          </div>
          <div *ngSwitchCase="'youtube-competitor-checklist'">
            <app-help-youtube-competitor-checklist></app-help-youtube-competitor-checklist>
          </div>
          <div *ngSwitchCase="'youtube-competitor-identity'">
            <app-help-youtube-competitor-identity></app-help-youtube-competitor-identity>
          </div>
          <div *ngSwitchCase="'youtube-competitor-details'">
            <app-help-youtube-competitor-details></app-help-youtube-competitor-details>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-checklist'">
            <app-help-youtube-video-insights-checklist></app-help-youtube-video-insights-checklist>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-identity'">
            <app-help-youtube-video-insights-identity></app-help-youtube-video-insights-identity>
          </div>
          <div *ngSwitchCase="'youtube-video-insights-details'">
            <app-help-youtube-video-insights-details></app-help-youtube-video-insights-details>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-checklist'">
            <app-help-youtube-video-iq-checklist></app-help-youtube-video-iq-checklist>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-identity'">
            <app-help-youtube-video-iq-identity></app-help-youtube-video-iq-identity>
          </div>
          <div *ngSwitchCase="'youtube-video-iq-details'">
            <app-help-youtube-video-iq-details></app-help-youtube-video-iq-details>
          </div>
          <div *ngSwitchDefault>
            This is the default text in case someone doesn't pass the helper type.
          </div>
        </div>
      </div>
    </div>
  </div>