export const vendors = {
  amazon: '/assets/img/products/amazon.svg',
  awsAthena: '/assets/img/products/amazon-athena.svg',
  awsRedshift: '/assets/img/products/amazon-redshift.svg',
  awsSpectrum: '/assets/img/products/amazon-redshift-spectrum.svg',
  azureBlobStorage: '/assets/img/products/azure-blob-storage.svg',
  azureDataLake: '/assets/img/products/azure-data-lake.svg',
  googleAnalytics360: '/assets/img/products/google-analytics-360.svg',
  googleBigquery: '/assets/img/products/google-bigquery.svg',
  googleCampaignManager: '/assets/img/products/google-campaign-manager.svg',
  googleCloud: '/assets/img/products/google-cloud.svg',
  googleDisplayVideo360: '/assets/img/products/google-display-video-360.svg',
  googleSearch360: '/assets/img/products/google-search-360.svg',
  googleAds: '/assets/img/products/google-ads.svg',
  google: '/assets/img/products/google.svg',
  facebook: '/assets/img/products/facebook.svg',
  instagram: '/assets/img/products/instagram.svg',
  kinesis: '/assets/img/products/kinesis.svg',
  batch: '/assets/img/products/batch.svg',
  shopify: '/assets/img/products/shopify.svg',
  snowflake: '/assets/img/products/snowflake.svg',
  youtube: '/assets/img/products/youtube.svg'
};