<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-tags"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Key considerations for pricing </p>
<!-- Divider -->
<hr class="mb-4">
<h4 class="mt-2 mb-2"> How plans and pricing work </h4>
<ol>
  <li class="text-muted mb-2">  Pricing plans define the types of service and support we deliver to you. Each plan offers different levels of support and various types of services. For pricing examples, FAQs, and plan details, see <a href="https://docs.openbridge.com/en/articles/5231237-how-pricing-and-billing-work" rel="noopener" target="_blank" class="light-link">How Pricing and Billing Work.</a></li>
   <li class="text-muted mb-2"> Our pricing is based on usage or "pay-as-you-go" for pipelines. All plans leverage pay-as-you-go pricing for data pipelines. Why? Pay-as-you-go allows you to quickly adapt to changing needs and get charged based on actual usage levels. This means we are only charging you for what you use.</li>
  <li class="text-muted mb-2"> Our pay-as-you-go pricing offers on-demand rates per pipeline, per day. This means you are charged each month according to your selected plan and the number of daily active data pipelines.</li>

  <li class="text-muted mb-2">
  We offer a 14-day, no credit card required, free trial. You will not be asked for a credit card before you start your trial, nor will you be charged during the trial. There will not be any charges unless you decide to order the service at the end of your trial. You will have access to either standard and premium sources or destinations for the duration of the trial period. </li>
  <li class="text-muted mb-2">
  If you do not upgrade at the end of the trial, any active pipelines and services will be turned off.</li>

</ol>