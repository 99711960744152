import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IdentityTypeService } from '../services/identity-type.service';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({
  name: 'logoPathFromIdentityTypeId'
}
)
@Injectable({
  providedIn: 'root'
})
export class LogoPathFromIdentityTypeIdPipe implements PipeTransform {

  constructor(
    private identityTypeService: IdentityTypeService
  ) { }

  transform(value: number): string {
    const identityType = this.identityTypeService.findIdentityTypeFromId(value);

    if (identityType) {
      return identityType.logoPath;
    }
    return '';
  }
}