<div class="row" [formGroup]="parentForm">
  <div class="col-auto align-self-start">
    <!-- Avatar -->
    <div class="avatar avatar-lg">
      <img [src]="cardData.logoUri" [alt]="cardData.logoAltText" [title]="cardData.logoAltText"
        class="avatar-img rounded">
    </div>
  </div>
  <div class="col align-self-center ms-n2">
    <!-- Title -->
    <h4 class="mb-1 name text-white">
      {{ cardData.title }}
      <!-- <span *ngIf="cardData.new" class="badge bg-success-soft ms-2">New</span> -->
    </h4>

    <ng-container *ngFor="let metaData of cardData.meta; index as metaIdx;">
      <!-- Text -->
      <p class="card-text small text-muted mb-1"> {{ metaData.name }}: {{ metaData.value }} </p>
    </ng-container>
  </div>
  <div class="col-auto align-self-center">
    <input type="radio" [attr.name]="controlName" style="display: none;" id="{{ cardData.id }}"
      value="{{ cardData.id }}" [formControlName]="controlName">
    <span class="mt-3 me-4 avatar-title rounded text-success h1 ng-tns-c100-0 radio-selected"><span
        class="fe fe-check ng-tns-c100-0"></span></span>

    <label for="{{ cardData.id }}" class="btn btn-sm btn-white d-none d-md-inline-block" id="radioBtn">
      Select
    </label>
  </div>
  <div class="col-auto align-self-center">
    <!-- Dropdown -->
    <p class="card-text small text-white">
      <span class="text-success">●</span> Active
    </p>
  </div>
</div> <!-- / .row -->