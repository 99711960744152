import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { secondSinceEpoch } from './core/functions/time';
import { authUserLogin, authUserLogout } from './core/store/actions/auth.actions';
import { AppState } from './core/store/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  loading = false;

  title = 'console-app';

  constructor(
    private auth: AuthService,
    private route: Router,
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
  }
}
