<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fas fa-file-check"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4"> Overview of Healthchecks </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Key considerations for Healthchecks </h4>
<ol>

  <li class="text-muted mb-2">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi dignissimos aut iste hic illo est excepturi
    distinctio consequuntur nisi, ipsam necessitatibus dicta qui expedita modi repellendus praesentium incidunt
    consequatur a.
  </li>

  <li class="text-muted mb-2">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo et nemo, adipisci, reiciendis pariatur molestiae
    fugit beatae tenetur facilis molestias eligendi. Fuga qui consectetur modi quod non deserunt cupiditate facere.
    <a href="#" rel="noopener" target="_blank" class="light-link"> Link </a>
  </li>

  <li class="text-muted mb-2">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo et nemo, adipisci, reiciendis pariatur molestiae
    fugit beatae tenetur facilis molestias eligendi. Fuga qui consectetur modi quod non deserunt cupiditate facere.
    <code> code </code>
  </li>

</ol>