<div class="row align-items-center">
  <div class="col">
    <!-- Nav -->
    <ul class="nav nav-tabs nav-overflow header-tabs">
      <li class="nav-item">
        <a href (click)="setFilter('all')" class="nav-link text-nowrap" id="all"
          [ngClass]="{'active': activeFilterType == 'all' }"> 
          All <span class="badge rounded-pill bg-secondary-soft" id="allIntegrationCount">{{ allIntegrationCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('standard')" class="nav-link text-nowrap" id="standard"
          [ngClass]="{'active': activeFilterType == 'standard' }"> 
          Standard <span class="badge rounded-pill bg-secondary-soft" id="allStandardCount">{{ allStandardCount }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href (click)="setFilter('premium')" class="nav-link text-nowrap" id="premium"
          [ngClass]="{'active': activeFilterType == 'premium'}">
          Premium <span class="badge rounded-pill bg-secondary-soft" id="allPremiumCount">{{ allPremiumCount }}</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a href="#!" class="nav-link text-nowrap"> Enterprise <span
                class="badge rounded-pill bg-secondary-soft">29</span>
        </a>
      </li> -->
    </ul>
  </div>
</div>