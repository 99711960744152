import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-nav-identities',
  templateUrl: './filter-nav-identities.component.html'
})
export class FilterNavIdentitiesComponent implements OnInit, OnChanges {
  @Input() identities: any[] = [];
  @Output() filteredIdentities = new EventEmitter<any[]>();

  activeFilterType: 'all' | 'source' | 'destination' = 'all';
  allIdentitiesCount: number;
  allSourcesCount: number;
  allDestinationsCount: number;
  storageIdentityTypes: number[] = [10, 12];

  constructor() { }

  ngOnInit(): void {
    this.allIdentitiesCount = this.identities.length;
    this.allSourcesCount = this.countIdentitiesByType('source');
    this.allDestinationsCount = this.countIdentitiesByType('destination');
    this.setFilter('all');
  }

  ngOnChanges(): void {
    this.allIdentitiesCount = this.identities.length;
    this.allSourcesCount = this.countIdentitiesByType('source');
    this.allDestinationsCount = this.countIdentitiesByType('destination');
    this.setFilter('all');
  }

  countIdentitiesByType(filterType: 'source' | 'destination' | 'enterprise'): number {
    let count = 0;
    this.identities.forEach((val, idx) => {
      if (filterType === 'source' && !this.storageIdentityTypes.includes(val.remote_identity_type_id)) {
        count++;
      }
      if (filterType === 'destination' && this.storageIdentityTypes.includes(val.remote_identity_type_id)) {
        count++;
      }
    });
    return count;
  }

  setFilter(filterType: 'all' | 'source' | 'destination'): boolean {

    this.activeFilterType = filterType;
    let filteredIdentities: any[] = [];

    if (filterType === 'all') {
      filteredIdentities = [...this.identities];
    }
    else {
      this.identities.forEach((val, idx) => {
        if (filterType === 'source' && !this.storageIdentityTypes.includes(val.remote_identity_type_id)) {
          filteredIdentities.push(val);
        }
        if (filterType === 'destination' && this.storageIdentityTypes.includes(val.remote_identity_type_id)) {
          filteredIdentities.push(val);
        }
      });
    }

    this.filteredIdentities.emit(filteredIdentities);

    // Return false to prevent default.
    return false;
  }
}
