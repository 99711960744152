<ng-container *ngIf="notifications.length > 0">
  <ng-container *ngFor="let notification of notifications">
    <div class="alert {{ notification.class }}" role="alert">
      <i class="fa-duotone {{ notification.class | iconForNotification }} fa-fade pe-1"
        style="--fa-animation-duration: 500ms; --fa-animation-iteration-count: 12;"></i> {{ notification.message }}
      <a class="cursor-pointer linkDanger" *ngIf="notification.details != ''"
        (click)="displayDetails(notification.details)">View
        Error Details</a>
    </div>
  </ng-container>
</ng-container>