import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, range } from 'rxjs';
import { map, filter, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-filter-server-side-pagination',
  templateUrl: './filter-server-side-pagination.component.html',
  styleUrls: ['./filter-server-side-pagination.component.scss']
})
export class FilterServerSidePaginationComponent implements OnInit {

  @Input() currentPageNumber;
  @Input() totalPageCount;
  @Input() range = 3;

  @Output() pageChange: EventEmitter<any>;
  currentPage: number;
  totalPages: number;
  pages: Observable<number[]>;

  constructor() {
    this.pageChange = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.getPages(this.currentPageNumber, this.totalPageCount);
  }

  ngOnChanges(): void {
    this.getPages(this.currentPageNumber, this.totalPageCount);
  }

  getPages(currentPage: number, totalPages: number): void {
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.pages = range(-this.range, this.range * 2 + 1)
      .pipe(
        map(offset => this.currentPage + offset),
        filter(page => this.isValidPageNumber(page, this.totalPages)),
        toArray()
      );
  }

  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }

  // selectPage(page: number, event: any): void {
  //   event.preventDefault();
  //   if (this.isValidPageNumber(page, this.totalPages)) {
  //     // this.pageChange.emit((page - 1) * this.limit);
  //   }
  // }

  cancelEvent(event): void {
    event.preventDefault();
  }

  onNext(): boolean {
    if (this.currentPage === this.totalPages) {
      return false;
    }
    else {
      this.setPage(this.currentPage + 1);
    }
  }

  onLast(): boolean {
    if (this.currentPage === this.totalPages) {
      return false;
    }
    else {
      this.setPage(this.totalPages);
    }
  }

  onPrevious(): boolean {
    if (this.currentPage === 1) {
      return false;
    }
    else {
      this.setPage(this.currentPage - 1);
    }
  }

  onFirst(): boolean {
    if (this.currentPage === 1) {
      return false;
    }
    else {
      this.setPage(1);
    }
  }

  setPage(pageNo): void {
    this.currentPage = pageNo;
    this.pageChange.emit(pageNo);
    // this.rendered.setProperty(this.el.nativeElement, 'value', this.pageNo);
    // this.onChangeEventEmitter.emit(this.pageNo);
  }

}
