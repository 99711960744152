import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdAmazonAdvertising, productIdAmazonAttribution,
  productIdAmazonDsp
} from '../product-ids';

export const amzAdsProducts: Integration[] = [
  {
    productId: productIdAmazonAdvertising,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Advertising',
    type: 'source',
    sortOrder: 1201,
    tagLine: 'Amazon Sponsored Brands, Products, Video, and Display insights reporting ',
    routerLink: '/wizards/amazon-advertising',
    logoPath: vendors.amazon,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 14,
    docs: {
      text: 'openbridge/amazon-advertising',
      uri: 'https://docs.openbridge.com/en/?q=amazon+advertising'
    },
    vendor: {
      text: 'ads.amazon.com/reporting',
      uri: 'https://advertising.amazon.com/API/docs/en-us/'
    },
    history: {
      startDateDeltaInDays: -1,
      endDateDeltaInDays: -59
    },
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmazonAttribution,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Attribution',
    type: 'source',
    sortOrder: 100,
    tagLine: 'Google Ads, Facebook, Microsoft Ads...driving purchase activity on Amazon',
    routerLink: '/wizards/amazon-attribution',
    logoPath: vendors.amazon,
    frequency: 'Daily',
    standardIntegration: false,
    premiumIntegration: true,
    remoteIdentityTypeId: 14,
    docs: {
      text: 'openbridge/amazon-attribution',
      uri: 'https://docs.openbridge.com/en/?q=amazon+advertising'
    },
    vendor: {
      text: 'ads.amazon.com/attribution',
      uri: 'https://advertising.amazon.com/API/docs/en-us/amazon-attribution/overview'
    },
    history: {
      startDateDeltaInDays: -1,
      endDateDeltaInDays: -89
    },
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmazonDsp,
    enabled: true,
    comingSoon: false,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'DSP',
    type: 'source',
    sortOrder: 200,
    keywords: [
      'amazon'
    ],
    tagLine: 'Programmatic advertising performance data for audiences on and off Amazon',
    routerLink: '/wizards/amazon-dsp',
    logoPath: vendors.amazon,
    frequency: 'Daily',
    standardIntegration: false,
    premiumIntegration: true,
    remoteIdentityTypeId: 14,
    docs: {
      text: 'openbridge/amazon-dsp',
      uri: 'https://docs.openbridge.com/en/?q=amazon+dsp'
    },
    vendor: {
      text: 'ads.amazon.com/dsp',
      uri: 'https://advertising.amazon.com/API/docs/en-us/dsp/overview'
    },
    history: {
      startDateDeltaInDays: -1,
      endDateDeltaInDays: -89
    },
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  }
];