import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { AuthState } from 'src/app/core/store/reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const authenticated = createSelector(
    selectAuthState,
    auth => (auth.profile != null)
);

// export const profile = createSelector(
// )