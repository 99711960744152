import { Injectable } from '@angular/core';
import { setCache, getCache, generateKeyFromObject, removeCache } from 'src/app/core/functions/cache';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  setCache(type: string, key: string, value: any): void {
    setCache(type, key, value);
  }

  getCache(type: string, key: string): any {
    return getCache(type, key);
  }

  removeCache(type: string, key: string): any {
    return removeCache(type, key);
  }

  generateKeyFromObject(params: object): string {
    return generateKeyFromObject(params);
  }

}
