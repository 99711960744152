import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IdentityService } from 'src/app/core/services/identity.service';
import { AppState } from 'src/app/core/store/reducers';
import { IdentityTypeNameFromIdentityTypeIdPipe } from 'src/app/shared/pipes/IdentityTypeNameFromIdentityTypeId.pipe';
import { LogoPathFromIdentityTypeIdPipe } from 'src/app/shared/pipes/LogoPathFromIdentityTypeId.pipe';

@Component({
  selector: 'app-form-list-identity',
  templateUrl: './form-list-identity.component.html',
  providers: [LogoPathFromIdentityTypeIdPipe, IdentityTypeNameFromIdentityTypeIdPipe]
})
export class FormListIdentityComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() remoteIdentityTypeId: number;
  @Input() notifierType: string = null;

  // notifierType = "Google Service Account"

  cardData: any[];
  filteredCardData: {}[];

  constructor(
    private identityService: IdentityService,
    private changeDetector: ChangeDetectorRef,
    private logoPathFromIdentityTypeIdPipe: LogoPathFromIdentityTypeIdPipe,
    private identityTypeNameFromIdentityTypeIdPipe: IdentityTypeNameFromIdentityTypeIdPipe,
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.cardData = [];
    this.filteredCardData = [];

    this.identityService.identities$.subscribe(response => {

      // Clear card data before rebuild.
      this.cardData = [];

      setTimeout(() => {
        const identities = response.filter(i => i.remoteIdentityTypeId === this.remoteIdentityTypeId);

        // Make sure the identity has the right logo and logo text
        identities.forEach(identity => {
          identity.logoUri = this.logoPathFromIdentityTypeIdPipe.transform(identity.remoteIdentityTypeId);
          identity.logoAltText = this.identityTypeNameFromIdentityTypeIdPipe.transform(identity.remoteIdentityTypeId);
          this.cardData.push(identity);
        });

        // To prevent "Expression has changed after it was checked errors"
        this.changeDetector.detectChanges();
      }, 0);
    });

    this.store$.select('identities').subscribe(state => {
      this.identityService.getIdentitiesByRemoteIdentityType(this.remoteIdentityTypeId);
    });

  }

  updateFilteredData(data): void {
    this.filteredCardData = data;
  }

}
