<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/amazon-athena.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-managed data pipeline into Amazon Athena </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Automated Amazon Athena Configuration </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Amazon Web Services or AWS account. </li>
  <li class="text-muted mb-2"> If you have not done so, our automated template will configure AWS for you. Simply follow
    the <a
      href="https://docs.openbridge.com/en/articles/2579879-how-to-use-cloudformation-to-configure-amazon-s3-iam-and-amazon-athena"
      rel="noopener" target="_blank">AWS
      Athena Data Lake CloudFormation</a> setup guide. </li>
  <li class="text-muted mb-2">The AWS Athena Region should be the same region you setup your AWS S3 bucket. For example,
    if your AWS S3 Bucket was configured in region <code>us-east-1</code> then set your AWS Athena Region to
    <code>us-east-1</code>
  </li>
  <li class="text-muted mb-2">The AWS Athena Database name should be descriptive of the data being stored. For example,
    you can use <code>openbridge_database</code> or <code>my_client_customer_database</code></li>
  <li class="text-muted mb-2"> Make sure you have you taken note of the <code>SecretKeyForIAMUser</code>,
    <code>AccessKeyForIAMUser</code>, and <code>BucketName</code> in the AWS interface. You will need to enter this
    information in this step. These items are shown under <code>Outputs</code> located here <code>CloudFormation ->
      Stacks -> "Your Stack Name"</code>
  </li>
  <li class="text-muted mb-2">Insufficient permission, incorrect names, or invalid settings provided during set up will
    cause the connection to AWS Athena and your data lake to fail</li>

</ol>