export const responseSharedRemoteIdentities = {
  links: {
    first: 'https://remote-identity.api.dev.openbridge.io/sri?page=1',
    last: 'https://remote-identity.api.dev.openbridge.io/sri?page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'RemoteIdentity',
      id: '110',
      attributes: {
        name: 'bigquery-working-test@openbridge-bigquery.iam.gserviceaccount.com',
        created_at: '2021-12-01T15:20:53.403180',
        modified_at: '2021-12-01T15:20:53.403202',
        identity_hash: '1207c302-e6aa-40d0-a14f-4f6d14241f49',
        remote_unique_id: 'f22bda528ae3f0d664d2153450b835c7ee29e0f4',
        account_id: 410,
        user_id: 377,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '12'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '410'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '377'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: null
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '22',
      attributes: {
        name: 'Openbridge Management',
        created_at: '2021-10-15T18:49:09.839933',
        modified_at: '2021-10-15T18:49:09.839946',
        identity_hash: '4cee22043dacd56cf8dbcf3eaed1ca44',
        remote_unique_id: 'amzn1.account.AH4XMYTXT4TG5BZDYCBJMMUMPDOQ',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '111',
      attributes: {
        name: 'KDP Identity',
        created_at: '2021-11-20T14:12:37.403180',
        modified_at: '2021-11-20T14:12:37.403180',
        identity_hash: '58649dc6685e87c97855997e04e2bcb9',
        remote_unique_id: 'amzn1.account.AF6OMLVZGHS2DTYHHWPRW7WJQW4Q',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '112',
      attributes: {
        name: 'Attribution Identity',
        created_at: '2021-11-20T14:12:37.403180',
        modified_at: '2021-11-20T14:12:37.403180',
        identity_hash: '390883e60dc4627e0fb5900eba38c1f5',
        remote_unique_id: 'amzn1.account.AF4UMXCQOTHFNRWXEXYHXDVFKGVQ',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '134',
      attributes: {
        name: 'A2ZLIS6STAFJ79',
        created_at: '2022-01-24T16:52:34.037409',
        modified_at: '2022-01-24T16:52:34.037424',
        identity_hash: 'de98b6270e653ad701a908c34fc85186',
        remote_unique_id: 'A2ZLIS6STAFJ79',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'DE'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '17'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '20',
      attributes: {
        name: 'James Andrews',
        created_at: '2021-10-15T13:53:16.863982',
        modified_at: '2021-11-01T14:09:31.080971',
        identity_hash: '9f94cb567b7fe71b22daef27d330b79f',
        remote_unique_id: 'amzn1.account.AHVI3O7GJNOOG6NBP5G4YACQAWAA',
        account_id: 341,
        user_id: 308,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'na'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '14'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '341'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '308'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '16'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '135',
      attributes: {
        name: 'A3OMVOFWPNPB8E',
        created_at: '2022-01-24T17:37:09.724779',
        modified_at: '2022-01-24T17:37:09.724794',
        identity_hash: 'a113e0f055f4e16b3ed3c43f005f4a3c',
        remote_unique_id: 'A3OMVOFWPNPB8E',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'MX'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '17'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '168',
      attributes: {
        name: 'A1AU2416LNDBIH',
        created_at: '2022-01-26T16:20:22.343916',
        modified_at: '2022-01-26T16:20:22.343930',
        identity_hash: '3324c84c6591f011facd64f624c368fb',
        remote_unique_id: 'A1AU2416LNDBIH',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'US'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '17'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '22'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '175',
      attributes: {
        name: 'Devin Roberts',
        created_at: '2022-02-08T19:46:48.925791',
        modified_at: '2022-02-08T19:46:48.925806',
        identity_hash: '0d8385780d42b85b87135aa096693f26',
        remote_unique_id: '108520703260214390962',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '8'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '11'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '178',
      attributes: {
        name: 'Hasbro',
        created_at: '2022-02-09T17:30:27.095221',
        modified_at: '2022-02-09T17:30:27.095235',
        identity_hash: 'd5d7b6d2f1785e20ebb8771b8c5749be',
        remote_unique_id: 'asdf9823uj23hydasdf9238',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '8'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '11'
          }
        }
      }
    },
    {
      type: 'RemoteIdentity',
      id: '179',
      attributes: {
        name: 'James Andrews',
        created_at: '2022-02-15T09:29:45.321546',
        modified_at: '2022-02-15T09:29:45.321560',
        identity_hash: 'b5587098369950248e114b1ae68ad05a',
        remote_unique_id: '101878852755606711695',
        account_id: 342,
        user_id: 309,
        notified_at: null,
        invalidate_manually: 0,
        invalid_identity: 0,
        invalidated_at: null,
        notification_counter: 0,
        region: 'global'
      },
      relationships: {
        remote_identity_type: {
          data: {
            type: 'RemoteIdentityType',
            id: '8'
          }
        },
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        },
        trusted_identity: {
          data: null
        },
        remote_identity_type_application: {
          data: {
            type: 'RemoteIdentityTypeApplication',
            id: '11'
          }
        }
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 11
    }
  }
};