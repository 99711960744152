import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiStateService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // Define Oauth state and call createState()
  createOauthState(accountId: number,
                   userId: number,
                   remoteIdentityTypeId: number,
                   returnUrl: string,
                   region?: string,
                   identityId?: string,
                   betaVersion?: boolean): Promise<HttpResponse<object>> {
    try {
      const oauthState: OauthState = {
        account_id: accountId,
        user_id: userId,
        remote_identity_type_id: remoteIdentityTypeId,
        return_url: returnUrl,
      };

      if (betaVersion) {
        oauthState['version'] = 'beta';
      }

      if (region) {
        oauthState['region'] = region;
      }

      if (identityId) {
        oauthState['identityId'] = +identityId;
      }

      // Create
      return this.createState(oauthState);

    }
    catch (error) {
      throw error;
    }
  }

  // Call the state api to create state.
  private async createState(stateDetails: any): Promise<HttpResponse<object>> {
    try {
      const payload: State = {
        data: {
          type: 'ClientState',
          attributes: {
            state: stateDetails
          }
        }
      };

      // Call and return the createState function
      return await this.httpClient.post(environment.openbridgeApiUris.state, payload, { observe: 'response' }).toPromise();
    }
    catch (error) {
      throw error;
    }
  }

}

// Define the state interface
interface State {
  data: {
    type: string;
    attributes: {
      state: string;
    }
  };
}

// Define the Oauth State interface
 // regions 'global' | 'na' | 'eu' | 'fe' ;
interface OauthState {
  account_id: number;
  user_id: number;
  remote_identity_type_id: number;
  return_url: string;
  region?: string; // 'global' | 'na' | 'eu' | 'fe' ;
  identityId?: number;
  customer_oauth_app_id?: number;
}
