import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { FlashNotification } from '../../models/flash-notification.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalErrorDetailsComponent } from 'src/app/shared/components/modals/modal-error-details/modal-error-details.component';

@Component({
  selector: 'app-flash-notifications',
  templateUrl: './flash-notifications.component.html'
})
export class FlashNotificationsComponent implements OnInit {
  bsModalRef: BsModalRef;
  notifications: FlashNotification[] = [];

  constructor(
    protected modalService: BsModalService,
    protected store$: Store<AppState>
  ) { }

  ngOnInit(): void {
    // Subscribe to the wizard slice to watch for stage changes.
    this.store$.select('flashNotifications').subscribe((response) => {
      this.notifications = response.notifications;
    });
  }

  displayDetails(details: string): void {
    const initialState = {
      details: details
    };
    this.bsModalRef = this.modalService.show(ModalErrorDetailsComponent, {initialState});
  }

}
