import { Injectable } from '@angular/core';
import { sources as integrations } from '../constants/integrations/integrations';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor() { }

  findIntegrationFromId(productId: number) {
    let result: any = null;

    result = integrations.filter((source) => {
      return source.productId === productId;
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }

  findIntegrationFromPath(path: string) {
    let result: any = null;

    result = integrations.filter((source) => {
      return source.routerLink === path;
    });

    if (result.length === 1) {
      return result[0];
    }
    return false;
  }

  getSources() {
    return this.getIntegrationsByType('source');
  }

  getDestinations() {
    return this.getIntegrationsByType('destination');
  }

  private getIntegrationsByType(type: 'source' | 'destination') {
    let integrationList = integrations.filter((source) => {
      return source.type === type;
    });
    integrationList = integrationList.sort((a, b) => b.sortOrder - b.sortOrder);
    return integrationList;
  }

}
