export const responseAmazonAttributionProfiles = {
  data: [
    {
      id: 2116350962252689,
      type: 'AmazonAdvertisingProfile',
      attributes: {
        country_code: 'US',
        currency_code: 'USD',
        daily_budget: '',
        timezone: 'America/Los_Angeles',
        account_info: {
          id: 'ENTITY27U3P80PXY90D',
          type: 'AmazonAdvertisingProfileAccountInfo',
          attributes: {
            marketplace_country: 'US',
            marketplace_string_id: 'ATVPDKIKX0DER',
            name: 'Attribution: Fullstar Houseware (US)',
            type: 'agency',
            subType: 'AMAZON_ATTRIBUTION',
            valid_payment_method: ''
          }
        }
      }
    }
  ]
};