import { Component, Input, OnInit } from '@angular/core';
import { Integration } from 'src/app/shared/models/integration.model';

@Component({
  selector: 'app-help-amazon-spapi-checklist',
  templateUrl: './help-amazon-spapi-checklist.component.html'
})
export class HelpAmazonSpapiChecklistComponent implements OnInit {
  @Input() integration: Integration;

  constructor() { }

  ngOnInit(): void {
  }

}
