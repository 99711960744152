import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-google-analytics-checklist',
  templateUrl: './help-google-analytics-checklist.component.html'
})
export class HelpGoogleAnalyticsChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
