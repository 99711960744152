import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseWizardService } from '../../base-wizard.service';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { identityIdGoogleBigquery } from 'src/app/shared/constants/identity-ids';
import { v4 as uuidv4 } from 'uuid';
import { Md5 } from 'ts-md5/dist/md5';
import { wizardIntegrationChecklist, wizardIntegrationConfig,
         wizardIntegrationStagelessConfig, wizardIntegrationSave } from 'src/app/core/store/actions/wizard.actions';
import { errorWizardGeneralError, errorWizardIdentityExists } from 'src/app/shared/constants/flash-notifications';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/reducers';
import { identityCreate } from 'src/app/core/store/actions/identities.actions';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ConfigGoogleBigquery } from 'src/app/shared/models/product-configuration.types';

@Injectable({
  providedIn: 'root'
})
export class GoogleBigqueryService extends BaseWizardService {
  modalProgress$: Subject<any> = new Subject();
  identityState$: any;
  baseConfigState: ConfigGoogleBigquery = {
    integrationName: null,
    remoteIdentityId: null,
    serviceAccountJson: null,
    projectId: null,
    datasetId: null
  };

  constructor(
    protected httpClient: HttpClient,
    protected store$: Store<AppState>,
    protected router: Router,
    protected encryptService: EncryptionService,
    protected identityService: IdentityService
  ) {
    super(httpClient, store$);
    this.stages = [
      'checklist',
      'identity',
      'details',
      'integrationName',
      'save'
    ];
  }

  doProcessCheckList(): void {

    // Set the next stage index
    this.nextStageIndex();
    const nextStage =  this.stages[this.stageIndex];

    // Dispatch
    this.store$.dispatch(wizardIntegrationChecklist({
      checklist: true,
      stage: nextStage
    }));
  }

  // Fix the type later...
  doProcessConfig(config: any): void {

    if (this.stages[(this.stageIndex + 1)] === 'save') {
      // Dispatch
      this.store$.dispatch(wizardIntegrationStagelessConfig({
        config: config
      }));

      this.store$.dispatch(wizardIntegrationSave());
      return;
    }

    this.nextStageIndex();
    const nextStage =  this.stages[this.stageIndex];

    // Dispatch save integration.
    this.store$.dispatch(wizardIntegrationConfig({
      config: config,
      stage: nextStage
    }));

  }

  async getProfilesAndDatasetsFromIdentity(identityId: string): Promise<void> {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      this.stageDataRequestState$.next({state: 'data-load-start', payload: null});
      if (environment.enableMock) {
        await delay(2000);
      }
      // make payload request.
      if (environment.enableMock) {
        await delay(2000);
      }
      this.stageDataRequestState$.next({state: 'data-load-complete', payload: null});
    }
    catch (error) {
      this.stageDataRequestState$.next({state: 'data-load-complete', payload: error});
    }
  }

  async createBigQueryIdentityFromServiceAccount(serviceAccountJsonString: string): Promise<void> {

    const md5 = new Md5();
    const authState = await this.store$.select('auth').pipe(take(1)).toPromise();
    const delay = ms => new Promise(res => setTimeout(res, ms));

    this.modalProgress$.next('remote-identity-sa-start');
    if (environment.enableMock) {
      await delay(2000);
    }

    const serviceAccountJSON = JSON.parse(serviceAccountJsonString);

    if (!this.identityService.identityExists(identityIdGoogleBigquery, serviceAccountJSON['private_key_id'])){
      try {

        this.modalProgress$.next('remote-identity-sa-validation');
        if (environment.enableMock) {
          await delay(2000);
        }

        const serviceAccountResponse = await this.getProfilesAndDatasetsFromServiceAccount(serviceAccountJsonString);

        this.modalProgress$.next('remote-identity-sa-encryption');
        const encodedServiceAccount = await this.encodeServiceAccount(serviceAccountJsonString);

        this.modalProgress$.next('remote-identity-saving-identity');

        const remoteIdentityPayload: PayloadRemoteIdentity = {
          data: {
            type: 'RemoteIdentity',
            attributes: {
              remote_identity_type: identityIdGoogleBigquery,
              name: serviceAccountJSON['client_email'],
              account: +authState.currentAccount.accountId,
              user: +authState.currentAccount.userId,
              identity_hash: uuidv4(),
              remote_unique_id: serviceAccountJSON['private_key_id'],
              remote_identity_meta_attributes: [
                {
                  remote_identity_type_meta_key: 10,
                  meta_value: encodedServiceAccount['data']['attributes']['serviceAccount'],
                  meta_format: 'ENCRYPTED_STRING'
                }
              ],
              region: 'global'
            }
          }
        };

        const identityResponse =  await this.httpClient.post(
          environment.openbridgeApiUris.identities + '/ri', remoteIdentityPayload).toPromise();

        const createPayload = {
          id: identityResponse['data'].id,
          identityHash: identityResponse['data'].attributes.identity_hash,
          name: identityResponse['data'].attributes.name,
          userId: identityResponse['data'].attributes.user_id,
          firstName: identityResponse['data'].relationships.user.first_name,
          lastName: identityResponse['data'].relationships.user.last_name,
          remoteIdentityTypeId: +identityResponse['data'].relationships.remote_identity_type.data.id,
          invalidIdentity: identityResponse['data'].attributes.invalid_identity,
          createdAt: identityResponse['data'].attributes.created_at,
          modifiedAt: identityResponse['data'].attributes.modified_at
        };

        this.store$.dispatch(identityCreate(createPayload));

        // make payload request.
        if (environment.enableMock) {
          await delay(2000);
        }
        this.modalProgress$.next('remote-identity-create-complete');
      }
      catch (error) {
        this.doProcessErrorWithDetails(errorWizardGeneralError, JSON.stringify(error.error));
        this.modalProgress$.next('remote-identity-create-error');
      }
    }
    else {
      this.doProcessError(errorWizardIdentityExists);
      this.modalProgress$.next('remote-identity-create-error');
    }
  }

  async getProfilesAndDatasetsFromServiceAccount(serviceAccountJsonString: string): Promise<any> {
    const encodedServiceAccountJsonString = window.btoa(serviceAccountJsonString);
    return await this.httpClient.get(environment.openbridgeApiUris.service + '/service/bq/validate-acct/' +
      encodedServiceAccountJsonString).toPromise();
  }

  async encodeServiceAccount(serviceAccountJsonString: string): Promise<any> {
    const encodedResponse = await this.encryptService.encryptStringList({serviceAccount: serviceAccountJsonString});
    return encodedResponse;
  }

  initializeConfigState(wizardMode: string, subscription: any, spm: any): any {
    super.initializeConfigState(wizardMode, subscription, spm);

    if (typeof subscription === 'undefined' && !spm !== null) {
      return {
        ...this.baseConfigState,
        wizardMode: wizardMode,
        subscriptionId: null
      };
    }

    const spmInfo = this.buildObjectFromSpmDataArray(spm.data);

    const configState = {
      ...this.baseConfigState,
      wizardMode: wizardMode,
      subscriptionId: subscription.id,
      remoteIdentityId: spmInfo.remote_identity_id,
      serviceAccountJson: '',
      projectId: spmInfo.project_id,
      datasetId: spmInfo.dataset_id
    };

    return configState;
  }
}


interface PayloadRemoteIdentity {
  data: {
    type: string;
    attributes: {
      remote_identity_type: number;
      name: string;
      account: number;
      user: number;
      identity_hash: string;
      remote_unique_id: string;
      remote_identity_meta_attributes: PayloadRemoteIdentityMeta[];
      region: string;
    }
  };
}

interface PayloadRemoteIdentityMeta {
  remote_identity_type_meta_key: number;
  meta_value: string;
  meta_format: string;
}
