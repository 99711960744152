import { environment } from '../../environments/environment';

export const responseCustomerDetails = {
  data: {
    id: 'cus_KPgiun9YhOBtyE',
    object: 'customer',
    account_balance: 0,
    address: null,
    balance: 0,
    created: 1634307911,
    currency: 'usd',
    default_source: 'src_1K1C8gDJfvhvVHhnUbIKRmlS',
    delinquent: false,
    description: null,
    discount: null,
    email: 'thomas+2021101501@openbridge.com',
    invoice_prefix: '1C81DC90',
    invoice_settings: {
      custom_fields: null,
      default_payment_method: null,
      footer: null
    },
    livemode: false,
    metadata: {},
    name: null,
    next_invoice_sequence: 79,
    phone: null,
    preferred_locales: [],
    shipping: null,
    sources: {
      object: 'list',
      data: [
        {
          id: 'src_1K1C8gDJfvhvVHhnUbIKRmlS',
          object: 'source',
          ach_credit_transfer: {
            account_number: 'test_9a6107aa8194',
            routing_number: '110000000',
            fingerprint: 'y0IeSEjfRgXeb9Sy',
            swift_code: 'TSTEZ122',
            bank_name: 'TEST BANK',
            refund_routing_number: null,
            refund_account_holder_type: null,
            refund_account_holder_name: null
          },
          amount: null,
          client_secret: 'src_client_secret_Fsutbf6EffgSoYkpGovPeLaB',
          created: 1638201094,
          currency: 'usd',
          customer: 'cus_KPgiun9YhOBtyE',
          flow: 'receiver',
          livemode: false,
          metadata: {},
          owner: {
            address: null,
            email: 'amount_0@stripe.com',
            name: null,
            phone: null,
            verified_address: null,
            verified_email: null,
            verified_name: null,
            verified_phone: null
          },
          receiver: {
            address: '110000000-test_9a6107aa8194',
            amount_charged: 0,
            amount_received: 0,
            amount_returned: 0,
            refund_attributes_method: 'email',
            refund_attributes_status: 'missing'
          },
          statement_descriptor: null,
          status: 'pending',
          type: 'ach_credit_transfer',
          usage: 'reusable'
        }
      ],
      has_more: false,
      total_count: 1,
      url: '/v1/customers/cus_KPgiun9YhOBtyE/sources'
    },
    subscriptions: {
      object: 'list',
      data: [
        {
          id: 'sub_1KUAvsDJfvhvVHhnFUzhCgg5',
          object: 'subscription',
          application_fee_percent: null,
          automatic_tax: {
            enabled: false
          },
          billing: 'charge_automatically',
          billing_cycle_anchor: 1645107967,
          billing_thresholds: null,
          cancel_at: null,
          cancel_at_period_end: false,
          canceled_at: null,
          collection_method: 'charge_automatically',
          created: 1645107967,
          current_period_end: 1647527167,
          current_period_start: 1645107967,
          customer: 'cus_KPgiun9YhOBtyE',
          days_until_due: null,
          default_payment_method: 'pm_1K44n2DJfvhvVHhnTejQpq57',
          default_source: null,
          discount: null,
          ended_at: null,
          invoice_customer_balance_settings: {
            consume_applied_balance_on_void: true
          },
          items: {
            object: 'list',
            data: [
              {
                id: 'si_LAVxDgLjPmkS6J',
                object: 'subscription_item',
                billing_thresholds: null,
                created: 1645107968,
                metadata: {},
                plan: {
                  id: environment.stripe.prices.premiumPriceId,
                  object: 'plan',
                  active: true,
                  aggregate_usage: 'sum',
                  amount: null,
                  amount_decimal: null,
                  billing_scheme: 'tiered',
                  created: 1645105991,
                  currency: 'usd',
                  interval: 'month',
                  interval_count: 1,
                  livemode: false,
                  metadata: {},
                  name: 'Premium Plan',
                  nickname: null,
                  product: 'prod_LAVQ7Vd5V1SKfL',
                  statement_descriptor: null,
                  tiers: [
                    {
                      amount: 149,
                      flat_amount: 14900,
                      flat_amount_decimal: '14900',
                      unit_amount_decimal: '149',
                      up_to: 30
                    },
                    {
                      amount: 69,
                      flat_amount: null,
                      flat_amount_decimal: null,
                      unit_amount_decimal: '69',
                      up_to: 100
                    },
                    {
                      amount: 49,
                      flat_amount: null,
                      flat_amount_decimal: null,
                      unit_amount_decimal: '49',
                      up_to: 200
                    },
                    {
                      amount: 19,
                      flat_amount: null,
                      flat_amount_decimal: null,
                      unit_amount_decimal: '19',
                      up_to: null
                    }
                  ],
                  tiers_mode: 'graduated',
                  transform_usage: null,
                  trial_period_days: null,
                  usage_type: 'metered'
                },
                price: {
                  id: environment.stripe.prices.premiumPriceId,
                  object: 'price',
                  active: true,
                  billing_scheme: 'tiered',
                  created: 1645105991,
                  currency: 'usd',
                  livemode: false,
                  lookup_key: null,
                  metadata: {},
                  nickname: null,
                  product: 'prod_LAVQ7Vd5V1SKfL',
                  recurring: {
                    aggregate_usage: 'sum',
                    interval: 'month',
                    interval_count: 1,
                    trial_period_days: null,
                    usage_type: 'metered'
                  },
                  tax_behavior: 'exclusive',
                  tiers_mode: 'graduated',
                  transform_quantity: null,
                  type: 'recurring',
                  unit_amount: null,
                  unit_amount_decimal: null
                },
                subscription: 'sub_1KUAvsDJfvhvVHhnFUzhCgg5',
                tax_rates: []
              }
            ],
            has_more: false,
            total_count: 1,
            url: '/v1/subscription_items?subscription=sub_1KUAvsDJfvhvVHhnFUzhCgg5'
          },
          latest_invoice: 'in_1KUAvsDJfvhvVHhnIzETKvql',
          livemode: false,
          metadata: {},
          next_pending_invoice_item_invoice: null,
          pause_collection: null,
          payment_settings: {
            payment_method_options: null,
            payment_method_types: null
          },
          pending_invoice_item_interval: null,
          pending_setup_intent: null,
          pending_update: null,
          plan: {
            id: environment.stripe.prices.premiumPriceId,
            object: 'plan',
            active: true,
            aggregate_usage: 'sum',
            amount: null,
            amount_decimal: null,
            billing_scheme: 'tiered',
            created: 1645105991,
            currency: 'usd',
            interval: 'month',
            interval_count: 1,
            livemode: false,
            metadata: {},
            name: 'Premium Plan',
            nickname: null,
            product: 'prod_LAVQ7Vd5V1SKfL',
            statement_descriptor: null,
            tiers: [
              {
                amount: 149,
                flat_amount: 14900,
                flat_amount_decimal: '14900',
                unit_amount_decimal: '149',
                up_to: 30
              },
              {
                amount: 69,
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount_decimal: '69',
                up_to: 100
              },
              {
                amount: 49,
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount_decimal: '49',
                up_to: 200
              },
              {
                amount: 19,
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount_decimal: '19',
                up_to: null
              }
            ],
            tiers_mode: 'graduated',
            transform_usage: null,
            trial_period_days: null,
            usage_type: 'metered'
          },
          quantity: 1,
          schedule: null,
          start: 1645107967,
          start_date: 1645107967,
          status: 'active',
          tax_percent: null,
          transfer_data: null,
          trial_end: null,
          trial_start: null
        }
      ],
      has_more: false,
      total_count: 1,
      url: '/v1/customers/cus_KPgiun9YhOBtyE/subscriptions'
    },
    tax_exempt: 'none',
    tax_ids: {
      object: 'list',
      data: [],
      has_more: false,
      total_count: 0,
      url: '/v1/customers/cus_KPgiun9YhOBtyE/tax_ids'
    },
    tax_info: null,
    tax_info_verification: null
  }
};