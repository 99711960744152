import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-youtube-video-iq-details',
  templateUrl: './help-youtube-video-iq-details.component.html'
})
export class HelpYoutubeVideoIqDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
