import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Integration } from '../../models/integration.model';

@Injectable({
  providedIn: 'root'
})
export class HelpDrawerService implements OnInit {
  drawerActionResponder$ = new Subject<any>();
  drawerIsOpen: boolean;
  drawerComponentIdentifier: string;

  constructor() { }

  ngOnInit(): void {
    this.resetDrawer();
  }

  resetDrawer(): void {
    this.drawerIsOpen = false;
    this.drawerComponentIdentifier = null;
  }

  toggleDrawer(drawerComponentIdentifier: string = null, integration?: Integration): void {

    if (drawerComponentIdentifier) {
      // Toggle the drawer state
      this.drawerIsOpen = !this.drawerIsOpen;
      this.drawerComponentIdentifier = drawerComponentIdentifier;

      const drawerState = {
        drawerIsOpen: this.drawerIsOpen,
        drawerComponentIdentifier: this.drawerComponentIdentifier,
        integration: integration
      };

      this.drawerActionResponder$.next(drawerState);
    }
  }
}
