import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-google-campaign-manager-identity',
  templateUrl: './help-google-campaign-manager-identity.component.html'
})
export class HelpGoogleCampaignManagerIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
