import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeAllWhiteSpace'
})
export class RemoveAllWhiteSpacePipe implements PipeTransform {

  transform(value: string): string {
    const stripped = value.replace(/\s+/g, '');
    return stripped;
  }

}
