import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-filter-pipelines',
  templateUrl: './filter-pipelines.component.html'
})
export class FilterPipelinesComponent implements OnInit {

  @Input() filterableObjects: any[] = [];
  @Output() filteredObjects = new EventEmitter<any[]>();
  @Output() updatedPageSize = new EventEmitter<number>();

  pageSize = 10;

  constructor() { }

  ngOnInit(): void {
    this.filteredObjects.emit([...this.filterableObjects]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredObjects.emit([...this.filterableObjects]);
  }

  processFilteredText(filterText: string): void {
    if (filterText === '') {
      this.filteredObjects.emit([...this.filterableObjects]);
    }
    else {
      const filteredObjects: any[] = [];

      this.filterableObjects.forEach((val, idx) => {
        if (val.name.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        else if (val.storageGroupName.toLowerCase().includes(filterText.toLowerCase())) {
          filteredObjects.push(val);
        }
        // if(val.keywords && val?.keywords?.includes(filterText.toLowerCase())) {
        //   filteredObjects.push(val);
        // }
      });

      this.filteredObjects.emit([...filteredObjects]);
    }
  }

  updatePageEntries(pageSize): void {
    this.updatedPageSize.emit(pageSize);
  }
}
