<!-- Image -->
<div class="avatar avatar-sm card-avatar">
    <img src="../assets/img/avatars/products/google-ads.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Fully-automated Google Ads data pipeline </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Getting ready for automated Google Ads data pipelines </h4>
<ol>
    <li class="text-muted mb-2"> If you do not have a Google Ads account, Amazon makes it easy to get started. Amazon
        offers <a href="https://ads.google.com/" rel="noopener" target="_blank"> a quick and simple online
            registration</a> for new customers </li>
    <li class="text-muted mb-2"> Openbridge only support Google Ads performance reports as defined by the Google Ads
        API.</li>
    <li class="text-muted mb-2">See our Google Ads <a href="https://docs.openbridge.com/en/?q=google+ads" rel="noopener"
            target="_blank">help docs</a> for guides, tips, and troubleshooting</li>
</ol>