import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-error-details',
  templateUrl: './modal-error-details.component.html'
})
export class ModalErrorDetailsComponent implements OnInit {
  details: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  copyMessage(val: string): boolean {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    return false;
  }

}
