import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-security',
  templateUrl: './help-security.component.html',
})
export class HelpSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
