import { Chart } from 'chart.js';

// Define the chart colors
export const colors = {
  gray: {
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#6E84A3',
    800: '#152E4D',
    900: '#283E59'
  },
  primary: {
    100: '#D2DDEC',
    300: '#A6C5F7',
    700: '#2C7BE5',
  },
  black: '#12263F',
  white: '#FFFFFF',
  blue: '214677',
  transparent: 'transparent',
};

// Define the font used in the charts
export const fonts = {
  base: 'Cerebri Sans'
};

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      usePointStyle: true,
      padding: 16
    }
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: colors.primary[700]      
    },
    line: {
      tension: .4,
      borderWidth: 3,
      borderColor: colors.primary[700],
      borderCapStyle: 'rounded',
      backgroundColor: colors.transparent
    },
    rectangle: {
      backgroundColor: colors.primary[700],
      maxBarThickness: 10
    },
    arc: {
      backgroundColor: colors.blue,
      borderColor: colors.blue,
      borderWidth: 4,
      hoverBorderColor: colors.blue
    }
  }
};

export function generateGlobalDefaults() {
  
  // 
  const globalConfig = Chart.defaults.global;

  // Global
  globalConfig.responsive = chartOptions.responsive;
  globalConfig.maintainAspectRatio = chartOptions.maintainAspectRatio;

  // Default
  globalConfig.defaultColor = colors.gray[600];
  globalConfig.defaultFontColor = colors.gray[600];
  globalConfig.defaultFontFamily = fonts.base;
  globalConfig.defaultFontSize = 13;

  // Layout
  globalConfig.layout.padding = chartOptions.layout.padding;

  // Legend
  globalConfig.legend.display = chartOptions.legend.display;
  globalConfig.legend.position = 'bottom';
  globalConfig.legend.labels.usePointStyle = chartOptions.legend.labels.usePointStyle;
  globalConfig.legend.labels.padding = chartOptions.legend.labels.padding;

  // Point
  globalConfig.elements.point.radius = chartOptions.elements.point.radius;
  globalConfig.elements.point.backgroundColor = chartOptions.elements.point.backgroundColor;

  // Line
  globalConfig.elements.line.tension = chartOptions.elements.line.tension;
  globalConfig.elements.line.borderWidth = chartOptions.elements.line.borderWidth;
  globalConfig.elements.line.borderColor = chartOptions.elements.line.borderColor;
  globalConfig.elements.line.backgroundColor = chartOptions.elements.line.backgroundColor;
  globalConfig.elements.line.borderCapStyle = chartOptions.elements.line.borderCapStyle;

  // Rectangle
  globalConfig.elements.rectangle.backgroundColor = chartOptions.elements.rectangle.backgroundColor;
  // globalConfig.elements.rectangle.maxBarThickness = 10;

  // Arc
  globalConfig.elements.arc.backgroundColor = chartOptions.elements.arc.backgroundColor;
  globalConfig.elements.arc.borderColor = chartOptions.elements.arc.borderColor;
  globalConfig.elements.arc.borderWidth = chartOptions.elements.arc.borderWidth;
  // globalConfig.elements.arc.hoverBorderColor = colors.white;

  Chart.defaults.global.tooltips.enabled = false;
  Chart.defaults.global.tooltips.mode = 'index';
  Chart.defaults.global.tooltips.intersect = false;
  Chart.defaults.global.tooltips.custom = function(model) {
    let tooltip = document.getElementById('chart-tooltip');
    let chartType = this._chart.config.type;

    // Create tooltip if doesn't exist
    if (!tooltip) {
      tooltip = document.createElement('div');

      tooltip.setAttribute('id', 'chart-tooltip');
      tooltip.setAttribute('role', 'tooltip');
      tooltip.classList.add('popover');
      tooltip.classList.add('bs-popover-top');

      document.body.appendChild(tooltip);
    }

    // Hide tooltip if not visible
    if (model.opacity === 0) {
      tooltip.style.visibility = 'hidden';

      return;
    }

    if (model.body) {
      let title = model.title || [];
      let body = model.body.map(function(body) {
        return body.lines;
      });

      // Add arrow
      let content = '<div class="arrow"></div>';

      // Add title
      title.forEach(function(title) {
        content += '<h3 class="popover-header text-center">' + title + '</h3>';
      });

      // Add content
      body.forEach(function(body, i) {
        let colors = model.labelColors[i];
        let indicatorColor = (chartType === 'line' && colors['borderColor'] !== 'rgba(0,0,0,0.1)') ? colors['borderColor'] : '#ff0000';
        let indicator = '<span class="popover-body-indicator" style="background-color: ' + indicatorColor + '"></span>';
        let justifyContent = (body.length > 1) ? 'justify-content-left' : 'justify-content-center';

        content += '<div class="popover-body d-flex align-items-center ' + justifyContent + '">' + indicator + body + '</div>';
      });

      tooltip.innerHTML = content;
    }

    let canvas = this._chart.canvas;
    let canvasRect = canvas.getBoundingClientRect();

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;

    let canvasTop = canvasRect.top + scrollTop;
    let canvasLeft = canvasRect.left + scrollLeft;

    let tooltipWidth = tooltip.offsetWidth;
    let tooltipHeight = tooltip.offsetHeight;

    let top = canvasTop + model.caretY - tooltipHeight - 16;
    let left = canvasLeft + model.caretX - tooltipWidth / 2;

    tooltip.style.top = top + 'px';
    tooltip.style.left = left + 'px';
    tooltip.style.visibility = 'visible';
  };

  return globalConfig;
}






