<app-chart *ngIf="!chartDataLengthError; else dataError"
  [chartType]="chartType"
  [chartLabels]="chartLabels"
  [chartDataSets]="chartDataSets"
  [chartOptions]="chartOptions"
  [chartClass]="chartClass"
  [chartHeight]="chartHeight"
  [chartStyle]="chartStyle"
  [chartStyle]="chartWidth"
  [ngClass]="chartClass"
  [style]="chartStyle"
></app-chart>
<ng-template #dataError>
  <h1>No data to display</h1>
</ng-template>