import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-azure-data-lake-checklist',
  templateUrl: './help-azure-data-lake-checklist.component.html'
})
export class HelpAzureDataLakeChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
