import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
    productIdAmzSellingPartnersOrders,
    productIdAmzSellingPartnersFinanceRt,
    productIdAmzSellingPartnersInbound,
    productIdAmzSellingPartnersSettlement,
    productIdAmzSellingBusRepFullfillmentRt,
    productIdAmzSellingBusRepInventoryRt,
    productIdAmzSellingBusRepInventory,
    productIdAmzSellingBusRepSales,
    productIdAmzSellingBusRepFees,
} from '../product-ids';

export const amzSellerPartnerProducts: Integration[] = [
  {
    productId: productIdAmzSellingPartnersOrders,
    enabled: true,
    comingSoon: false,
    isConfigurable: true,
    brand: 'Amazon',
    name: 'Orders',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Sales data for both FBA and seller-fulfilled order status, fulfillment, items, and channel',
    routerLink: '/wizards/amazon-selling-partners-orders',
    logoPath: vendors.amazon,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingPartnersFinanceRt,
    enabled: true,
    comingSoon: false,
    isConfigurable: true,
    brand: 'Amazon',
    name: 'Finance Real-time',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Real-time financial event data for refunds, orders, credits, shipments, and many more',
    routerLink: '/wizards/amazon-selling-partner-finance',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: false,
    premiumIntegration: true,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingPartnersInbound,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Inbound Fulfillment',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Inbound shipments data for inventory you send to the Amazon fulfillment networks.',
    routerLink: '/wizards/amazon-selling-partner-inbound-fulfillment',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingPartnersSettlement,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Settlement Reports',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Amazon Pay reports provide detailed account activity for a settlement period',
    routerLink: '/wizards/amazon-selling-partner-settlement',
    logoPath: vendors.amazon,
    frequency: 'Bi-Weekly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingBusRepFullfillmentRt,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Fulfillment Real-Time',
    type: 'source',
    sortOrder: 1400,
    tagLine: 'Fulfilled order, shipment, and item information with price, courier, and tracking data.',
    routerLink: '/wizards/amazon-selling-partner-fulfillment-realtime',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'pay.amazon.com/settlement',
      uri: 'https://pay.amazon.com/help/202070210'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingBusRepInventoryRt,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Inventory Real-Time',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Real-time data for available inventory, adjustments, statuses, age, fulfillment centers, and units.',
    routerLink: '/wizards/amazon-selling-partner-inventory-realtime',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingBusRepInventory,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Inventory',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Inventory metrics for status, fulfillment centers, adjustments, fees, issues, and transfers.',
    routerLink: '/wizards/amazon-selling-partner-inventory',
    logoPath: vendors.amazon,
    frequency: 'Daily',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingBusRepSales,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Sales',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Optimized for real-time sales data synchronization, order updates, and order research',
    routerLink: '/wizards/amazon-selling-partner-orders-real-time',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  },
  {
    productId: productIdAmzSellingBusRepFees,
    enabled: false,
    comingSoon: true,
    isConfigurable: false,
    brand: 'Amazon',
    name: 'Fees',
    type: 'source',
    sortOrder: 1200,
    tagLine: 'Seller account level sales, storage, referral, fulfillment, and estimated fees',
    routerLink: '/wizards/amazon-selling-partner-fees',
    logoPath: vendors.amazon,
    frequency: 'Hourly',
    standardIntegration: true,
    premiumIntegration: false,
    remoteIdentityTypeId: 17,
    docs: {
      text: 'docs.openbridge.com',
      uri: 'https://docs.openbridge.com'
    },
    vendor: {
      text: 'github.com/selling-partner',
      uri: 'https://github.com/amzn/selling-partner-api-docs'
    },
    history: false,
    manageMenu: {
    },
    wizardConfigParams: {
      stages: {
        default: [
          'checklist',
          'identity',
          'details',
          'destination',
          'integrationName',
          'save'
        ]
      }
    }
  }
];