<!-- Image -->
<div class="avatar avatar-sm card-avatar">

    <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
        <i class="fad fa-key-skeleton"></i>
    </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Key considerations for identities </p>
<!-- Divider -->
<hr class="mb-4">

  <h4 class="mt-2 mb-2"> Access, Authorization, and Permissions </h4>
  <ol>
    <li class="text-muted mb-2">  A remote identity reflects a user authorization to a data source (i.e, Facebook, Google, Amazon...) or data destination (i.e., Google BigQuery, Amazon Redshift...). Read more about <a href="https://docs.openbridge.com/en/articles/3673866-what-are-remote-identities" rel="noopener" target="_blank" class="light-link">remote identities.</a></li>
     <li class="text-muted mb-2"> If a resource at a data source or destination system does not appear available, validate you are properly authorized to access it.</li>
    <li class="text-muted mb-2"> Each data source and destination has specific and unique permission requirements for accessing resources. An authorization indicates you have permission to each system system.</li>
    <li class="text-muted mb-2"> Authorizations can expire or change over time. While we attempt to detect loss of authorization or changes to permissions, customers should carefully be monitoring changes to their source or destinations given these are outside of our control. </li>
  </ol>