import { Pipe, PipeTransform } from '@angular/core';
import { FlashNotificationIcons } from '../constants/flash-notifications';

@Pipe({
  name: 'iconForNotification'
})
export class IconForNotificationPipe implements PipeTransform {
  flashNotificationIcons = FlashNotificationIcons;
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 'alert-primary') {
      return this.flashNotificationIcons.primaryIcon;
    } else if (value === 'alert-secondary') {
      return this.flashNotificationIcons.secondaryIcon;
    } else if (value === 'alert-success') {
      return this.flashNotificationIcons.successIcon;
    } else if (value === 'alert-danger') {
      return this.flashNotificationIcons.dangerIcon;
    } else if (value === 'alert-warning') {
      return this.flashNotificationIcons.warningIcon;
    } else if (value === 'alert-info') {
      return this.flashNotificationIcons.infoIcon;
    } else if (value === 'alert-light') {
      return this.flashNotificationIcons.lightIcon;
    } else if (value === 'alert-dark') {
      return this.flashNotificationIcons.darkIcon;
    } else {
      return this.flashNotificationIcons.defaultIcon;
    }
  }

}
