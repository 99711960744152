<!-- Image -->
<div class="avatar avatar-sm card-avatar">
  <img src="../assets/img/avatars/products/azure-data-lake.svg" class="avatar-img rounded" alt="...">
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Activating Your Azure Data Lake </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Configuring Azure Data Lake </h4>
<ol>
  <li class="text-muted mb-2"> The configuration process assumes you have an Azure Cloud account. Azure offers <a
       href="https://azure.microsoft.com/en-us/free/" rel="noopener" target="_blank" class="light-link"> free trials and service credits</a> for new customers </li>
  <li class="text-muted mb-2"> If you already have an Azure account, follow the <a
       href="https://docs.openbridge.com/en/articles/4384989-configuring-your-azure-data-lake-storage-data-destination" rel="noopener" target="_blank" class="light-link">Azure Data Lake setup guide</a>. </li>
  <li class=" text-muted mb-2"> Make sure you have you taken note of your Azure Data Lake <code>Storage Account
      Name</code>, <code>Container Name</code>, and <code>Connection String</code> in the Azure interface. You will need
    this in the next step.</li>
</ol>