<!-- Image -->
<div class="avatar avatar-sm card-avatar">

  <span class="avatar-title rounded-circle text-wait" style="font-size: 1.2rem;">
    <i class="fad fa-layer-group"></i>
  </span>
</div>
<!-- Heading -->
<h2 class="text-center mb-2" id="helpDrawerHeader"> Getting Started </h2>
<!-- Text -->
<p class="text-center mb-4" id="helpDrawerTagline"> Overview of data sources </p>
<!-- Divider -->
<hr class="mb-4">
<!-- Heading-->
<h4 class="mt-2 mb-2"> Key considerations for data sources </h4>
<ol>

  <li class="text-muted mb-2"> Sources reflects an upstream system, like Amazon, Google, or Facebook, which data needs to be collected.</li>

  <li class="text-muted mb-2"> An active data destination must be ready to receive data before activating a source.
    Without a destination, we have no place to deliver your data. Read more about <a href="https://docs.openbridge.com/en/collections/753357-data-destinations" rel="noopener" target="_blank" class="light-link">data
      destinations.</a></li>
      
  <li class="text-muted mb-2"> Each data source has permission requirements for accessing resources. An authorization
    indicates you have permission to the data supplied by a source system.  If a resource at a source system does not appear available, validate you are properly
    authorized to access it. Read more about <a href="https://docs.openbridge.com/en/articles/3673866-understanding-remote-identities" rel="noopener" target="_blank" class="light-link">
      identities.</a></li>


  <li class="text-muted mb-2"> <a href="https://docs.openbridge.com/en/articles/5672649-understanding-data-source-apis" rel="noopener" target="_blank" class="light-link">All data sources have API limits and throttles</a>. Ensuring operation within published rate
    limits and throttling constraints is critical to avoid errors.</li>
  <li class="text-muted mb-2"> All source APIs are carefully pre-modeled for limits and data availability. As a result, scheduling is not user-configurable. Read more about API
    limits in <a href="https://docs.openbridge.com/en/articles/5675188-understanding-data-pipeline-scheduling" rel="noopener" target="_blank" class="light-link">Understanding Data Pipeline Scheduling</a>.</li>
</ol>