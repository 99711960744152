<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12">
      <!-- Card -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto pe-3">
              <!-- Avatar -->
              <div class="avatar avatar-md">
                <span class="avatar-title rounded-circle text-wait" style="font-size: 1.4rem;">
                  <i class="fad fa-key-skeleton"></i></span>
              </div>
            </div>
            <div class="col ps-0">
              <!-- Heading -->
              <span class="h3 mb-0"> Create New {{ integration.name }} Authorization</span>
              <!-- Text -->
              <p class="card-text small text-muted mb-1">Grant data access permissions to your {{ integration.name }}
                account. Once authorized, the {{ integration.name }} identity will appear in the list below.</p>
            </div>
            <div class="col pt-3">
              <div class="mb-3">
                <select *ngIf="identityRegions.length > 1" class="form-control select2" name="region" id="region"
                  formControlName="region" [ngClass]="{
                  'is-invalid': formGroup.controls.region.invalid && formGroup.controls.region.touched,
                  'is-valid': formGroup.controls.region.valid && formGroup.controls.region.touched
                }">
                  <option value="">Select your region</option>
                  <option *ngFor="let region of identityRegions" [ngValue]="region.value">{{ region.name }}</option>
                </select>
                <input *ngIf="identityRegions.length == 1" name="identityRegion" value="global" type="hidden"
                  formControlName="region">
              </div>
              <div class="mb-0">
                <!-- Card -->
                <div class="card">
                  <ng-container [ngSwitch]="integration.brand">
                    <div class="image-center">
                      <img *ngSwitchCase="'Google'" src="assets/img/vendor/btn_google_signin.png"
                        alt="Signin with Google" class="google-signin-btn cursor-pointer lift" (click)="submitForm()" />
                    </div>
                    <button type="button" *ngSwitchDefault class="btn btn-ls btn-white" id="authorizeBtn"
                      (click)="submitForm()">Authorize</button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</form>