export const responseSubscriptions = {
  links: {
    first: 'https://subscriptions.api.dev.openbridge.io/sub?page=1',
    last: 'https://subscriptions.api.dev.openbridge.io/sub?page=1',
    next: '',
    prev: ''
  },
  data: [
    {
      type: 'Subscription',
      id: '124',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-10-23T03:17:27',
        date_end: '2021-10-23T03:17:27',
        auto_renew: 1,
        created_at: '2021-10-22T22:17:30.554002',
        modified_at: '2022-01-04T08:47:53.435597',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'snowflake',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 52,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '52'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '125',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-10-23T03:18:23',
        date_end: '2021-10-23T03:18:23',
        auto_renew: 1,
        created_at: '2021-10-22T22:18:26.867325',
        modified_at: '2022-01-04T08:41:05.433667',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-ads-data',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '126',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-10-23T03:28:27',
        date_end: '2021-10-23T03:28:27',
        auto_renew: 1,
        created_at: '2021-10-22T22:28:31.259640',
        modified_at: '2022-01-04T08:36:26.120597',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-ads-data2',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '127',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-10-23T03:29:10',
        date_end: '2021-10-23T03:29:10',
        auto_renew: 1,
        created_at: '2021-10-22T22:29:14.414688',
        modified_at: '2022-01-04T08:57:38.336498',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-ads-data22',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '133',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-10-26T00:49:00',
        date_end: '2021-10-26T00:49:00',
        auto_renew: 1,
        created_at: '2021-10-25T19:49:03.923727',
        modified_at: '2022-01-04T08:47:05.622606',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'bababba',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '134',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-10-26T20:31:41',
        date_end: '2021-10-26T20:31:41',
        auto_renew: 1,
        created_at: '2021-10-26T15:31:46.274686',
        modified_at: '2021-12-08T13:46:52.035960',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-ads-dataeqwwe',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '137',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-11-01T07:24:33',
        date_end: '2021-11-01T07:24:33',
        auto_renew: 1,
        created_at: '2021-11-01T07:24:35.964717',
        modified_at: '2021-12-08T13:39:47.398214',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-ads-datas',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '138',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-11-01T08:07:17',
        date_end: '2021-11-01T08:07:17',
        auto_renew: 1,
        created_at: '2021-11-01T08:07:20.518985',
        modified_at: '2021-11-10T08:02:46.433260',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'bigquery',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 37,
        product_plan_id: null,
        remote_identity_id: 77,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '37'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '77'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '141',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-11-08T13:40:47',
        date_end: '2021-11-08T13:40:47',
        auto_renew: 1,
        created_at: '2021-11-08T13:40:49.750181',
        modified_at: '2021-12-07T14:35:41.563146',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-blizzard',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 105,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '105'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '142',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-01T19:33:10',
        date_end: '2021-12-01T19:33:10',
        auto_renew: 1,
        created_at: '2021-12-01T19:33:12.854155',
        modified_at: '2021-12-01T19:33:12.854171',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'foobar-testing',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 105,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '105'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '143',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-02T13:19:53',
        date_end: '2021-12-02T13:19:53',
        auto_renew: 1,
        created_at: '2021-12-02T13:19:55.904709',
        modified_at: '2021-12-08T13:19:23.504108',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'attribution',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '144',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T11:40:44',
        date_end: '2021-12-03T11:40:44',
        auto_renew: 1,
        created_at: '2021-12-03T11:40:51.895873',
        modified_at: '2021-12-08T13:19:26.770231',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my attribution',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '145',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-03T12:25:25',
        date_end: '2021-12-03T12:25:25',
        auto_renew: 1,
        created_at: '2021-12-03T12:25:29.461886',
        modified_at: '2021-12-08T13:30:36.427164',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'great amazon advertising',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '146',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-03T12:27:43',
        date_end: '2021-12-03T12:27:43',
        auto_renew: 1,
        created_at: '2021-12-03T12:27:46.834930',
        modified_at: '2021-12-09T00:52:18.669562',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'another subscription',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '147',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T12:29:25',
        date_end: '2021-12-03T12:29:25',
        auto_renew: 1,
        created_at: '2021-12-03T12:29:28.880720',
        modified_at: '2021-12-06T20:30:32.842744',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon attribution test again',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '148',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T19:14:08',
        date_end: '2021-12-03T19:14:08',
        auto_renew: 1,
        created_at: '2021-12-03T19:14:17.742104',
        modified_at: '2021-12-08T13:46:15.858731',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'asdfasdfasdfa',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '149',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T18:30:20',
        date_end: '2021-12-03T18:30:20',
        auto_renew: 1,
        created_at: '2021-12-03T19:14:29.323503',
        modified_at: '2021-12-08T13:46:20.771038',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'asdfasdfasdfa',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '150',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T18:30:20',
        date_end: '2021-12-03T18:30:20',
        auto_renew: 1,
        created_at: '2021-12-03T19:15:09.045863',
        modified_at: '2021-12-08T13:46:25.823195',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'asdfasdfasdfa',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '151',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T18:30:20',
        date_end: '2021-12-03T18:30:20',
        auto_renew: 1,
        created_at: '2021-12-03T19:15:13.259893',
        modified_at: '2021-12-08T13:46:29.962234',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'asdfasdfasdfa',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '152',
      attributes: {
        price: 0,
        status: 'cancelled',
        date_start: '2021-12-03T18:30:20',
        date_end: '2021-12-03T18:30:20',
        auto_renew: 1,
        created_at: '2021-12-03T19:35:02.693764',
        modified_at: '2021-12-08T13:46:33.254363',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'asdfasdfasdfa',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '158',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-08T20:42:06',
        date_end: '2021-12-08T20:42:06',
        auto_renew: 1,
        created_at: '2021-12-08T20:42:10.061629',
        modified_at: '2021-12-08T20:42:10.897693',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'bigquery razor storage',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 37,
        product_plan_id: null,
        remote_identity_id: 110,
        storage_group_id: 106,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '37'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '110'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '106'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '162',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-09T16:13:22',
        date_end: '2021-12-09T16:13:22',
        auto_renew: 1,
        created_at: '2021-12-09T16:13:29.603127',
        modified_at: '2021-12-09T16:13:30.550700',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'azure blob storage',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 46,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 107,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '46'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '107'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '163',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-09T16:38:14',
        date_end: '2021-12-09T16:38:14',
        auto_renew: 1,
        created_at: '2021-12-09T16:38:18.496725',
        modified_at: '2021-12-09T16:38:19.497518',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my datalake test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 47,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 108,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '47'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '108'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '165',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-10T07:24:24',
        date_end: '2021-12-10T07:24:24',
        auto_renew: 1,
        created_at: '2021-12-10T07:24:27.132305',
        modified_at: '2021-12-10T08:20:55.649222',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'aws-athena-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '166',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-10T11:16:04',
        date_end: '2021-12-10T11:16:04',
        auto_renew: 1,
        created_at: '2021-12-10T11:16:07.982103',
        modified_at: '2021-12-10T11:16:09.732172',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'athena-test-destination',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 111,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '111'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '167',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-10T11:18:56',
        date_end: '2021-12-10T11:18:56',
        auto_renew: 1,
        created_at: '2021-12-10T11:19:00.730451',
        modified_at: '2021-12-10T11:19:00.730467',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-test-source-pipeline',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 111,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '111'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '217',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-17T15:12:13',
        date_end: '2021-12-17T15:12:13',
        auto_renew: 1,
        created_at: '2021-12-17T15:12:21.740793',
        modified_at: '2021-12-17T15:12:21.740807',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon ads 3956545467331245',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '218',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-17T15:50:04',
        date_end: '2021-12-17T15:50:04',
        auto_renew: 1,
        created_at: '2021-12-17T15:50:10.288556',
        modified_at: '2021-12-17T15:50:10.288571',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon ads 3570846544438332',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '219',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-17T16:16:14',
        date_end: '2021-12-17T16:16:14',
        auto_renew: 1,
        created_at: '2021-12-17T16:16:18.570179',
        modified_at: '2021-12-17T16:16:18.570194',
        quantity: 1,
        stripe_subscription_id: '',
        name: '3931855377063679 amazon ads kdp',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '224',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-23T18:44:55',
        date_end: '2021-12-23T18:44:55',
        auto_renew: 1,
        created_at: '2021-12-23T18:44:59.235902',
        modified_at: '2021-12-23T18:44:59.235915',
        quantity: 1,
        stripe_subscription_id: '',
        name: '3931855377063679 amazon ads kdp updated',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '225',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2021-12-23T19:00:49',
        date_end: '2021-12-23T19:00:49',
        auto_renew: 1,
        created_at: '2021-12-23T18:49:11.048496',
        modified_at: '2021-12-23T19:00:53.421440',
        quantity: 1,
        stripe_subscription_id: '',
        name: '3931855377063679 amazon ads kdp updated 5',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 110,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '110'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '226',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-03T12:59:11',
        date_end: '2022-01-03T12:59:11',
        auto_renew: 1,
        created_at: '2021-12-23T18:51:48.763575',
        modified_at: '2022-01-03T12:59:14.660781',
        quantity: 1,
        stripe_subscription_id: '',
        name: '3931855377063679 amazon ads kdp updated 16',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 111,
        storage_group_id: 111,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '111'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '111'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '227',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-12-30T12:55:19',
        date_end: '2021-12-30T12:55:19',
        auto_renew: 1,
        created_at: '2021-12-30T12:55:22.862203',
        modified_at: '2022-01-04T09:20:30.710177',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-mock-1',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 108,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '108'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '228',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2021-12-31T07:31:58',
        date_end: '2021-12-31T07:31:58',
        auto_renew: 1,
        created_at: '2021-12-31T07:32:02.273749',
        modified_at: '2022-01-04T09:20:06.973259',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-attribution-mock-1',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 108,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '108'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '235',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-05T15:28:18',
        date_end: '2022-01-05T15:28:18',
        auto_renew: 1,
        created_at: '2022-01-05T15:28:21.742196',
        modified_at: '2022-01-05T15:28:21.742211',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-attribution-pipeline-2a2a',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 111,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '111'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '237',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-07T12:45:11',
        date_end: '2022-01-07T12:45:11',
        auto_renew: 1,
        created_at: '2022-01-07T12:45:14.968048',
        modified_at: '2022-01-07T12:45:14.968063',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'dsp test subscription',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 54,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '54'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '265',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-21T17:27:42',
        date_end: '2022-01-21T17:27:42',
        auto_renew: 1,
        created_at: '2022-01-10T19:51:59.573723',
        modified_at: '2022-01-21T17:27:46.285978',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-dsp-mock-2',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 54,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '54'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '275',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-24T13:14:58',
        date_end: '2022-01-24T13:14:58',
        auto_renew: 1,
        created_at: '2022-01-24T13:15:02.288715',
        modified_at: '2022-01-26T14:45:21.679819',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-blob-storage-e2e-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 46,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 199,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '46'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '199'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '276',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-24T13:33:28',
        date_end: '2022-01-24T13:33:28',
        auto_renew: 1,
        created_at: '2022-01-24T13:33:31.316183',
        modified_at: '2022-01-24T13:33:31.981685',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'snowflake-development',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 52,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 200,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '52'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '200'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '277',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-24T13:35:58',
        date_end: '2022-01-24T13:35:58',
        auto_renew: 1,
        created_at: '2022-01-24T13:34:49.307185',
        modified_at: '2022-01-24T13:36:00.971713',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-sponsoredads-pipeline2',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 200,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '200'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '278',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-24T14:05:57',
        date_end: '2022-01-24T14:05:57',
        auto_renew: 1,
        created_at: '2022-01-24T14:06:01.679721',
        modified_at: '2022-01-26T14:45:34.873047',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-e2e-destination-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 46,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 201,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '46'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '201'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '279',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-24T14:09:54',
        date_end: '2022-01-24T14:09:54',
        auto_renew: 1,
        created_at: '2022-01-24T14:09:58.527996',
        modified_at: '2022-01-26T14:45:41.407354',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-e2e-destination',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 46,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 202,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '46'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '202'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '280',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-24T14:12:22',
        date_end: '2022-01-24T14:12:22',
        auto_renew: 1,
        created_at: '2022-01-24T14:12:26.312069',
        modified_at: '2022-01-26T14:45:47.188868',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 47,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 203,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '47'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '203'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '281',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-24T14:14:54',
        date_end: '2022-01-24T14:14:54',
        auto_renew: 1,
        created_at: '2022-01-24T14:14:58.330459',
        modified_at: '2022-01-26T14:45:53.512360',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'kishan-athena-test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 204,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '204'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '298',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-26T12:36:32',
        date_end: '2022-01-26T12:36:32',
        auto_renew: 1,
        created_at: '2022-01-26T12:36:35.505431',
        modified_at: '2022-01-26T14:42:17.172347',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'attributione2etest',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 50,
        product_plan_id: null,
        remote_identity_id: 112,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '50'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '112'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '299',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-26T16:55:54',
        date_end: '2022-01-26T16:55:54',
        auto_renew: 1,
        created_at: '2022-01-26T16:55:58.656993',
        modified_at: '2022-01-26T16:55:58.657008',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'spapi orders',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 53,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 88,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '53'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '88'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '300',
      attributes: {
        price: 0,
        status: 'invalid',
        date_start: '2022-01-27T11:56:47',
        date_end: '2022-01-27T11:56:47',
        auto_renew: 1,
        created_at: '2022-01-27T11:56:50.549393',
        modified_at: '2022-01-27T11:59:08.306471',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'aws athena e2e test',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 31,
        product_plan_id: null,
        remote_identity_id: null,
        storage_group_id: 232,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '31'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: null
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '232'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '309',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-15T17:49:42',
        date_end: '2022-02-15T17:49:42',
        auto_renew: 1,
        created_at: '2022-02-15T17:49:48.565499',
        modified_at: '2022-02-15T17:49:48.565515',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'Google Ads Stuff',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 25,
        product_plan_id: null,
        remote_identity_id: 178,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '25'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '178'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '310',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:32:56',
        date_end: '2022-02-16T10:32:56',
        auto_renew: 1,
        created_at: '2022-02-16T10:33:00.199217',
        modified_at: '2022-02-16T10:33:00.199231',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon selling partner fees',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 62,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '62'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '311',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:33:36',
        date_end: '2022-02-16T10:33:36',
        auto_renew: 1,
        created_at: '2022-02-16T10:33:43.042688',
        modified_at: '2022-02-16T10:33:43.042702',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-finance',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 55,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '55'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '312',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:34:21',
        date_end: '2022-02-16T10:34:21',
        auto_renew: 1,
        created_at: '2022-02-16T10:34:25.161820',
        modified_at: '2022-02-16T10:34:25.161834',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-fulfillment-realtime',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 58,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '58'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '313',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:35:04',
        date_end: '2022-02-16T10:35:04',
        auto_renew: 1,
        created_at: '2022-02-16T10:35:11.383774',
        modified_at: '2022-02-16T10:35:11.383788',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-inbound-fulfillment',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 56,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '56'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '314',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:36:03',
        date_end: '2022-02-16T10:36:03',
        auto_renew: 1,
        created_at: '2022-02-16T10:36:06.861864',
        modified_at: '2022-02-16T10:36:06.861878',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-inventory',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 60,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '60'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '315',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:36:40',
        date_end: '2022-02-16T10:36:40',
        auto_renew: 1,
        created_at: '2022-02-16T10:36:44.331559',
        modified_at: '2022-02-16T10:36:44.331574',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-inventory-realtime',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 59,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '59'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '316',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:38:06',
        date_end: '2022-02-16T10:38:06',
        auto_renew: 1,
        created_at: '2022-02-16T10:38:09.588784',
        modified_at: '2022-02-16T10:38:09.588798',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-sales',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 61,
        product_plan_id: null,
        remote_identity_id: 168,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '61'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '168'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '317',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-02-16T10:46:28',
        date_end: '2022-02-16T10:46:28',
        auto_renew: 1,
        created_at: '2022-02-16T10:39:09.545965',
        modified_at: '2022-02-16T10:46:34.817944',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'amazon-selling-partner-settlement 2',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 57,
        product_plan_id: null,
        remote_identity_id: 135,
        storage_group_id: 107,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '57'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '135'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '107'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    },
    {
      type: 'Subscription',
      id: '106501',
      attributes: {
        price: 0,
        status: 'active',
        date_start: '2022-01-27T18:34:52',
        date_end: '2022-01-27T18:34:52',
        auto_renew: 1,
        created_at: '2022-01-27T18:34:55.309374',
        modified_at: '2022-01-27T18:34:55.309387',
        quantity: 1,
        stripe_subscription_id: '',
        name: 'my-attribution-pipeline-sfjdakljfalskjflka',
        rabbit_payload_successful: 0,
        primary_job_id: null,
        pipeline: null,
        invalid_subscription: 0,
        invalidated_at: null,
        notified_at: null,
        canonical_name: null,
        account_id: 342,
        product_id: 40,
        product_plan_id: null,
        remote_identity_id: 22,
        storage_group_id: 102,
        user_id: 309,
        history_requested: 0
      },
      relationships: {
        account: {
          data: {
            type: 'Account',
            id: '342'
          }
        },
        product: {
          data: {
            type: 'Product',
            id: '40'
          }
        },
        product_plan: {
          data: null
        },
        remote_identity: {
          data: {
            type: 'RemoteIdentity',
            id: '22'
          }
        },
        storage_group: {
          data: {
            type: 'StorageGroup',
            id: '102'
          }
        },
        user: {
          data: {
            type: 'User',
            id: '309'
          }
        }
      }
    }
  ],
  included: [
    {
      type: 'StorageGroup',
      id: '102',
      attributes: {
        product_id: 37,
        name: 'bigquery',
        key_name: 'b08046b8463056b7-bigquery'
      }
    },
    {
      type: 'StorageGroup',
      id: '105',
      attributes: {
        product_id: 31,
        name: 'my-blizzard',
        key_name: 'adb53e2033d2c9fc-my-blizzard'
      }
    },
    {
      type: 'StorageGroup',
      id: '106',
      attributes: {
        product_id: 37,
        name: 'bigquery razor storage',
        key_name: 'bc9b374472b1e7a4-bigquery-razor-storage'
      }
    },
    {
      type: 'StorageGroup',
      id: '107',
      attributes: {
        product_id: 46,
        name: 'azure blob storage',
        key_name: '985317bf25546027-azure-blob-storage'
      }
    },
    {
      type: 'StorageGroup',
      id: '108',
      attributes: {
        product_id: 47,
        name: 'my datalake test',
        key_name: 'afad95d7a191146f-my-datalake-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '110',
      attributes: {
        product_id: 31,
        name: 'aws-athena-test',
        key_name: '85e4f28214fd8834-aws-athena-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '111',
      attributes: {
        product_id: 31,
        name: 'athena-test-destination',
        key_name: '8eae8c21fb87de50-athena-test-destination'
      }
    },
    {
      type: 'StorageGroup',
      id: '199',
      attributes: {
        product_id: 46,
        name: 'kishan-blob-storage-e2e-test',
        key_name: 'a3bde46f0ca9e0e0-kishan-blob-storage-e2e-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '200',
      attributes: {
        product_id: 52,
        name: 'snowflake-development',
        key_name: 'bce6bd86207a5549-snowflake-development'
      }
    },
    {
      type: 'StorageGroup',
      id: '201',
      attributes: {
        product_id: 46,
        name: 'kishan-e2e-destination-test',
        key_name: '95e60f84a4af4bf4-kishan-e2e-destination-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '202',
      attributes: {
        product_id: 46,
        name: 'kishan-e2e-destination ',
        key_name: '925f945d719d1088-kishan-e2e-destination-'
      }
    },
    {
      type: 'StorageGroup',
      id: '203',
      attributes: {
        product_id: 47,
        name: 'kishan-test',
        key_name: 'a8bab8f7d20ac672-kishan-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '204',
      attributes: {
        product_id: 31,
        name: 'kishan-athena-test',
        key_name: 'b45b1b4756b41827-kishan-athena-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '232',
      attributes: {
        product_id: 31,
        name: 'aws athena e2e test',
        key_name: 'b9f8badf84172323-aws-athena-e2e-test'
      }
    },
    {
      type: 'StorageGroup',
      id: '88',
      attributes: {
        product_id: 52,
        name: 'snowflake',
        key_name: 'snowflake-342'
      }
    }
  ],
  meta: {
    pagination: {
      page: 1,
      pages: 1,
      count: 58
    }
  }
};