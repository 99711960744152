import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-mws-finances-identity',
  templateUrl: './help-mws-finances-identity.component.html'
})
export class HelpMwsFinancesIdentityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
