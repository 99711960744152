import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-mws-realtime-orders-checklist',
  templateUrl: './help-mws-realtime-orders-checklist.component.html',
  styleUrls: ['./help-mws-realtime-orders-checklist.component.scss']
})
export class HelpMwsRealtimeOrdersChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
