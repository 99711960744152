export const responseBigQueryRemoteIdentity = {
  data: {
    type: 'RemoteIdentity',
    id: '110',
    attributes: {
      name: 'bigquery-working-test@openbridge-bigquery.iam.gserviceaccount.com',
      created_at: '2021-12-01T15:20:53.403180',
      modified_at: '2021-12-01T15:20:53.403202',
      identity_hash: '1207c302-e6aa-40d0-a14f-4f6d14241f49',
      remote_unique_id: 'f22bda528ae3f0d664d2153450b835c7ee29e0f4',
      account_id: 410,
      user_id: 377,
      notified_at: null,
      invalidate_manually: 0,
      invalid_identity: 0,
      invalidated_at: null,
      notification_counter: 0,
      region: 'global'
    },
    relationships: {
      remote_identity_type: {
        data: {
          type: 'RemoteIdentityType',
          id: '12'
        }
      },
      account: {
        data: {
          type: 'Account',
          id: '410'
        }
      },
      user: {
        data: {
          type: 'User',
          id: '377'
        }
      },
      trusted_identity: {
        data: null
      },
      remote_identity_type_application: {
        data: null
      }
    }
  }
};