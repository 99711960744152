import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-chart-sparkline',
  templateUrl: './chart-sparkline.component.html'
})
export class ChartSparklineComponent implements OnInit {
  // Data Related
  @Input() chartDataSets: ChartDataSets[];
  @Input() chartLabels: Label[];
  @Input() chartPlugins: any[];
  @Input() chartLegend: boolean;

  // Style related.
  @Input() chartClass: string;
  @Input() chartHeight: string;
  @Input() chartStyle: string;
  @Input() chartWidth: string;

  chartType: ChartType = 'line';
  chartOptions: ChartOptions;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      scales: {
        yAxes: [{
          display: false
        }],
        xAxes: [{
          display: false
        }]
      },
      elements: {
        line: {
          borderWidth: 2,
          borderColor: '#D2DDEC'
        },
        point: {
          hoverRadius: 0
        }
      },
      tooltips: {
        custom: function() {
          return false;
        }
      }
    };
  }
}
