import { createReducer, on } from "@ngrx/store";
import * as actions from '../actions/stripe.actions';

// CHange "any" to whatever the user model will be in the future.
export interface StripeState {
  stripeCustomer: any | null;
}

//  Create the initial state for the authenticated user.
const initialStripeState: StripeState = {
  stripeCustomer: null,
};

export const reducer = createReducer(
  initialStripeState,
  on(actions.stripeCustomerSet, (state, action) => {
    return {
      ...state,
      stripeCustomer: action.stripeCustomer
    };
  }),
);
