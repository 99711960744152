import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-mws-reporting-checklist',
  templateUrl: './help-mws-reporting-checklist.component.html'
})
export class HelpMwsReportingChecklistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
