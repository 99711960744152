<div class="row">
  <div class="col-12">
    <!-- Card -->
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <!-- Heading -->
            <span class="h3 mb-0"> Activate New Google Service Account </span>
            <!-- Text -->
            <p class="card-text small text-muted mb-1">In Google Cloud console, go to
              <code>IAM & admin > Service accounts</code>. Next, <code>Create service account</code> and assign
              permissions <code>XXXX, XXXXX</code> Lastly, create and export your JSON key for upload here.</p>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <!-- Card -->
              <div class="card">
                <button type="button" class="btn btn-secondary" (click)="createIdentity()">Link shopify store.</button>
              </div>
            </div>
            <!-- Icon
                        <a class="btn btn-sm btn-primary" href="pricing.html">Upgrade</a>-->
          </div>
        </div> <!-- / .row -->
      </div>
    </div>
  </div>
  <!--div class="col-12 col-md-6">


                <div class="card">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col">

                        <div class="form-group">


                                          <label class="mb-1">
                                            Starting files
                                          </label>


                                          <small class="form-text text-muted">
                                            Upload any files you want to start the projust with.
                                          </small>

                                          <div class="card">
                                            <div class="card-body">
                                              <div class="dropzone dropzone-multiple dz-clickable" data-bs-toggle="dropzone" data-options="{&quot;url&quot;: &quot;https://&quot;}">


                                                <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"></ul>

                                              <div class="dz-default dz-message"><button class="dz-button" type="button">Drop files here to upload</button></div></div>
                                            </div>
                                          </div>
                                        </div>

                      </div>
                      <div class="col-auto">


                        <div class="chart chart-sparkline"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                          <canvas class="chart-canvas chartjs-render-monitor" id="sparklineChart" style="display: block; height: 35px; width: 75px;" width="150" height="70"></canvas>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

  </div-->
</div>